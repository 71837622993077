import { useState, useEffect, ChangeEvent, useRef } from "react";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import { LoanDetailsModal } from "./LoanDetails";
import { Slider } from "@mui/material";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import Loader from "../components/Loader";
import { DrawerComponent } from "../../../_metronic/assets/ts/components";

export interface LoanDetails {
  tenantId: string;
  referenceId: string;
  referenceType: string;
  loanGuid: string;
  loanAmount: number;
  currentDue: number;
  interestRate: number;
  loanTerm: number;
  noOfInstallment: number;
  dueDate: string;
  emi: number;
  processingFee: number;
  totalPayableAmount: number;
  loanStatus: number;
  requestedDate: string;
  totalRepaymentAmount: number;
  remainingAmount: number;
  lifeTimeLoanRepaymentAmount: number;
  paymentInfo: PaymentInfo;
  repaymentSchedules: RepaymentSchedule[];
}

interface PaymentInfo {
  currentDueDate: string;
  currentDueAmount: number;
  previousDueAmount: number;
  lateFee: number;
  totalUpcomingInstallmentAmount: number;
  totalDueAmount: number;
  totalOutstandingAmount: number;
  interestFromTotalDueAmount: number;
  interestFromTotalOutstandingAmount: number;
}

export interface RepaymentSchedule {
  tenantId: string;
  loanGuid: string;
  repaymentGuid: string;
  month: number;
  dueDate: string;
  principal: number;
  interest: number;
  monthlyPayment: number;
  endingBalance: number;
  paymentStatus: number;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  dueDateStr: string;
}

export interface Loan {
  loanGuid: string;
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
  noOfInstallment: number;
  dueDate: string;
  emi: number;
  processingFee: number;
  totalPayableAmount: number;
  loanStatus: number;
  requestedDate: string;
}

interface LoanQuotation {
  tenantId: string;
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
  dueDate: string;
  emi: number;
  processingFee: number;
  totalPayableAmount: number;
}

export const LoanApi = {
  api: (): void => {},
  detailsApi: (loanGuid: string): void => {},
  activeApi: (): void => {},
};

export default function LoanDashboard() {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [screen, setScreen] = useState("1");
  const [term, setTerm] = useState(1);
  const [loanAmount, setLoanAmount] = useState(25000);
  const [activeLoan, setActiveLoan] = useState<LoanDetails>();
  const [quotation, setQuotation] = useState<LoanQuotation>({
    tenantId: "",
    loanAmount: 0,
    interestRate: 0,
    loanTerm: 0,
    dueDate: "",
    emi: 0,
    processingFee: 0,
    totalPayableAmount: 0,
  });
  const [data, setData] = useState<Loan[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState(data);
  const [searchQuery, setSearchQuery] = useState("");
  const [loan, setLoan] = useState<LoanDetails>({
    tenantId: "",
    referenceId: "",
    referenceType: "",
    loanGuid: "",
    loanAmount: 0,
    currentDue: 0,
    interestRate: 0,
    loanTerm: 0,
    noOfInstallment: 0,
    dueDate: "",
    emi: 0,
    processingFee: 0,
    totalPayableAmount: 0,
    loanStatus: 0,
    requestedDate: "",
    totalRepaymentAmount: 0,
    lifeTimeLoanRepaymentAmount: 0,
    remainingAmount: 0,
    paymentInfo: {
      currentDueDate: "",
      currentDueAmount: 0,
      previousDueAmount: 0,
      lateFee: 0,
      totalUpcomingInstallmentAmount: 0,
      totalDueAmount: 0,
      totalOutstandingAmount: 0,
      interestFromTotalDueAmount: 0.0,
      interestFromTotalOutstandingAmount: 0.0,
    },
    repaymentSchedules: [],
  });

  const modalRef = useRef<HTMLDivElement | null>(null);

  const [authority, setAuthority] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleOpenModal = () => {
    const modalElement = document.getElementById("loan-modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    setFilteredTransactions(
      data?.filter(
        (loan) =>
          loan?.loanAmount?.toString().includes(searchQuery.toLowerCase()) ||
          loan?.requestedDate?.toLowerCase().includes(searchQuery.toLowerCase())
        // loan?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [data, searchQuery]);

  const amount = [
    {
      value: 0,
      label: "ZAR 25,000.00",
    },
    {
      value: 50,
      label: "ZAR 50,000.00",
    },
    {
      value: 100,
      label: "ZAR 100,000.00",
    },
  ];

  const emi = [
    {
      value: 0,
      label: "1 Month",
    },
    {
      value: 3,
      label: "3 Months",
    },
    {
      value: 6,
      label: "6 Months",
    },
  ];

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const getLoans = async () => {
    try {
      const url = `${API_URL}/loan/viewLoans`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const searchCriteriaList = [
        {
          propertyName: "",
          propertyValue: "",
        },
      ];

      const body = JSON.stringify({
        searchCriteriaList,
        pageNumber: 1,
        pageSize: 50,
        skipPagination: true,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setData(data?.results);
      }
      // if (data.status.statusCode === 1) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Failed",
      //     text: data?.status?.messageDescription,
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
      setLoading(false);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getActiveLoan = async () => {
    try {
      const url = `${API_URL}/loan/getActiveLoan`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setActiveLoan(data?.message);
      }
      // if (data.status.statusCode === 1) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Failed",
      //     text: data?.status?.messageDescription,
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getLoanDetails = async (loanGuid: string) => {
    try {
      const url = `${API_URL}/loan/findLoanByGuid`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        loanGuid: loanGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setLoan(data?.message);
      }
      // if (data.status.statusCode === 1) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Failed",
      //     text: data?.status?.messageDescription,
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getLoanQuotation = async (loanAmount: number, loanTerm: number) => {
    try {
      const url = `${API_URL}/loan/getLoanQuotes`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        loanAmount: loanAmount,
        loanTerm: loanTerm,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setQuotation(data?.message);
      }
      // if (data.status.statusCode === 1) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Failed",
      //     text: data?.status?.messageDescription,
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getLoanQuotation(loanAmount, term);
  }, [term, loanAmount]);

  const applyLoan = async (loanAmount: number, loanTerm: number) => {
    try {
      const url = `${API_URL}/loan/requestLoan`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        loanAmount: loanAmount,
        loanTerm: loanTerm,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleCloseModal();
        getLoans();
        getActiveLoan();
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          html: `Thank You !! Your amount has been processed<br/>You shall receive the amount in your account in the next 2 to 24 hours`,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      // if (data.status.statusCode === 1) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Failed",
      //     html: `${data?.status?.messageDescription}`,
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getLoans();
    getActiveLoan();
    LoanApi.api = getLoans;
    LoanApi.detailsApi = getLoanDetails;
    LoanApi.activeApi = getActiveLoan;
  }, []);

  function calculateAmount(input: number): number {
    if (input === 0) {
      return 25000;
    } else if (input === 50) {
      return 50000;
    } else if (input === 100) {
      return 100000;
    } else {
      // Handle other cases if needed
      return 0; // Default value if input doesn't match any predefined values
    }
  }

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <style>
            {`
    #shadow-hover {
      // transition: box-shadow 0.3s;
      box-shadow: 4px 4px 10px #d1d1d1;
    }

    #shadow-hover:hover {
      box-shadow: 2px 2px 5px #71FF99;
    }
//     .carousel-control-prev-icon,

// .carousel-indicators {
//   display: none !important;
// }
.black-icon {
  color: black;
}
.btn:hover{
backgroundColor:#ffff
color:"red"
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  background-size: 20px 20px;
}
  `}
          </style>
          <h4 className="mb-5">Loan Dashboard</h4>
          <div
            className={`d-flex gap-3 ${
              window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
            }`}
          >
            <div className="card d-flex flex-column flex-grow-1">
              <div
                className={`d-flex justify-content-between gap-5 ${
                  window.innerWidth < 13 * 96 ? "flex-wrap" : "flex-nowrap"
                } `}
                style={{
                  paddingTop: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <div
                  className=" d-flex flex-grow-1 card shadow-sm text-center bg-secondary"
                  style={{ width: "800px" }}
                >
                  <div
                    className="d-flex flex-grow-1 p-10 ms-10 "
                    // style={{ marginLeft: "20px" }}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <p
                          className="p-0 text-dark mb-5 text-start"
                          style={{ fontWeight: "bold" }}
                        >
                          <p
                            className="text-dark-200"
                            style={{ fontWeight: "normal" }}
                          >
                            Business Loan
                          </p>
                        </p>
                        <h4
                          className="d-flex flex-row p-0 text-dark mb-2"
                          style={{ fontSize: "35px", marginLeft: "0" }}
                        >
                          <span
                            className="d-flex me-2 mt-2"
                            style={{
                              fontSize: "20px",
                              fontWeight: "normal",
                              alignItems: "center",
                            }}
                          >
                            {baseCurrency}{" "}
                          </span>
                          <span>
                            {activeLoan?.loanAmount
                              ? activeLoan?.loanAmount.toLocaleString("en-US")
                              : "0"}
                          </span>
                          <span
                            style={{
                              fontSize: "15px",
                              // position: "absolute",
                              fontWeight: "normal",
                            }}
                          >
                            .00
                          </span>
                        </h4>
                        <div className="d-flex gap-7 mt-7">
                          <div>
                            <button
                              className=" bg-dark"
                              style={{
                                height: "40px",
                                width: "40px",
                                outline: "none",
                                borderRadius: "5px",
                                border: "0px",
                                cursor: "default",
                              }}
                            >
                              <span className="svg-icon svg-icon-1">
                                {/* <i className="bi bi-plus-lg text-light text-hover-light"></i> */}
                                <p className="text-white m-0">
                                  {activeLoan?.loanTerm
                                    ? activeLoan?.loanTerm
                                    : "0"}
                                </p>
                              </span>
                            </button>
                            <p className="mt-2 fs-8">Total EMIs</p>
                          </div>
                          <div>
                            <button
                              className="bg-dark"
                              style={{
                                height: "40px",
                                width: "40px",
                                outline: "none",
                                borderRadius: "5px",
                                border: "0px",
                                cursor: "default",
                              }}
                            >
                              <span className="svg-icon svg-icon-1 ">
                                {/* <i className="bi bi-bank2 text-light text-hover-light"></i> */}
                                <p className="text-white m-0">
                                  {activeLoan?.repaymentSchedules
                                    ? activeLoan?.repaymentSchedules?.filter(
                                        (schedule) =>
                                          schedule.paymentStatus === 5
                                      ).length
                                    : 0}
                                </p>
                              </span>
                            </button>
                            <p className="mt-2 fs-8">Pending EMIs</p>
                          </div>
                          <div>
                            <button
                              className="bg-dark"
                              style={{
                                height: "40px",
                                width: "40px",
                                outline: "none",
                                borderRadius: "5px",
                                border: "0px",
                                cursor: "default",
                              }}
                            >
                              <span className="svg-icon svg-icon-1 ">
                                {/* <i className="bi bi-bank2 text-light text-hover-light"></i> */}
                                <p className="text-white m-0">
                                  {activeLoan?.repaymentSchedules
                                    ? activeLoan?.repaymentSchedules?.filter(
                                        (schedule) =>
                                          schedule.paymentStatus === 4
                                      ).length
                                    : 0}
                                </p>
                              </span>
                            </button>
                            <p className="mt-2 fs-8">Paid EMIs</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card shadow-sm d-flex flex-grow-1 "
                  style={{ width: "100%" }}
                >
                  <div className="card-body p-5 flex-grow-1 p-0 m-0">
                    <div className="d-flex justify-content-between mb-3 flex-grow-1">
                      <h4 className="p-0 m-0">Loan Life Time</h4>
                    </div>
                    <br />

                    <div className="  w-100 d-flex flex-row gap-10">
                      <div className=" card shadow-sm bg-info w-100  p-10 ">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {" "}
                            {activeLoan?.lifeTimeLoanRepaymentAmount
                              ? activeLoan?.lifeTimeLoanRepaymentAmount?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : "0.00"}
                            <span className="fs-9">({baseCurrency})</span>
                            {/* {loans[selectedIndex]?.paidEmiAmount.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )} */}
                          </h1>
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7 "> Paid Amount</p>
                        </div>
                      </div>
                      <div className=" card shadow-sm bg-primary w-100  p-10 ">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {activeLoan?.totalPayableAmount?.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                              ? activeLoan?.totalPayableAmount?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : "0.00"}
                            <span className="fs-9">({baseCurrency})</span>
                            {/* {loans[
                            selectedIndex
                          ]?.pendingEmiAmount.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} */}
                          </h1>
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7"> Pending Amount</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="p-5">
                <div className="py-2 card shadow-sm p-5">
                  <div className="d-flex justify-content-end">
                    <div className="d-flex flex-1 align-items-center gap-10 me-5">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-4">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          name="searchQuery"
                          value={searchQuery}
                          className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                          data-kt-docs-table-filter="search"
                          onChange={handleSearchChange}
                          placeholder="Search Loan"
                          autoComplete="new-password"
                        />
                      </div>
                      <Authorize hasAnyPermission={["MENU_LOAN|ADD"]}>
                        <button
                          className="btn fs-7 p-3"
                          onClick={() => {
                            if (
                              data?.[0]?.loanStatus === 1 ||
                              data?.[0]?.loanStatus === 2 ||
                              data?.[0]?.loanStatus === 5 ||
                              data?.[0]?.loanStatus === 6 ||
                              data?.[0]?.loanStatus === 7
                            ) {
                              Swal.fire({
                                icon: "warning",
                                title: "New Loan Denied !!",
                                text: "You cannot apply for a new loan as you already have an Active loan",
                                allowEscapeKey: true,
                                allowEnterKey: true,
                              });
                            } else {
                              handleOpenModal();
                            }
                          }}
                        >
                          New Loan
                        </button>
                      </Authorize>
                    </div>
                  </div>

                  <div className="tab-content " id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="kt_tab_pane_1"
                      role="tabpanel"
                    >
                      <>
                        <div className="card p-2">
                          <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                            <thead className="bg-gray-200">
                              <tr className="text-start text-dark-600 fw-bold fs-6 ">
                                <th className="min-w-100px p-5">
                                  Amount Borrowed
                                </th>
                                {/* <th className="min-w-100px p-5">Payment</th> */}
                                <th className="min-w-100px p-5 text-nowrap">
                                  Application Date
                                </th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Loan Status
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {currentData && currentData.length > 0 ? (
                                currentData?.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={`${
                                      item?.loanStatus === 3
                                        ? ""
                                        : "cursor-pointer"
                                    }`}
                                    onMouseOver={(e) => {
                                      const target =
                                        e.currentTarget as HTMLElement;
                                      target.style.backgroundColor = "#f1f1f2";
                                    }}
                                    onMouseOut={(e) => {
                                      const target =
                                        e.currentTarget as HTMLElement;
                                      target.style.backgroundColor = "white";
                                    }}
                                    {...(item?.loanStatus !== 3
                                      ? {
                                          "data-bs-toggle": "tooltip",
                                          "data-bs-placement": "left",
                                          "data-bs-dismiss": "click",
                                          "data-bs-trigger": "hover",
                                          id: "kt_modal_toggle_loanDetails",
                                        }
                                      : {})}
                                    onClick={() => {
                                      getLoanDetails(item?.loanGuid);
                                    }}
                                  >
                                    <td className="p-5 text-gray-600 fs-6">
                                      {baseCurrency}{" "}
                                      {item?.loanAmount?.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="p-5 text-gray-600">
                                      {/* {item?.requestedDate} */}
                                      {new Date(
                                        item?.requestedDate
                                      ).toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "2-digit",
                                        year: "numeric",
                                      })}{" "}
                                      {/* |{" "}
                                {new Date(
                                  item?.requestedDate
                                ).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                })} */}
                                    </td>
                                    <td className="p-5 ">
                                      <p
                                        className={
                                          item?.loanStatus === 1
                                            ? "text-gray-600"
                                            : item?.loanStatus === 2
                                              ? "text-success"
                                              : item?.loanStatus === 3
                                                ? "text-danger"
                                                : item?.loanStatus === 4
                                                  ? "text-primary"
                                                  : "text-dark"
                                        }
                                      >
                                        {item?.loanStatus === 1
                                          ? "Under Process"
                                          : item?.loanStatus === 2
                                            ? "Active"
                                            : item?.loanStatus === 3
                                              ? "Declined"
                                              : item?.loanStatus === 4
                                                ? "Paid"
                                                : item?.loanStatus === 5
                                                  ? "Unpaid"
                                                  : item?.loanStatus === 6
                                                    ? "Due"
                                                    : "Overdue"}
                                      </p>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="p-5">
                      <ul className="pagination">
                        <li
                          className={`page-item double-arrow ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          key={0}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePageChange(1)}
                          >
                            <i className="previous"></i>
                            <i className="previous"></i>
                          </a>
                        </li>
                        <li
                          className={`page-item previous ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          key={1}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <i className="previous"></i>
                          </a>
                        </li>
                        {renderPageNumbers()}
                        <li
                          className={`page-item next ${
                            currentPage ===
                            Math.ceil(
                              filteredTransactions?.length / itemsPerPage
                            )
                              ? "disabled"
                              : ""
                          }`}
                          key={2}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <i className="next"></i>
                          </a>
                        </li>
                        <li
                          className={`page-item double-arrow ${
                            currentPage ===
                            Math.ceil(
                              filteredTransactions?.length / itemsPerPage
                            )
                              ? "disabled"
                              : ""
                          }`}
                          key={3}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() =>
                              handlePageChange(
                                Math.ceil(
                                  filteredTransactions?.length / itemsPerPage
                                )
                              )
                            }
                          >
                            <i className="next"></i>
                            <i className="next"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="p-5 d-flex align-items-center">
                      <label
                        htmlFor="itemsPerPage"
                        style={{ fontWeight: "bold" }}
                      >
                        Total: {filteredTransactions?.length}&nbsp;&nbsp;
                      </label>
                      <select
                        id="itemsPerPage"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoanDetailsModal screen={screen} setScreen={setScreen} loan={loan} />

          <div
            className="modal fade"
            tabIndex={-1}
            id="loan-modal"
            ref={modalRef}
          >
            <div
              className="modal-dialog modal-lg"
              style={{ marginTop: "60px" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="fw-bold m-0">Design Your Loan</h3>

                  {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                 <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
                </div>

                <div
                  className="modal-body p-10"
                  style={{
                    maxHeight: "550px",
                    overflow: "auto",
                    padding: "10px",
                    display: authority === 3 ? "flex" : "",
                    minHeight: "300px",
                    alignItems: authority === 3 ? "center" : "center",
                    justifyContent: authority === 3 ? "center" : "center",
                  }}
                >
                  {authority === 3 ? (
                    <div>
                      <p className="fw-bold text-center text-danger">
                        You don't have access to this feature.
                      </p>

                      <p className="fw-bold text-center text-danger">
                        Please contact admin.
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="mb-5  rounded py-4 px-2">
                        <p className="form-label text-center fs-4 fw-bold">
                          Choose the Amount
                        </p>
                        <p
                          className="form-label text-center"
                          style={{ color: "#246bfd" }}
                        >
                          Move the slider to select your loan amount
                        </p>
                        <div className="d-flex  justify-content-center">
                          <div className="d-flex w-400px">
                            <Slider
                              defaultValue={0}
                              aria-label="Default"
                              onChange={(_, newValue) => {
                                console.log(
                                  calculateAmount(newValue as number)
                                );

                                setLoanAmount(
                                  calculateAmount(newValue as number)
                                );
                              }}
                              // valueLabelDisplay="auto"
                              step={50}
                              marks={amount}
                              min={0}
                              max={100}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-5  rounded py-4 px-2">
                        <p className="form-label text-center fs-4 fw-bold">
                          Choose Repayment Period
                        </p>
                        <p
                          className="form-label text-center"
                          style={{ color: "#246bfd" }}
                        >
                          Move the slider to select the loan repayment term in
                          months
                        </p>
                        <div className="d-flex  justify-content-center">
                          <div className="d-flex w-400px">
                            <Slider
                              defaultValue={0}
                              aria-label="Default"
                              onChange={(_, newValue) => {
                                console.log(newValue);
                                const termvalue =
                                  (newValue as number) === 0
                                    ? 1
                                    : (newValue as number);
                                setTerm(termvalue);
                              }}
                              // valueLabelDisplay="auto"
                              step={3}
                              marks={emi}
                              min={0}
                              max={6}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="rounded bg-gray-200 p-5">
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Amount borrowed</p>
                          <p>
                            {baseCurrency}{" "}
                            {quotation?.loanAmount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Rate of Interest</p>
                          <p>{quotation?.interestRate} %</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Repayment Period</p>
                          <p>
                            {quotation?.loanTerm}{" "}
                            {quotation?.loanTerm === 1 ? "Month" : "Months"}
                          </p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Number of Installments</p>
                          <p>{quotation?.loanTerm}</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Due Date</p>
                          <p>{quotation?.dueDate}</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Amount per Month(EMI)</p>
                          <p>
                            {baseCurrency}{" "}
                            {quotation?.emi?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Processing Fee(One time)</p>
                          <p>
                            {baseCurrency}{" "}
                            {quotation?.processingFee?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <p className="fw-bold">Amount to be paid</p>
                          <p>
                            {baseCurrency}{" "}
                            {quotation?.totalPayableAmount?.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </p>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn w-100 mt-5"
                        onClick={() => {
                          applyLoan(loanAmount, term);
                        }}
                      >
                        Apply
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
