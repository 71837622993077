import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import folder from "../../../../_metronic/assets/all-serviceIcons/folder.svg";
import gridImg from "../../../../_metronic/assets/all-serviceIcons/grid-square.svg";
import listImg from "../../../../_metronic/assets/all-serviceIcons/List_view.svg";
import pdf from "../../../../_metronic/assets/all-serviceIcons/pdf-file.svg";
import docx from "../../../../_metronic/assets/all-serviceIcons/docx-file.svg";
import jpeg from "../../../../_metronic/assets/all-serviceIcons/image-files.svg";
import xlsx from "../../../../_metronic/assets/all-serviceIcons/excel-file.svg";

import * as Yup from "yup";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";

import { OverlayTrigger } from "react-bootstrap";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import ShareLinkModal from "./ShareLinkModal";
import {
  createDirectory,
  deleteCustomDirectory,
  downloadFolder,
  listDirectory,
  moveDirectory,
  renameDirectory,
  uplaodFiles,
} from "./documentRoomSlice";
import { useFormik } from "formik";
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import { RootState } from "../../../store";

export const array = [
  {
    name: "invoices",
    size: "500kb",
    type: "folder",
    genType: "System generated",
    lastModified: "19 Aug 2024, 5:20 pm",
    fileGuid: "asdr23454esdf1",
  },
  {
    name: "remittance",
    size: "2Mb",
    type: "folder",
    genType: "User generated",
    lastModified: "22 Sep 2024, 10:30 am",
    fileGuid: "asdr23454esdf2",
  },
  {
    name: "bill payment",
    size: "719kb",
    type: "folder",
    genType: "System generated",
    lastModified: "24 Jun 2024, 11:30 am",
    fileGuid: "asdr23454esdf3",
  },
  {
    name: "accounts",
    size: "233kb",
    type: "folder",
    genType: "System generated",
    lastModified: "21 Feb 2024, 2:40 pm",
    fileGuid: "asdr23454esdf4",
  },
  {
    name: "apps",
    size: "500kb",
    type: "folder",
    genType: "User generated",
    lastModified: "20 Jun 2024, 11:05 am",
    fileGuid: "asdr23454esdf5",
  },
  {
    name: "remittance",
    size: "2Mb",
    type: "folder",
    genType: "System generated",
    lastModified: "22 Sep 2024, 10:30 am",
    fileGuid: "asdr23454esdf6",
  },
  {
    name: "bill payment",
    size: "719kb",
    type: "folder",
    genType: "User generated",
    lastModified: "24 Jun 2024, 11:30 am",
    fileGuid: "asdr23454esdf7",
  },
  {
    name: "business_list.pdf",
    type: "pdf",
    genType: "User generated",
    size: "123kb",
    lastModified: "20 Jun 2024, 11:05 am",
    fileGuid: "asdr23454esdf8",
  },
  {
    name: "vendor_list.docx",
    type: "docx",
    genType: "User generated",
    size: "250kb",
    lastModified: "20 Jun 2024, 11:05 am",
    fileGuid: "asdr23454esdf9",
  },
  {
    name: "invoices.jpeg",
    type: "jpeg",
    genType: "User generated",
    size: "55kb",
    lastModified: "20 Jun 2024, 11:05 am",
    fileGuid: "asdr23454esdf10",
  },
];

const FilterSchema = Yup.object().shape({
  itemName: Yup.string()
    .max(50, "Item name must be 50 characters or less")
    .optional(),
  itemType: Yup.array().min(1, "Please select at least one item type"),
  lastModified: Yup.date()
    .nullable()
    .max(new Date(), "Date cannot be in the future"),
});

const TaxFolder: FC = () => {
  const [add, setAdd] = useState(false);
  const [list, setList] = useState(true);
  const [editingId, setEditingId] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [file, setFile] = useState<File[] | null>();
  const [files, setFiles] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [folderName, setFolderName] = useState("");
  const [rename, setRename] = useState("");
  const [sourceFolderGuid, setSourceFolderGuid] = useState("");
  const [operationType, setOperationType] = useState("");
  const [profileimg, setProfileImg] = useState<string | undefined>("");
  const [profiledoc, setProfileDoc] = useState<string | undefined>("");

  const { folders, count, loading } = useSelector(
    (state: RootState) => state?.folders
  );
  const parentFolderGuid = useSelector(
    (state: RootState) => state?.folders?.folders?.parentFolderGuid
  );
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const filterFormik = useFormik({
    initialValues: {
      itemName: "",
      itemType: [""],
      lastModified: null,
    },
    validationSchema: FilterSchema,
    onSubmit: (values) => {
      // Perform filtering logic here
      console.log();

      console.log("Filter Values:", values);
      dispatch(
        listDirectory({
          parentFolderGuid: parentFolderGuid,
          sourceTypeFilter: values?.itemType[0],
          folderNameFilter: values?.itemName,
          lastModifiedDateFilter: values?.lastModified,
        } as any) as any
      );
      // Close dropdown after submission
      if (dropdownRef.current) {
        const dropdownButton = dropdownRef.current.querySelector(
          '[data-bs-toggle="dropdown"]'
        ) as HTMLButtonElement; // Cast to HTMLButtonElement
        if (dropdownButton) {
          dropdownButton.click();
        }
      }
    },
  });

  const handleViewClick = async () => {
    const modalElement = document.getElementById("docu_image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("docu_pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleAddFile = (uploadedFile: File[] | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
    } else {
      console.error("No file selected.");
    }
  };

  const handleUpload = () => {
    setFiles([]);
    setSelectedFiles([]);
    const modalElement = document.getElementById("kt_modal_upload_file");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  useEffect(() => {
    dispatch(listDirectory({} as any) as any);
  }, [dispatch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(
        listDirectory({
          search: searchTerm,
          parentFolderGuid: parentFolderGuid,
        } as any) as any
      );
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleOpenModal = () => {
    const modalElement = document.getElementById("date_range");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const navigate = useNavigate();
  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(array?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // Handler for item selection
  const handleItemSelect = (fileGuid: string) => {
    setSelectedItems([fileGuid]);
  };

  const [checked, setChecked] = useState<string[]>([]);

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    folderGuid: string
  ) => {
    const target = e.target as HTMLInputElement;

    setChecked((prev) => {
      if (target.checked) {
        // Add the folderGuid to the state
        return [...prev, folderGuid];
      } else {
        // Remove the folderGuid from the state
        return prev.filter((guid) => guid !== folderGuid);
      }
    });
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as Node;
      if (dropdownRef.current && dropdownRef.current.contains(target)) {
        e.stopPropagation();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const items = [
    "ALL_FOLDERS",
    "SYSTEM_GENERATED",
    "USER_GENERATED",
    "ALL_FILES",
  ];

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const totalFiles = files.length + acceptedFiles.length;
      console.log(files);

      if (totalFiles > 10) {
        // toast(`Maximum 10 files can be uploaded at a time`, {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        return;
      }

      const fileNames = acceptedFiles.map((file) => file.name);
      setSelectedFiles((prevSelectedFiles) => [
        ...prevSelectedFiles,
        ...fileNames,
      ]);
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    [files]
  );

  const dropzoneOptions: DropzoneOptions = {
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    maxFiles: 10,
    maxSize: 3000000,
    onDrop,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  const removeFile = (indexToRemove: number) => {
    setSelectedFiles((prevFiles) => {
      // Remove the file at the specified index
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
  };

  const uploadFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // name: "",
      uploadFiles: [],
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string()
      //   .matches(/^[a-zA-Z0-9 _,-]+$/, {
      //     message:
      //       "Only (letters, numbers, spaces, underscores, and commas) are allowed ",
      //   })
      //   .required("List name is required"),
      // uploadFiles: Yup.mixed().test(
      //   "fileType",
      //   "Only .csv and .xlsx files are allowed",
      //   (value) => {
      //     if (!value) return true;
      //     const file = value as File;
      //     const validExtensions = [
      //       ".csv",
      //       ".xlsx",
      //       ".pdf",
      //       ".jpeg",
      //       ".png",
      //       ".jpg",
      //       ".docx",
      //     ];
      //     const extension = file.name
      //       ? file.name.slice(file.name.lastIndexOf("."))
      //       : "";
      //     return validExtensions.includes(extension);
      //   }
      // ),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        console.log(values);
        // if (values.uploadFiles.length !== 0) {
        console.log("api called");

        dispatch(
          uplaodFiles({
            parentFolderGuid: parentFolderGuid,
            uploadFiles: files,
          }) as any
        ).then(() => {
          setFiles([]);
          setTimeout(() => {
            dispatch(
              listDirectory({
                search: searchTerm,
                parentFolderGuid: parentFolderGuid,
              } as any) as any
            );
          }, 500);
        });
        uploadFormik.resetForm();
        uploadFormik.setFieldValue("uploadFiles", "");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        const modal = Modal.getInstance("#kt_modal_upload_file");
        if (modal) {
          modal.hide();
        }
        // } else {
        // uploadFormik.setFieldError("uploadFiles", "File is required");
        // }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Folders & Files</h4>{" "}
      <div className="card p-2">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center  position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control p-3 border-secondary w-250px ps-15"
              placeholder="Search Files & Folders"
              // defaultValue={searchTerm}
              onChange={(event) => handleSearch(event?.target?.value)}
            />
          </div>
          <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
            <div className="dropdown" ref={dropdownRef}>
              <button
                className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#ebe9eb",
                }}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => filterFormik?.resetForm()}
              >
                <i className="bi bi-funnel text-dark fs-2 p-0 m-0"></i>
              </button>

              <ul
                className="dropdown-menu dropdown-menu-end p-10 w-350px"
                aria-labelledby="dropdownMenuButton"
                onClick={(e) => e.stopPropagation()}
              >
                <form onSubmit={filterFormik.handleSubmit}>
                  <li>
                    <p className="fs-4">Filter options</p>
                    <hr />
                  </li>

                  <li className="mb-3">
                    <label className="fs-5 mb-2">Item name</label>
                    <input
                      id="itemName"
                      name="itemName"
                      type="text"
                      className={`form-control ${
                        filterFormik.touched.itemName &&
                        filterFormik.errors.itemName
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter name"
                      onChange={filterFormik.handleChange}
                      onBlur={filterFormik.handleBlur}
                      value={filterFormik.values.itemName}
                    />
                    {filterFormik.touched.itemName &&
                      filterFormik.errors.itemName && (
                        <div className="invalid-feedback">
                          {filterFormik.errors.itemName}
                        </div>
                      )}
                  </li>

                  <li className="mb-3 flex-column">
                    <label className="fs-5 mb-2">Item type</label>
                    <div
                      className="d-inline-flex gap-1 cursor-pointer m-0 form-select mb-3 border-0 px-0"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Select Item type
                    </div>
                    {filterFormik.touched.itemType &&
                      filterFormik.errors.itemType && (
                        <div className="text-danger">
                          {filterFormik.errors.itemType}
                        </div>
                      )}
                    <div className="collapse" id="collapseExample">
                      <div className="card">
                        {items.map((item, index) => (
                          <div key={index} className="d-flex gap-5 mb-3">
                            <label className="form-check form-check-custom form-check-sm">
                              <input
                                className="form-check-input cursor-pointer border border-dark"
                                type="checkbox"
                                id={`itemType-${item}`}
                                name="itemType"
                                value={item}
                                onChange={filterFormik.handleChange}
                                checked={filterFormik.values.itemType.includes(
                                  item
                                )}
                                // onClick={(e) => handleCheckClick(e)}
                              />{" "}
                            </label>
                            <p className="m-0">{item}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>

                  <li className="mb-5">
                    <label className="fs-5 mb-2">Last modified</label>
                    <input
                      id="lastModified"
                      name="lastModified"
                      type="date"
                      className={`form-control ${
                        filterFormik.touched.lastModified &&
                        filterFormik.errors.lastModified
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={filterFormik.handleChange}
                      onBlur={filterFormik.handleBlur}
                      value={filterFormik.values.lastModified || ""}
                    />
                    {filterFormik.touched.lastModified &&
                      filterFormik.errors.lastModified && (
                        <div className="invalid-feedback">
                          {filterFormik.errors.lastModified}
                        </div>
                      )}
                  </li>

                  <li>
                    <div className="d-flex justify-content-end">
                      <button className="btn btn-md btn-primary" type="submit">
                        Apply
                      </button>
                    </div>
                  </li>
                </form>
              </ul>
            </div>

            <div className="d-flex">
              <div
                className="d-flex border border-dark px-2 py-1 rounded-start-5 border-end-0 align-items-center"
                onClick={() => setList(true)}
              >
                {list && (
                  <i className="bi bi-check2 fs-3 fw-bold text-dark"></i>
                )}
                <img
                  src={listImg}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              </div>
              <div
                className="d-flex border border-dark px-2 py-1 rounded-end-5 align-items-center"
                onClick={() => setList(false)}
              >
                {!list && (
                  <i className="bi bi-check2 fs-3 fw-bold text-dark"></i>
                )}
                <img
                  src={gridImg}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              </div>
            </div>

            {checked.length > 0 ? (
              <button
                className={`btn btn-danger fs-7 p-3 w-100px`}
                onClick={() =>
                  Swal.fire({
                    icon: "warning",
                    text: "Are you sure you want to delete the selected folders?",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      dispatch(
                        deleteCustomDirectory({
                          folderGuidList: selectedItems,
                        }) as any
                      ).then(
                        setChecked([]),
                        setTimeout(() => {
                          dispatch(
                            listDirectory({
                              search: searchTerm,
                              parentFolderGuid: parentFolderGuid,
                            } as any) as any
                          );
                        }, 500)
                      );
                    }
                  })
                }
                //   id="kt_modal_toggle_domestic"
                //   disabled={payAmount < 1}
              >
                Delete ({checked.length})
              </button>
            ) : (
              <>
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                {/* <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => setAdd(true)}
                  //   id="kt_modal_toggle_domestic"
                  //   disabled={payAmount < 1}
                >
                  New Folder
                </button> */}
                {/* </Authorize> */}
                {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
                {/* <button
                  className={`btn fs-7 p-3 w-100px`}
                  onClick={() => handleUpload()}
                >
                  Upload Files
                </button> */}
                {/* </Authorize> */}
                <div className="dropdown">
                  <button
                    className="btn ps-5 pe-7"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-plus fs-4 text-white"></i>Add
                  </button>

                  <ul
                    className="dropdown-menu dropdown-menu-end p-2 w-120px"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => setAdd(true)}
                      >
                        New Folder
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleUpload()}
                      >
                        Upload File
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex mb-5 bg-light-primary">
          <div className="d-flex p-2 ">
            {folders?.rootDirectory
              ?.split("/")
              ?.filter((path: string) => path !== "")
              ?.map((path: string, index: number) => (
                <div key={index} className="d-flex align-items-center">
                  <p
                    key={index}
                    className="mb-0 text-primary cursor-pointer"
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.textDecoration = "none")
                    }
                    onClick={() => {
                      if (
                        folders?.rootDirectory
                          ?.split("/")
                          ?.filter((path: string) => path !== "")?.length -
                          1 !==
                        index
                      ) {
                        const folderGuid = folders?.folderToParentMapping[path];
                        if (folderGuid) {
                          dispatch(
                            listDirectory({
                              parentFolderGuid: folderGuid,
                            } as any) as any
                          );
                        } else {
                          const folderGuid =
                            folders?.folderToParentMapping[
                              folders?.businessGuid
                            ];
                          dispatch(
                            listDirectory({
                              parentFolderGuid: folderGuid,
                            } as any) as any
                          );
                        }
                      }
                    }}
                  >
                    {path}
                  </p>
                  <i className="bi bi-chevron-right fs-8 text-dark px-2"></i>
                </div>
              ))}
          </div>
        </div>

        {!list ? (
          // Grid View
          <div className="ms-10 row g-2 gap-10">
            {add && (
              <tr>
                <td></td>
                <td>
                  <div className="d-flex align-items-center gap-5">
                    <input
                      type="text"
                      className="form-control w-300px"
                      placeholder="Folder name"
                      onChange={(event) => setFolderName(event.target.value)}
                    />
                    <div className="d-flex gap-3">
                      <i
                        className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                        onClick={() => {
                          if (folderName) {
                            dispatch(
                              createDirectory({
                                folderName: folderName,
                                parentFolderGuid: parentFolderGuid,
                              }) as any
                            ).then(
                              setTimeout(() => {
                                dispatch(
                                  listDirectory({
                                    search: searchTerm,
                                    parentFolderGuid: parentFolderGuid,
                                  } as any) as any
                                );
                              }, 300)
                            );
                            setAdd(false);
                          }
                        }}
                      ></i>
                      <i
                        className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                        onClick={() => setAdd(false)}
                      ></i>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {folders?.folders?.length > 0 ? (
              folders?.folders?.map((item: any, index: number) => (
                <div className="col-lg-2 col-md-4" key={index}>
                  <div
                    className="border rounded p-4 h-100 bg-light bg-hover-secondary cursor-pointer"
                    onMouseOver={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "#f1f1f2";
                    }}
                    onMouseOut={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "white";
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-start mb-3">
                      <div className="form-check form-check-custom form-check-sm">
                        {item.source === "USER_GENERATED" && (
                          <input
                            className="form-check-input cursor-pointer border border-dark"
                            type="checkbox"
                            checked={checked.includes(item.folderGuid)}
                            onClick={(e) =>
                              handleCheckClick(e, item.folderGuid)
                            }
                          />
                        )}
                      </div>
                      <div className="d-flex gap-2">
                        {/* Action buttons */}

                        <button
                          className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "25%",
                            backgroundColor: "#ebe9eb",
                          }}
                          type="button"
                          onClick={() => handleOpenModal()}
                        >
                          <i className="bi bi-link-45deg text-dark fs-3 p-0 m-0"></i>
                        </button>

                        <div className="dropdown">
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-end p-1"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => {
                                  if (item?.fileName) {
                                    if (
                                      item?.fileName?.split(".").pop() === "pdf"
                                    ) {
                                      setProfileDoc(item?.readUrl);
                                      handleDocViewClick();
                                    } else if (
                                      ["jpeg", "png", "jpg"].includes(
                                        item?.fileName?.split(".").pop() || ""
                                      )
                                    ) {
                                      setProfileImg(item?.readUrl);
                                      handleViewClick();
                                    }
                                  } else if (item.folderName) {
                                    dispatch(
                                      listDirectory({
                                        parentFolderGuid: item.folderGuid,
                                      } as any) as any
                                    );
                                  }
                                }}
                              >
                                View
                              </button>
                            </li>
                            {item.source === "USER_GENERATED" && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => setEditingId(item.folderGuid)}
                                >
                                  Rename
                                </button>
                              </li>
                            )}
                            {item.type !== "FOLDER" && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    navigate("/documentRoom/pdfView", {
                                      state: {
                                        pdfUrl: item?.readUrl,
                                      },
                                    });
                                    // handleOpenModal();
                                  }}
                                >
                                  Self Sign
                                </button>
                              </li>
                            )}
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => {
                                  dispatch(
                                    moveDirectory({
                                      sourceFolderGuid: sourceFolderGuid,
                                      destinationFolderGuid: item.folderGuid,
                                      operationType: operationType,
                                    } as any) as any
                                  ).then(() => {
                                    setOperationType("");
                                    setSourceFolderGuid("");
                                    dispatch(
                                      listDirectory({
                                        parentFolderGuid: item?.folderGuid,
                                      } as any) as any
                                    );
                                  });
                                }}
                              >
                                Paste
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => {
                                  setSourceFolderGuid(item?.folderGuid);
                                  setOperationType("COPY_PASTE");
                                }}
                              >
                                Copy
                              </button>
                            </li>
                            {item.source === "USER_GENERATED" && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    setSourceFolderGuid(item?.folderGuid);
                                    setOperationType("CUT_PASTE");
                                  }}
                                >
                                  Cut
                                </button>
                              </li>
                            )}
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                // onClick={() => setEditingId(item.fileGuid)}
                              >
                                Mirror to Collection
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => {
                                  dispatch(
                                    downloadFolder({
                                      url:
                                        item.type === "FOLDER"
                                          ? "downloadFolder"
                                          : "downloadFile",
                                      folderGuid: item.folderGuid,
                                    }) as any
                                  );
                                }}
                              >
                                Download
                              </button>
                            </li>
                            {item.type === "USER_GENERATED" && (
                              <li>
                                <button
                                  className="dropdown-item text-danger"
                                  type="button"
                                  onClick={() =>
                                    Swal.fire({
                                      icon: "question",
                                      text: "Are you sure you want to delete documents?",
                                      showCancelButton: true,
                                      confirmButtonText: "Yes, delete",
                                    }).then((res) => {
                                      if (res.isConfirmed) {
                                        dispatch(
                                          deleteCustomDirectory({
                                            folderGuidList: [item.folderGuid],
                                          }) as any
                                        ).then(
                                          setChecked([]),
                                          setTimeout(() => {
                                            dispatch(
                                              listDirectory({
                                                search: searchTerm,
                                                parentFolderGuid:
                                                  parentFolderGuid,
                                              } as any) as any
                                            );
                                          }, 500)
                                        );
                                      }
                                    })
                                  }
                                >
                                  Delete
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="text-center mb-3">
                      {item.type === "FOLDER" ? (
                        <img
                          src={folder}
                          alt=""
                          style={{ width: "100px", height: "100px" }}
                        />
                      ) : (
                        <img
                          src={
                            item?.fileName?.split(".").pop() === "pdf"
                              ? pdf
                              : ["jpeg", "png", "jpg"].includes(
                                    item?.fileName?.split(".").pop() || ""
                                  )
                                ? jpeg
                                : ["xlsx", "csv"].includes(
                                      item?.fileName?.split(".").pop() || ""
                                    )
                                  ? xlsx
                                  : docx
                          }
                          alt=""
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </div>

                    {editingId === item.folderGuid ? (
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Folder name"
                          defaultValue={
                            item.folderName ? item.folderName : item.fileName
                          }
                        />
                        <div className="d-flex gap-1">
                          <i
                            className="bi bi-check-circle text-primary cursor-pointer"
                            onClick={() => setEditingId(null)}
                          ></i>
                          <i
                            className="bi bi-x-circle text-danger cursor-pointer"
                            onClick={() => setEditingId(null)}
                          ></i>
                        </div>
                      </div>
                    ) : (
                      <h5 className="text-center text-gray-800 mb-2">
                        {item.folderName ? item.folderName : item.fileName} (
                        {item.size})
                      </h5>
                    )}

                    <div className="text-center text-gray-600 small">
                      <div>{item.lastModified}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-5">
                <p className="text-gray-600">No Data Found</p>
              </div>
            )}
          </div>
        ) : (
          <div>
            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
              <thead className="bg-gray-200">
                <tr className="text-start text-dark-600 fw-bold fs-6 ">
                  <th className="px-3">
                    {" "}
                    <label className="form-check form-check-custom form-check-sm fs-7">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        // checked={selectAll}
                        // onClick={handleSelectAll}
                      />{" "}
                    </label>
                  </th>

                  <th className="min-w-200px p-5">Name</th>
                  <th className="min-w-200px p-5">Type</th>
                  <th className="min-w-100px p-5">Size</th>
                  <th className="min-w-100px p-5">Last Modified</th>
                  <th className="min-w-100px p-5">Action</th>
                </tr>
              </thead>

              <tbody>
                {add && (
                  <tr>
                    <td></td>
                    <td>
                      <div className="d-flex align-items-center gap-5">
                        <input
                          type="text"
                          className="form-control w-300px"
                          placeholder="Folder name"
                          onChange={(event) =>
                            setFolderName(event.target.value)
                          }
                        />
                        <div className="d-flex gap-3">
                          <i
                            className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                            onClick={() => {
                              if (folderName) {
                                dispatch(
                                  createDirectory({
                                    folderName: folderName,
                                    parentFolderGuid: parentFolderGuid,
                                  }) as any
                                ).then(
                                  setTimeout(() => {
                                    dispatch(
                                      listDirectory({
                                        search: searchTerm,
                                        parentFolderGuid: parentFolderGuid,
                                      } as any) as any
                                    );
                                  }, 300),
                                  setAdd(false)
                                );
                              }
                            }}
                          ></i>
                          <i
                            className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                            onClick={() => setAdd(false)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {folders?.folders?.length > 0 ? (
                  folders?.folders?.map((item: any, index: number) => (
                    <tr
                      key={index}
                      onMouseOver={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "#f1f1f2";
                      }}
                      onMouseOut={(e) => {
                        const target = e.currentTarget as HTMLElement;
                        target.style.backgroundColor = "white";
                      }}
                    >
                      <td className="px-3">
                        {item.source === "USER_GENERATED" && (
                          <label className="form-check form-check-custom form-check-sm fs-7">
                            <input
                              className="form-check-input cursor-pointer border border-dark"
                              type="checkbox"
                              checked={checked.includes(item.folderGuid)}
                              onClick={(e) =>
                                handleCheckClick(e, item.folderGuid)
                              }
                            />{" "}
                          </label>
                        )}
                      </td>

                      <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                        <div className=" d-flex flex-row gap-3 align-items-center">
                          {item?.type === "FOLDER" ? (
                            <i
                              className={`bi bi-folder2-open fs-2 text-${
                                item.folderGuid === "System Generated"
                                  ? "primary"
                                  : "dark"
                              }`}
                            ></i>
                          ) : (
                            <img
                              src={
                                item?.fileName?.split(".").pop() === "pdf"
                                  ? pdf
                                  : ["jpeg", "png", "jpg"].includes(
                                        item?.fileName?.split(".").pop() || ""
                                      )
                                    ? jpeg
                                    : ["xlsx", "csv"].includes(
                                          item?.fileName?.split(".").pop() || ""
                                        )
                                      ? xlsx
                                      : docx
                              }
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                          )}
                          {editingId === item.folderGuid ? (
                            <div className="d-flex align-items-center gap-5">
                              <input
                                type="text"
                                className="form-control w-300px"
                                placeholder="Folder name"
                                defaultValue={
                                  item.folderName
                                    ? item.folderName
                                    : item.fileName
                                }
                                onChange={(e) => setRename(e.target.value)}
                              />
                              <div className="d-flex gap-3">
                                <i
                                  className="bi bi-check-circle fw-bold fs-2x text-primary cursor-pointer"
                                  onClick={() => {
                                    dispatch(
                                      renameDirectory({
                                        parentFolderGuid: item?.folderGuid,
                                        folderName: rename,
                                      }) as any
                                    ).then(() => {
                                      setRename("");
                                      dispatch(
                                        listDirectory({
                                          parentFolderGuid: parentFolderGuid,
                                        } as any) as any
                                      );
                                    });
                                    setEditingId(null);
                                  }}
                                ></i>
                                <i
                                  className="bi bi-x-circle fw-bold fs-2x text-danger cursor-pointer"
                                  onClick={() => setEditingId(null)}
                                ></i>
                              </div>
                            </div>
                          ) : (
                            <p
                              className={`text-${item.source === "SYSTEM GENERATED" ? "primary" : "gray-600"} fw-hover-bold  fs-6 mb-0`}
                              onClick={() => {
                                if (item?.fileName) {
                                  if (
                                    item?.fileName?.split(".").pop() === "pdf"
                                  ) {
                                    setProfileDoc(item?.readUrl);
                                    handleDocViewClick();
                                  } else if (
                                    ["jpeg", "png", "jpg"].includes(
                                      item?.fileName?.split(".").pop() || ""
                                    )
                                  ) {
                                    setProfileImg(item?.readUrl);
                                    handleViewClick();
                                  }
                                } else if (item.folderName) {
                                  dispatch(
                                    listDirectory({
                                      parentFolderGuid: item.folderGuid,
                                    } as any) as any
                                  );
                                }
                              }}
                            >
                              {item.folderName
                                ? item.folderName
                                : item.fileName}
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="p-5 text-light text-hover-dark fw-hover-bold fs-6">
                        <p
                          className="mb-0 badge text-light"
                          style={{
                            backgroundColor:
                              item.source === "SYSTEM_GENERATED"
                                ? "#FF8A65"
                                : "#AB47BC",
                          }}
                        >
                          {item.source === "SYSTEM_GENERATED"
                            ? "System Generated"
                            : "User Generated"}
                        </p>
                      </td>

                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.size}</p>
                      </td>
                      <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                        <p className="mb-0"> {item.lastModified}</p>
                      </td>
                      <td className="p-5 text-gray-600 p-2">
                        <div className="d-flex gap-5 align-items-center">
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            onClick={() => handleOpenModal()}
                          >
                            <i className="bi bi-link-45deg text-dark fs-3 p-0 m-0"></i>
                          </button>

                          <div className="dropdown" style={{ width: "20px" }}>
                            <button
                              className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "25%",
                                backgroundColor: "#ebe9eb",
                              }}
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                            </button>

                            <ul
                              className="dropdown-menu dropdown-menu-end p-1"
                              aria-labelledby="dropdownMenuButton"
                              style={{ zIndex: 1000000 }}
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    if (item?.fileName) {
                                      if (
                                        item?.fileName?.split(".").pop() ===
                                        "pdf"
                                      ) {
                                        setProfileDoc(item?.readUrl);
                                        handleDocViewClick();
                                      } else if (
                                        ["jpeg", "png", "jpg"].includes(
                                          item?.fileName?.split(".").pop() || ""
                                        )
                                      ) {
                                        setProfileImg(item?.readUrl);
                                        handleViewClick();
                                      }
                                    } else if (item.folderName) {
                                      dispatch(
                                        listDirectory({
                                          parentFolderGuid: item.folderGuid,
                                        } as any) as any
                                      );
                                    }
                                  }}
                                >
                                  View
                                </button>
                              </li>
                              {item.source === "USER_GENERATED" && (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      setEditingId(item.folderGuid)
                                    }
                                  >
                                    Rename
                                  </button>
                                </li>
                              )}
                              {item.type !== "FOLDER" &&
                                item?.fileName?.split(".").pop() === "pdf" && (
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        navigate("/documentRoom/pdfView", {
                                          state: {
                                            pdfUrl: item?.readUrl,
                                            itemId: item?.folderGuid,
                                          },
                                        });
                                        // handleOpenModal();
                                      }}
                                    >
                                      Self Sign
                                    </button>
                                  </li>
                                )}
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    dispatch(
                                      moveDirectory({
                                        sourceFolderGuid: sourceFolderGuid,
                                        destinationFolderGuid: item.folderGuid,
                                        operationType: operationType,
                                      } as any) as any
                                    ).then(() => {
                                      setOperationType("");
                                      setSourceFolderGuid("");
                                      dispatch(
                                        listDirectory({
                                          parentFolderGuid: item?.folderGuid,
                                        } as any) as any
                                      );
                                    });
                                  }}
                                >
                                  Paste
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    setSourceFolderGuid(item?.folderGuid);
                                    setOperationType("COPY_PASTE");
                                  }}
                                >
                                  Copy
                                </button>
                              </li>
                              {item.source === "USER_GENERATED" && (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      setSourceFolderGuid(item?.folderGuid);
                                      setOperationType("CUT_PASTE");
                                    }}
                                  >
                                    Cut
                                  </button>
                                </li>
                              )}
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  // onClick={() => setEditingId(item.fileGuid)}
                                >
                                  Mirror to Collection
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    dispatch(
                                      downloadFolder({
                                        url:
                                          item.type === "FOLDER"
                                            ? "downloadFolder"
                                            : "downloadFile",
                                        folderGuid: item.folderGuid,
                                      }) as any
                                    );
                                  }}
                                >
                                  Download
                                </button>
                              </li>
                              {item.source === "USER_GENERATED" && (
                                <li>
                                  <button
                                    className="dropdown-item text-danger"
                                    type="button"
                                    onClick={() =>
                                      Swal.fire({
                                        icon: "question",
                                        text: "Are you sure you want to delete documents?",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete",
                                      }).then((res) => {
                                        if (res.isConfirmed) {
                                          dispatch(
                                            deleteCustomDirectory({
                                              folderGuidList: [item.folderGuid],
                                            }) as any
                                          ).then(
                                            setChecked([]),
                                            setTimeout(() => {
                                              dispatch(
                                                listDirectory({
                                                  search: searchTerm,
                                                  parentFolderGuid:
                                                    parentFolderGuid,
                                                } as any) as any
                                              );
                                            }, 500)
                                          );
                                        }
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(array?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(Math.ceil(array?.length / itemsPerPage))
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {folders?.folders?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              //   onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="kt_modal_upload_file"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div
              className="modal-header d-flex justify-content-between"
              id="kt_modal_upload_file_header"
            >
              <h2 className="fw-bold">Upload File</h2>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form
                id="kt_modal_upload_file_form"
                className="form"
                onSubmit={uploadFormik.handleSubmit}
              >
                <div className="d-flex flex-column scroll-y me-n7 pe-7">
                  <div
                    className={`dropzone ${isDragActive ? "drag-active" : ""}`}
                    // id="kt_modal_create_ticket_attachments"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />

                    {selectedFiles.length > 0 ? (
                      <div className="d-flex min-h-200px align-items-center justify-content-center">
                        <div className="d-flex flex-wrap">
                          {selectedFiles.map((fileName, index) => (
                            <div
                              className="d-flex flex-row align-items-center bg-secondary gap-3 px-2 py-1 ms-3 mb-3"
                              key={index}
                              style={{
                                zIndex: 1,
                                borderRadius: "5px",
                                flexBasis: "calc(33.33% - 20px)",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <p className="m-0">{fileName}</p>
                              <i
                                className="bi bi-x m-0 text-dark fs-4"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFile(index);
                                }}
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="dz-message needsclick align-items-center flex-column gap-5 p-5">
                        <FontAwesomeIcon
                          icon={faCloudUploadAlt}
                          size="4x"
                          style={{ color: "#888" }}
                        />

                        <div className="ms-4">
                          <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                            Drop files here or click to upload.
                          </h3>
                          {/* <span className="fw-semibold fs-7 text-gray-500">
                            Upload up to 10 files (Maximum upload size 3mb)
                          </span> */}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* {uploadFormik.touched.uploadFiles &&
                    uploadFormik.errors.uploadFiles && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {uploadFormik.errors.uploadFiles}
                          </span>
                        </div>
                      </div>
                    )} */}
                </div>
                <div className="text-center pt-15">
                  <button
                    type="reset"
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      uploadFormik.resetForm();
                    }}
                  >
                    Discard
                  </button>

                  <button
                    type="submit"
                    id="kt_modal_upload_file_submit"
                    className="btn btn-primary"
                    disabled={selectedFiles?.length === 0}
                  >
                    <span className="indicator-label">Submit</span>
                    {uploadFormik.isSubmitting && (
                      <span className="indicator-progress">
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ShareLinkModal />
      <div className="modal" tabIndex={-1} id="docu_image_modal">
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div className="modal-body justify-content-center d-flex">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                <img
                  className="image"
                  src={profileimg}
                  style={{
                    borderRadius: "10px",
                    objectFit: "contain",
                    maxWidth: "500px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="docu_pdf_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
          <div
            className="modal-content"
            style={{ maxHeight: "700px", overflow: "auto" }}
          >
            <div className="modal-header" id="kt_modal_add_user_header">
              <h3 className="fw-bold m-0">PDF Viewer</h3>

              {/* <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div> */}
            </div>

            <div className="modal-body px-15">
              <embed
                src={`${profiledoc}#toolbar=0`}
                type="application/pdf"
                width="100%"
                height="800px"
              />
              {/* Render Excel viewer */}
              {/* <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewImage}`}
                        width="600px"
                        height="600px"
                      /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { TaxFolder };
