import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignaturePad from "signature_pad";
import folder from "../../../_metronic/assets/all-serviceIcons/folder.svg";
import { Document, Page, pdfjs } from "react-pdf";
import { Documents, findSettings, uploadSignatureFiles } from "./settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { signDocument } from "./documentRoomSlice";

// Explicitly set the worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// Type definitions
export interface SignatureData {
  dataUrl: string;
  type: "draw" | "type" | "upload" | "saved";
}

interface SignatureModalProps {
  //   pdfElement: HTMLDivElement | null;
  setGuid: (id: string) => void;
  onSignatureCreated: (data: SignatureData) => void;
}

interface SignatureCanvasProps {
  onSave: (dataUrl: string) => void;
}

interface SignaturePlacerProps {
  pdfElement: HTMLCanvasElement | null;
  signatureData: SignatureData | null;
  onPlacementComplete: () => void;
  placements: SignaturePlacement[];
  setPlacements: React.Dispatch<React.SetStateAction<SignaturePlacement[]>>;
  currentPage: number;
}

interface SignaturePlacement extends Position {
  id: number;
  w: number;
  h: number;
  pageNumber: number;
  documentGuid: SignatureData;
}

interface ResizableImageProps {
  placement: SignaturePlacement;
  pdfElement: HTMLCanvasElement | null;
  removePlacement: (id: string) => void;
  handlePlaceSignature: (e: React.MouseEvent) => void;
  onUpdatePlacement: (id: number, placement: SignaturePlacement) => void;
}

interface LocationState {
  pdfUrl: string;
  itemId: string;
}

interface Position {
  x: number;
  y: number;
}

const fonts = [
  "Dancing Script",
  "Satisfy",
  "Alex Brush",
  "Great Vibes",
  "Pacifico",
  "Bonheur Royale",
];

const dataURLtoFile = (dataURL: string, fileName: string): File => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1] || "image/png";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

// SignatureModal Component
const SignatureModal: React.FC<SignatureModalProps> = ({
  onSignatureCreated,
  setGuid,
}) => {
  const [selectedTab, setSelectedTab] = useState<
    "Draw" | "Type" | "Upload" | "Select"
  >("Draw");
  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState<string>("");
  const [selectedFont, setSelectedFont] = useState<string>("Dancing Script");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { settings, loading } = useSelector(
    (state: RootState) => state?.settings
  );

  useEffect(() => {
    dispatch(findSettings({} as any) as any);
  }, [dispatch]);

  const handleTabClick = (tab: "Draw" | "Type" | "Upload" | "Select") => {
    setSelectedTab(tab);
  };

  const handleFontChange = () => {
    const currentIndex = fonts.indexOf(selectedFont);
    const nextIndex = (currentIndex + 1) % fonts.length; // Cycle back to the start
    setSelectedFont(fonts[nextIndex]);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleAddFile = (uploadedFile: File) => {
    setFile(uploadedFile);
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleItemSelect = async (fileGuid: string, url: string) => {
    setSelectedItems([fileGuid]);
    setGuid(fileGuid);
    if (url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            onSignatureCreated({
              dataUrl: reader.result.toString(),
              type: "saved",
            });
          }
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Failed to convert image URL to Data URL:", error);
      }
    }
  };

  const handleUploadSave = () => {
    if (file) {
      dispatch(uploadSignatureFiles({ uploadFiles: [file] } as any) as any);

      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          onSignatureCreated({
            dataUrl: reader.result.toString(),
            type: "upload",
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const saveTypeSignature = () => {
    if (!text || !selectedFont) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.font = `32px ${selectedFont}`;
    const textWidth = ctx.measureText(text).width;
    const padding = 20;

    canvas.width = textWidth + padding * 2;
    canvas.height = 60;

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.font = `32px ${selectedFont}`;
    ctx.fillStyle = "black";
    ctx.textBaseline = "middle";
    ctx.fillText(text, padding, canvas.height / 2);

    const dataUrl = canvas.toDataURL("image/png");

    // Convert dataURL to a File object
    const file = dataURLtoFile(dataUrl, "signature.png");
    const files: File[] = [file];
    dispatch(uploadSignatureFiles({ uploadFiles: files } as any) as any);

    onSignatureCreated({
      dataUrl,
      type: "type",
    });
  };

  return (
    <div className="modal fade" id="sign_modal" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered min-w-650px">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Add your signature</h4>
          </div>
          <div className="modal-body p-4 min-h-350px">
            <ul className="nav nav-tabs mb-4">
              {(["Draw", "Type", "Upload", "Select"] as const).map((tab) => (
                <li key={tab} className="nav-item">
                  <button
                    className={`nav-link ${selectedTab === tab ? "active" : ""}`}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </button>
                </li>
              ))}
            </ul>

            <div className="tab-content">
              {selectedTab === "Draw" && (
                <SignatureCanvas
                  onSave={(dataUrl) =>
                    onSignatureCreated({ dataUrl, type: "draw" })
                  }
                />
              )}
              {selectedTab === "Type" && (
                <div className="type-signature">
                  <div
                    ref={textContainerRef}
                    className="d-flex align-items-end border border-gray-300  rounded w-100 mb-4"
                    style={{
                      // width: "500px",
                      height: "200px",
                    }}
                  >
                    <input
                      type="text"
                      value={text}
                      onChange={handleTextChange}
                      className="form-control fs-5x px-5 py-3 border border-0"
                      style={{ fontFamily: selectedFont, fontSize: "32px" }}
                      placeholder="Type your signature"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div
                      className="d-flex align-items-center justify-content-end cursor-pointer border-bottom border-dark"
                      onClick={() => handleFontChange()}
                    >
                      <i className="bi bi-shuffle text-dark fw-bold fs-5 me-3"></i>
                      <p className="m-0 fs-5">Change font</p>
                    </div>
                    <button
                      className="btn btn-sm"
                      onClick={saveTypeSignature}
                      disabled={!text}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
              {selectedTab === "Upload" && (
                <div className="upload-signature">
                  <form
                    id="kt_modal_upload_file_form"
                    className="form"
                    //   onSubmit={editFormik.handleSubmit}
                  >
                    <div className="d-flex flex-column scroll-y">
                      {file ? (
                        <div className="d-flex flex-column w-100 justify-content-center ">
                          <div className="d-flex justify-content-center">
                            <div
                              style={{
                                border: "1px dashed #888",
                                borderRadius: "8px",
                                padding: "20px",
                                width: "532px",
                                height: "200px",
                              }}
                            >
                              <div
                                className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                                style={{ width: "490px", height: "155px" }}
                              >
                                <div className="d-flex flex-row">
                                  <p className="fw-bold">{file.size}&nbsp;</p>
                                  <span>KB</span>
                                </div>
                                <div className="text-container my-2">
                                  <p className="text-truncate">{file.name}</p>
                                </div>
                                <p
                                  className="text-danger text-decoration-underline fw-bold cursor-pointer"
                                  onClick={handleFileRemove}
                                >
                                  Remove
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignSelf: "center",
                              width: "532px",
                              height: "200px",
                            }}
                          >
                            <FileUploader
                              handleChange={handleAddFile}
                              name="file"
                              types={["jpg", "jpeg", "png", "svg", "webp"]}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px dashed #888",
                                  borderRadius: "8px",
                                  padding: "20px",
                                  marginBottom: "10px",
                                  width: "532px",
                                  height: "200px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCloudUploadAlt}
                                  size="4x"
                                  style={{ color: "#888" }}
                                />
                                <p style={{ marginTop: "10px", color: "#888" }}>
                                  Drag & drop image here, or click to select
                                </p>
                                {/* <p style={{ marginTop: "10px", color: "#888" }}>
                                  Max file size: 1 MB
                                </p> */}
                              </div>
                            </FileUploader>
                            {/* {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )} */}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="text-end pt-5">
                      <button
                        type="reset"
                        className="btn btn-light btn-sm me-3"
                        onClick={handleFileRemove}
                      >
                        Remove
                      </button>
                      <button
                        type="button"
                        // id="kt_modal_upload_file_submit"
                        className="btn btn-sm"
                        disabled={!file}
                        onClick={handleUploadSave}
                      >
                        <span className="indicator-label">Save</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {selectedTab === "Select" && (
                <>
                  {" "}
                  <div className=" max-h-200px overflow-y-scroll">
                    <div
                      className="row g-2"
                      //   style={{
                      //     height: "250px",
                      //     overflow: "scroll",
                      //   }}
                    >
                      {settings?.documents?.map(
                        (item: Documents, index: number) => (
                          <div className="col-lg-3 col-md-2" key={index}>
                            <div
                              className={`border rounded p-4 h-100 cursor-pointer ${
                                selectedItems.includes(item.documentGuid)
                                  ? "bg-light-primary border-primary"
                                  : "bg-light bg-hover-secondary"
                              }`}
                              onClick={() =>
                                handleItemSelect(
                                  item?.documentGuid,
                                  item?.documentPath
                                )
                              }
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                              }}
                            >
                              <div className="text-center">
                                {/* <i className="bi bi-folder2-open fs-1 text-primary"></i> */}
                                <img
                                  src={item?.documentPath || folder}
                                  alt=""
                                  style={{ width: "100px", height: "80px" }}
                                  // onMouseDown={handleStartDragging}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="text-end pt-5">
                    <button
                      type="reset"
                      className="btn btn-light btn-sm me-3"
                      onClick={handleFileRemove}
                    >
                      Delete
                    </button>

                    <button
                      type="submit"
                      id="kt_modal_upload_file_submit"
                      className="btn btn-sm"
                      //   disabled={!file}
                      //   onClick={handleUploadSave}
                    >
                      <span className="indicator-label">Insert</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// SignatureCanvas Component
const SignatureCanvas: React.FC<SignatureCanvasProps> = ({ onSave }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canvasRef.current) {
      signaturePadRef.current = new SignaturePad(canvasRef.current, {
        backgroundColor: "rgb(255, 255, 255)",
      });
    }

    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.off();
      }
    };
  }, []);

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const saveSignature = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const dataURL = signaturePadRef.current.toDataURL();

      // Convert dataURL to a File object
      const file = dataURLtoFile(dataURL, "signature.png");
      const files: File[] = [file];
      dispatch(uploadSignatureFiles({ uploadFiles: files } as any) as any);

      // Call the onSave callback
      onSave(dataURL);
    }
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        className="border rounded"
        width={600}
        height={200}
      />
      <div className="text-end mt-4">
        <button onClick={clearSignature} className="btn btn-light btn-sm me-2">
          Clear
        </button>
        <button onClick={saveSignature} className="btn btn-sm">
          Save
        </button>
      </div>
    </div>
  );
};

const ResizableImage: React.FC<ResizableImageProps> = ({
  placement,
  removePlacement,
  handlePlaceSignature,
  onUpdatePlacement,
  pdfElement,
}) => {
  const [size, setSize] = useState({
    width: placement.w,
    height: placement.h,
  });
  const [position, setPosition] = useState({ x: placement.x, y: placement.y });
  const [isDragging, setIsDragging] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(placement.pageNumber);

  const calculateCurrentPage = (yPosition: number) => {
    if (!pdfElement) return 1;
    const pageHeight = pdfElement.clientHeight;
    return Math.floor(yPosition / pageHeight) + 1;
  };

  // Constrain position within PDF container
  const constrainPosition = (
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    if (!pdfElement) return { x, y };

    const pdfRect = pdfElement.getBoundingClientRect();
    const pdfScrollHeight = pdfElement.scrollHeight;

    // const rect = pdfElement.getBoundingClientRect();

    // // Get coordinates relative to the PDF
    // const x = e.clientX - rect.left;
    // const y = rect.height - (e.clientY - rect.top);

    const constrainedX = Math.max(0, Math.min(x, pdfRect.width - width));

    const constrainedY = Math.max(0, Math.min(y, pdfScrollHeight - height));

    return { x: constrainedX, y: constrainedY };
  };

  // Update page number when position changes
  useEffect(() => {
    const newPage = calculateCurrentPage(position.y);
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      onUpdatePlacement(placement.id, {
        ...placement,
        x: position.x,
        y: position.y,
        pageNumber: newPage,
      });
    }
  }, [position.y, currentPage, placement, onUpdatePlacement]);

  const handleSignatureClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsActive(true);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest(`[data-signature-id="${placement.id}"]`)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [placement.id]);

  const startResizing = (
    mouseDownEvent: React.MouseEvent<HTMLDivElement>,
    corner: string
  ) => {
    if (!isActive || !pdfElement) return;
    mouseDownEvent.stopPropagation();
    const startSize = size;
    const startPosition = {
      x: mouseDownEvent.pageX,
      y: mouseDownEvent.pageY,
    };
    const startImagePosition = { ...position };
    const pdfRect = pdfElement.getBoundingClientRect();

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      mouseMoveEvent.preventDefault();
      const deltaX = mouseMoveEvent.pageX - startPosition.x;
      const deltaY = mouseMoveEvent.pageY - startPosition.y;

      let newWidth = startSize.width;
      let newHeight = startSize.height;
      let newX = startImagePosition.x;
      let newY = startImagePosition.y;

      // Minimum size constraint
      const minSize = 50;

      // Maximum size constraint based on PDF container
      const maxWidth = pdfRect.width - newX;
      const maxHeight = pdfRect?.height - newY;

      switch (corner) {
        case "top-left":
          newWidth = Math.max(
            minSize,
            Math.min(
              startSize.width - deltaX,
              startSize.width + startPosition.x - mouseMoveEvent.pageX
            )
          );
          newHeight = Math.max(
            minSize,
            Math.min(
              startSize.height - deltaY,
              startSize.height + startPosition.y - mouseMoveEvent.pageY
            )
          );
          newX = startImagePosition.x + startSize.width - newWidth;
          newY = startImagePosition.y + startSize.height - newHeight;
          break;
        case "top-right":
          newWidth = Math.max(
            minSize,
            Math.min(startSize.width + deltaX, maxWidth)
          );
          newHeight = Math.max(
            minSize,
            Math.min(
              startSize.height - deltaY,
              startSize.height + startPosition.y - mouseMoveEvent.pageY
            )
          );
          newY = startImagePosition.y + startSize.height - newHeight;
          break;
        case "bottom-left":
          newWidth = Math.max(
            minSize,
            Math.min(
              startSize.width - deltaX,
              startSize.width + startPosition.x - mouseMoveEvent.pageX
            )
          );
          newHeight = Math.max(
            minSize,
            Math.min(startSize.height + deltaY, maxHeight)
          );
          newX = startImagePosition.x + startSize.width - newWidth;
          break;
        case "bottom-right":
          newWidth = Math.max(
            minSize,
            Math.min(startSize.width + deltaX, maxWidth)
          );
          newHeight = Math.max(
            minSize,
            Math.min(startSize.height + deltaY, maxHeight)
          );
          break;
      }

      // Constrain final position
      const constrainedPosition = constrainPosition(
        newX,
        newY,
        newWidth,
        newHeight
      );

      setSize({ width: newWidth, height: newHeight });
      setPosition(constrainedPosition);

      const newPage = calculateCurrentPage(constrainedPosition.y);
      onUpdatePlacement(placement.id, {
        ...placement,
        x: constrainedPosition.x,
        y: constrainedPosition.y,
        w: newWidth,
        h: newHeight,
        pageNumber: newPage,
      });
    }

    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const startDragging = (e: React.MouseEvent) => {
    if (!isActive || !pdfElement) return;
    e.stopPropagation();
    setIsDragging(true);
    const startPosition = { x: e.pageX - position.x, y: e.pageY - position.y };
    const pdfRect = pdfElement.getBoundingClientRect();

    const onMouseMove = (moveEvent: MouseEvent) => {
      const newX = moveEvent.pageX - startPosition.x;
      const newY = moveEvent.pageY - startPosition.y;

      // Constrain position within PDF container
      const constrainedPosition = constrainPosition(
        newX,
        newY,
        size.width,
        size.height
      );

      setPosition(constrainedPosition);

      const newPage = calculateCurrentPage(constrainedPosition.y);
      onUpdatePlacement(placement.id, {
        ...placement,
        x: constrainedPosition.x,
        y: constrainedPosition.y,
        pageNumber: newPage,
      });
      handlePlaceSignature(e);
    };

    const onMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const resizeHandleStyle = {
    position: "absolute",
    width: "5px",
    height: "5px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    border: "1px solid #666",
    zIndex: 2,
    display: isActive ? "block" : "none",
  } as const;

  return (
    <div
      data-signature-id={placement.id}
      className={`position-absolute ${isActive ? "signature-active" : ""}`}
      style={{
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
        cursor: isDragging ? "grabbing" : isActive ? "grab" : "pointer",
        border: isActive ? "2px solid #007bff" : "none",
      }}
      onClick={handleSignatureClick}
      onMouseDown={startDragging}
    >
      <img
        src={placement.documentGuid.dataUrl}
        alt="Placed Signature"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          pointerEvents: "none",
        }}
      />

      {/* Resize handles */}
      <div
        style={{
          ...resizeHandleStyle,
          top: "-2.5px",
          left: "-2.5px",
          cursor: "nw-resize",
        }}
        onMouseDown={(e) => startResizing(e, "top-left")}
      />
      <div
        style={{
          ...resizeHandleStyle,
          top: "-2.5px",
          right: "-2.5px",
          cursor: "ne-resize",
        }}
        onMouseDown={(e) => startResizing(e, "top-right")}
      />
      <div
        style={{
          ...resizeHandleStyle,
          bottom: "-2.5px",
          left: "-2.5px",
          cursor: "sw-resize",
        }}
        onMouseDown={(e) => startResizing(e, "bottom-left")}
      />
      <div
        style={{
          ...resizeHandleStyle,
          bottom: "-2.5px",
          right: "-2.5px",
          cursor: "se-resize",
        }}
        onMouseDown={(e) => startResizing(e, "bottom-right")}
      />

      {isActive && (
        <>
          <i
            className="position-absolute top-0 end-0 bi bi-trash text-danger fs-6 cursor-pointer mt-2 me-2"
            onClick={() => removePlacement(placement.id.toString())}
          ></i>
        </>
      )}
    </div>
  );
};

// SignaturePlacer Component
const SignaturePlacer: React.FC<SignaturePlacerProps> = ({
  pdfElement,
  signatureData,
  onPlacementComplete,
  placements,
  setPlacements,
  currentPage = 1,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  //   const [placements, setPlacements] = useState<SignaturePlacement[]>([]);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const floatingSignatureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && pdfElement) {
        const pdfRect = pdfElement.getBoundingClientRect();

        // Constrain cursor position within PDF container
        const constrainedX = Math.max(
          pdfRect.left,
          Math.min(e.clientX, pdfRect.right)
        );
        const constrainedY = Math.max(
          pdfRect.top,
          Math.min(e.clientY, pdfRect.bottom)
        );

        setCursorPosition({ x: constrainedX, y: constrainedY });
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [isDragging, pdfElement]);

  useEffect(() => {
    if (signatureData) {
      setIsDragging(true);
    }
  }, [signatureData]);

  const handlePlaceSignature = (e: React.MouseEvent) => {
    if (!isDragging || !pdfElement || !signatureData) return;

    const pdfRect = pdfElement.getBoundingClientRect();
    const scrollTop = pdfElement.scrollTop;
    const pageHeight = pdfElement.clientHeight;

    // Calculate page number based on scroll position
    const pageNumber = currentPage;

    // Calculate local Y position within the current page
    const localY =
      e.clientY - pdfRect.top + scrollTop - (pageNumber - 1) * pageHeight;

    // Constrain signature placement within PDF container
    const x = Math.max(
      0,
      Math.min(
        e.clientX - pdfRect.left - 75,
        pdfRect.width - 150 // Subtract signature width
      )
    );
    const y = Math.max(
      0,
      Math.min(
        localY - 30,
        pageHeight - 60 // Subtract signature height
      )
    );

    const newPlacement: SignaturePlacement = {
      id: Date.now(),
      x,
      y,
      w: 150,
      h: 60,
      documentGuid: signatureData,
      pageNumber,
    };

    setPlacements([...placements, newPlacement]);
    setIsDragging(false);
    onPlacementComplete();
  };

  const updatePlacement = (
    id: number,
    updatedPlacement: SignaturePlacement
  ) => {
    setPlacements(placements.map((p) => (p.id === id ? updatedPlacement : p)));
  };

  const removePlacement = (id: string) => {
    setPlacements(placements.filter((p) => p.id.toString() !== id));
  };

  return (
    <div
      className="position-absolute top-0 start-0 bottom-0 end-0"
      style={{ pointerEvents: isDragging ? "all" : "none" }}
      onClick={handlePlaceSignature}
    >
      {isDragging && signatureData && (
        <div
          ref={floatingSignatureRef}
          className="position-fixed"
          style={{
            left: cursorPosition.x,
            top: cursorPosition.y,
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
            // zIndex: 1050,
          }}
        >
          <img
            src={signatureData.dataUrl}
            alt="Signature"
            style={{ maxWidth: "150px", maxHeight: "60px" }}
          />
        </div>
      )}

      <div style={{ pointerEvents: "all" }}>
        {placements.map((placement) => (
          <ResizableImage
            key={placement.id}
            placement={placement}
            removePlacement={removePlacement}
            handlePlaceSignature={handlePlaceSignature}
            onUpdatePlacement={updatePlacement}
            pdfElement={pdfElement} // Add this prop
          />
        ))}
      </div>
    </div>
  );
};

// Main PdfPreview Component
const PdfPreview: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { uploadResponse, loading } = useSelector(
    (state: RootState) => state?.uploadResponse
  );

  const state = location.state as LocationState;

  // Use useMemo to memoize the PDF URL
  const pdfUrl = useMemo(() => state?.pdfUrl, [state?.pdfUrl]);
  const itmeId = useMemo(() => state?.itemId, [state?.itemId]);

  // Error handling for PDF loading
  const [pdfError, setPdfError] = useState<string | null>(null);

  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [numPages, setNumPages] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [itemGuid, setItemGuid] = useState("");
  const [placements, setPlacements] = useState<SignaturePlacement[]>([]);
  const [signatureData, setSignatureData] = useState<SignatureData | null>(
    null
  );

  const setGuid = (id: string) => {
    setItemGuid(id);
  };
  const handleOpenModal = () => {
    const modalElement = document.getElementById("sign_modal");
    if (modalElement) {
      const modal = new Modal(modalElement);
      modal.show();
      setIsModalOpen(true);

      modalElement.addEventListener(
        "hidden.bs.modal",
        () => {
          setIsModalOpen(false);
        },
        { once: true }
      );
    }
  };

  useEffect(() => {
    handleOpenModal();
  }, [location]);

  const handleSignatureCreated = (data: SignatureData) => {
    setSignatureData(data);
    const modalElement = document.getElementById("sign_modal");
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
  };

  // Memoize the file prop to prevent unnecessary reloads
  const documentFile = useMemo(() => ({ url: pdfUrl }), [pdfUrl]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = rect.height - (e.clientY - rect.top);
    console.log({ x, y, page: currentPage });

    // onSelectCoordinates({ x, y, page: currentPage }); // Pass page info
  };

  return (
    <>
      <h4 className="mb-4">Document Room - Sign Document</h4>{" "}
      <div className="card p-4 overflow-hidden overflow-scroll">
        <div className="d-flex justify-content-end my-5 gap-2">
          <button
            className="btn btn-primary btn-sm"
            disabled={placements.length === 0}
            onClick={() => {
              const configModel = placements.map((placement) => ({
                documentGuid: uploadResponse?.documentGuid
                  ? uploadResponse?.documentGuid
                  : itemGuid,
                pageNumber: placement.pageNumber,
                x: placement.x.toString(),
                y: placement.y.toString(),
                h: placement.h.toString(),
                w: placement.w.toString(),
              }));

              dispatch(
                signDocument({
                  itemId: itmeId,
                  configModel: configModel,
                } as any) as any
              ).then(() => navigate("/documentRoom/folder"));
            }}
          >
            Save
          </button>

          <button className="btn btn-primary btn-sm" onClick={handleOpenModal}>
            Sign
          </button>
        </div>
        <div className="pdf-signature-container">
          {pdfError && (
            <div className="alert alert-danger" role="alert">
              {pdfError}
            </div>
          )}

          <div
            ref={pdfContainerRef}
            className="pdf-preview-container"
            style={{
              maxWidth: "800px",
              display: "flex",
              margin: "0 auto",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Document
              file={documentFile}
              loading={<div>PDF is loading, please wait...</div>}
              onLoadSuccess={({ numPages }) => {
                console.log(numPages);

                setNumPages(numPages);
              }}
              onLoadError={(error) => {
                console.error("PDF load error:", error);
                setPdfError("Failed to load PDF");
              }}
            >
              {[...Array(numPages)].map((_, index) => (
                <div
                  key={`page-container_${index + 1}`}
                  style={{
                    position: "relative",
                    //   width: "100%",
                    //   maxWidth: "800px",
                    marginBottom: "20px",
                  }}
                  className="shadow-sm"
                >
                  <Page
                    key={`page_${index + 1}`}
                    canvasRef={canvasRef}
                    pageNumber={index + 1}
                    width={800}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    onRenderSuccess={() => {
                      // Update current page when a page is rendered
                      setCurrentPage(index + 1);
                    }}
                    onClick={handleClick}
                  />
                </div>
              ))}
            </Document>
            {!isModalOpen && signatureData && (
              <SignaturePlacer
                pdfElement={canvasRef.current}
                signatureData={signatureData}
                onPlacementComplete={() => {}}
                placements={placements}
                setPlacements={setPlacements}
                currentPage={currentPage} // Pass the current page
              />
            )}
          </div>
          <SignatureModal
            onSignatureCreated={handleSignatureCreated}
            setGuid={setGuid}
          />
        </div>
      </div>
    </>
  );
};

export default PdfPreview;
