import { maxWidth } from '@mui/system';
import React, { useState } from 'react'
import Select from 'react-select';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TaxCompliance = () => {
    const [selectedOption, setSelectedOption] = useState("")
    const [selectedRadio, setSelectedRadio] = useState("goto")
    const filingData = [
        {
            "eventDate": "7th January",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For December",
            "status": "Completed", // Assigned as Pending
            "filingStatus": "Completed", // Assigned as Pending
            "action": null
        },
        {
            "eventDate": "25th January",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for December",
            "status": "Pending",
            "textStatus": "Due in 20 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 20 days",
            "action": null
        },
        {
            "eventDate": "31st January",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for December",
            "status": "Pending",
            "textStatus": "Overdue by 2 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Overdue by 2 days",
            "action": null
        },
        {
            "eventDate": "7th February",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For January",
            "status": "Pending",
            "textStatus": "Due today",
            "filingStatus": "Pending",
            "filTxtstatus": "Due today",
            "action": null
        },
        {
            "eventDate": "25th February",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for January",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "28th February",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for January",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "28th September",
            "taxType": "Provisional tax",
            "applicableForm": "IRP6",
            "obligations": "2nd Provisional Tax Payment",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th March",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For February",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th March",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for February",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "31st March",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for February",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th April",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For March",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th April",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for March",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "30th April",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for March",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th May",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For April",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th May",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for April",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "31st May",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for April",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "31st May",
            "taxType": "Reconciliation tax",
            "applicableForm": "EMP501",
            "obligations": "Final Reconciliation",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th June",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For May",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th June",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for May",
            "status": "Pending", // Assigned as Pending
            "filingStatus": "Completed", // Assigned as Pending
            "action": null
        },
        {
            "eventDate": "30th June",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for May",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",

            "action": null
        },
        {
            "eventDate": "7th July",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For June",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th July",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for June",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "31st July",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for June",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th August",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For July",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th August",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for July",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "31st August",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Electronic filing for July",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th September",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "For August",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th September",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "Manual filing for August",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "30th September",
            "taxType": "Provisional tax",
            "applicableForm": "IRP6",
            "obligations": "3rd Provisional Tax Payment",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th September",
            "taxType": "Provisional tax",
            "applicableForm": "IRP6",
            "obligations": "1st Provisional Tax Payment",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "31st May",
            "taxType": "Reconciliation tax",
            "applicableForm": "EMP501",
            "obligations": "Final Reconciliation",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th October",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "for September",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th October",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "for September",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "31st October",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "for September",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th November",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "for October",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th November",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "for October",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "30th November",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "for October",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "30th November",
            "taxType": "Income tax",
            "applicableForm": "ITR14",
            "obligations": "for October",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "7th December",
            "taxType": "PAYE/UIF/SDL",
            "applicableForm": "EMP201",
            "obligations": "for November",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        },
        {
            "eventDate": "25th December",
            "taxType": "VAT",
            "applicableForm": "VAT201",
            "obligations": "for November",
            "status": "Pending",
            "textStatus": "Due in 27 days",
            "filingStatus": "Pending",
            "filTxtstatus": "Due in 27 days",
            "action": null
        }


    ];
    // Group the filing data by month
    function groupByMonth(data: any) {
        return data.reduce((acc: any, item: any) => {
            const month = item.eventDate.split(' ')[1];  // Extracts the month name

            if (!acc[month]) {
                acc[month] = [];
            }

            acc[month].push(item);

            return acc;
        }, {});
    }

    const groupedByMonth = groupByMonth(filingData);
    return (
        <>
            <h4>Tax Compliance Calendar</h4>
            <div className='card shadow  mt-5'>


                <div className="card-header border-0 mt-5">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input
                                type="text"
                                data-kt-user-table-filter="search"
                                className="form-control form-control-solid w-250px ps-13"
                                placeholder="Search "
                            //   value={searchValue}
                            //   onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className="card-toolbar">
                        <div
                            className="d-flex justify-content-end"
                            data-kt-customer-table-toolbar="base"
                        >
                            {/* {filter && (
                      <div className="d-flex align-items-center me-5 mt-3 justify-content-center">
                        <a
                          className="text-danger text-hover-danger fw-bold"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            filterFormik.resetForm();
                            setSelectedTypes([]);
                          }}
                        >
                          Clear Filters
                        </a>
                      </div>
                    )} */}
                            <div>
                                <button
                                    type="button"
                                    className="btn me-3"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    id="kt-toolbar-filter"
                                //   onClick={handleTransType}
                                >
                                    <i className="ki-duotone ki-filter fs-2 text-light">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    Filter
                                </button>
                                <div
                                    className="menu menu-sub menu-sub-dropdown w-380px w-md-380px"
                                    data-kt-menu="true"
                                    id="kt-toolbar-filter"
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}

                                >
                                    <div className="px-7 py-5">
                                        <div className="fs-4 text-dark fw-bold">
                                            Filter Options
                                        </div>
                                    </div>
                                    <form>
                                        <div className="separator border-gray-200"></div>
                                        <div className="px-7 py-5">
                                            {/* By Status */}
                                            <div
                                                className="mb-5"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <label className="form-label fs-5 fw-semibold mb-3">By Date(From - To)</label>
                                                <div className='d-flex flex-row gap-5'>
                                                    <input type="date" className='form-control' name="" id="" />
                                                    <input type="date" className='form-control' name="" id="" />
                                                </div>
                                            </div>

                                            <div className="fv-row mb-7 ">
                                                {/* begin::Label */}
                                                <label className="required fw-semibold fs-6 mb-2">
                                                    Tax Type
                                                </label>
                                                <Select styles={{ container: (base) => ({ ...base, maxWidth: '350px' }) }} isMulti options={[{ value: "1", label: "Corporate Income Tax" }, { value: "2", label: "VAT" }, { value: "3", label: "PAYE" }, { value: "4", label: "UIF" }, { value: "5", label: "SDL" }, { value: "6", label: "Turnover Tax" }, { value: "7", label: "Provisional Tax" }]} onChange={(selectedOptions) => console.log(selectedOptions)} placeholder="Select Type" />

                                            </div>



                                            <div className="mb-10">
                                                <label className="form-label fs-5 fw-semibold mb-3">By Form Number</label>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeRemittance"
                                                            name="type"
                                                            value="Remittance Partner"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeRemittance">
                                                            EMP201
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeIDAuth"
                                                            name="type"
                                                            value="ID Authentication"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeIDAuth">
                                                            VAT201
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeSMS"
                                                            name="type"
                                                            value="SMS"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeSMS">
                                                            EMP501
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeSMS"
                                                            name="type"
                                                            value="SMS"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeSMS">
                                                            ITR14
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeSMS"
                                                            name="type"
                                                            value="SMS"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeSMS">
                                                            IRP6
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="mb-10">
                                                <label className="form-label fs-5 fw-semibold mb-3">By Status</label>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeRemittance"
                                                            name="type"
                                                            value="Remittance Partner"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeRemittance">
                                                            Completed
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeIDAuth"
                                                            name="type"
                                                            value="ID Authentication"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeIDAuth">
                                                            Pending
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="typeSMS"
                                                            name="type"
                                                            value="SMS"
                                                        />
                                                        <label className="form-check-label" htmlFor="typeSMS">
                                                            Not Applicable
                                                        </label>
                                                    </div>

                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            type="reset"
                                                            className="btn btn-light btn-active-light-primary me-2"
                                                            data-kt-menu-dismiss="true"
                                                            data-kt-customer-table-filter="reset"
                                                        //   onClick={filterFormik.handleReset}
                                                        >
                                                            Reset
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            data-kt-customer-table-filter="filter"
                                                            data-kt-menu-dismiss="true"
                                                        //   disabled={filterFormik.isSubmitting}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn  me-3"

                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_add_event"
                                >

                                    Add Event
                                </button>


                            </div>






                        </div>

                        <div
                            className="d-flex justify-content-end align-items-center d-none"
                            data-kt-customer-table-toolbar="selected"
                        >
                            <div className="fw-bold me-5">
                                <span
                                    className="me-2"
                                    data-kt-customer-table-select="selected_count"
                                ></span>
                                Selected
                            </div>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-kt-customer-table-select="delete_selected"
                            >
                                Delete Selected
                            </button>
                        </div>
                        {/* <!--end::Group actions--> */}
                    </div>
                    {/* <!--end::Card toolbar--> */}
                </div>
                <div className="card-body p-5 ms-5 me-5">
                    <table className="table align-middle table-row-dashed fs-6 gy-5">
                        <thead>
                            <tr className="fw-bolder fs-6 text-gray-800">
                                <th>Event Date</th>
                                <th>Tax Type</th>
                                <th>Applicable Form</th>
                                <th>Obligations</th>
                                <th>Payment Status</th>
                                <th>Filing Status</th>
                            </tr>
                        </thead>
                        <tbody >
                            {Object.keys(groupedByMonth).map((month) => (
                                <>
                                    <tr key={month}>
                                        <td colSpan={6} className="fw-bold fs-6 text-gray-800">
                                            <strong>{month}</strong>
                                        </td>
                                    </tr>
                                    {groupedByMonth[month].map((item: any, index: any) => (
                                        <tr key={index}  >
                                            <td>{item.eventDate}</td>
                                            <td>{item.taxType}</td>
                                            <td>{item.applicableForm}</td>
                                            <td>{item.obligations}</td>
                                            <td>
                                                <div className='d-flex flex-row '>
                                                    <div className={`badge ${item?.status === "Pending" ? "text-primary" : item?.status === "Not Applicable" ? "badge-light-warning" : "badge-light-success"}`}>
                                                        {item?.status === "Pending" ? item?.textStatus : item.status}
                                                    </div>
                                                    {item?.status === "Completed" ? <i className="bi bi-download ms-3 text-dark"></i> : <div><OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-add-money">
                                                                Pay now
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span style={{ cursor: "pointer", marginLeft: "5px" }} data-bs-toggle="modal"
                                                            data-bs-target="#kt_modal_payment" >
                                                            <img style={{ height: "20px", width: "20px" }} src={toAbsoluteUrl("/media/tax-icons/Pay Now.svg")} alt="" />
                                                        </span>
                                                    </OverlayTrigger></div>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex flex-row '>
                                                    <div className={`badge ${item?.filingStatus === "Pending" ? "text-primary" : item?.filingStatus === "Not Applicable" ? "badge-light-warning" : "badge-light-success"}`}>
                                                        {item?.filingStatus === "Pending" ? item?.filTxtstatus : item.filingStatus}
                                                    </div>
                                                    {item?.filingStatus === "Completed" ? <i className="bi bi-download ms-3 text-dark"></i> : <div><OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-add-money">
                                                                File now
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span style={{ cursor: "pointer", marginLeft: "5px" }} data-bs-toggle="modal"
                                                            data-bs-target="#kt_modal_complete_event">
                                                            <img style={{ height: "20px", width: "20px" }} src={toAbsoluteUrl("/media/tax-icons/File Now (1).svg")} alt="" />
                                                        </span>
                                                    </OverlayTrigger></div>}
                                                </div>
                                            </td>
                                            {/* <td><div className='d-flex flex-row gap-5'>

                                                <div><OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-add-money">
                                                            Complete now
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                                                        <img style={{ height: "20px", width: "20px" }} src={toAbsoluteUrl("/media/tax-icons/File Now.svg")} alt="" />
                                                    </span>
                                                </OverlayTrigger></div>
                                                <div><OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-add-money">
                                                            Mark as completed
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span style={{ cursor: "pointer", marginLeft: "5px" }}   data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_complete_event">
                                                        <img style={{ height: "20px", width: "20px" }} src={toAbsoluteUrl("/media/tax-icons/Mark Completed.svg")} alt="" />
                                                    </span>
                                                </OverlayTrigger></div>
                                            </div></td> */}
                                        </tr>
                                    ))}
                                </>
                            ))}
                        </tbody>
                    </table>

                </div>
                <div
                    className="modal fade"
                    id="kt_modal_add_event"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div
                            className="modal-content"
                            style={{ maxHeight: "700px", overflow: "auto" }}
                        >
                            <div
                                className="modal-header d-flex justify-content-between"
                                id="kt_modal_add_user_header"
                            >
                                <h2 className="fw-bold">Add Event</h2>

                            </div>

                            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                <div className="fv-row mb-7 ">
                                    {/* begin::Label */}
                                    <label className="required fw-semibold fs-6 mb-2">
                                        Event Date
                                    </label>
                                    <input type="date" className='form-control' />
                                </div>

                                <div className="fv-row mb-7 ">
                                    {/* begin::Label */}
                                    <label className="required fw-semibold fs-6 mb-2">
                                        Tax Type
                                    </label>
                                    <select className="form-select" data-control="select2" data-placeholder="Select an option" value={selectedOption} onChange={(e) => { setSelectedOption(e.target.value) }}>
                                        <option>Select Type</option>
                                        <option value="PAYE">PAYE</option>
                                        <option value="UIF">UIF</option>
                                        <option value="SDL">SDL</option>
                                        <option value="VAT201M">VAT201(Manual)</option>
                                        <option value="VAT201E">VAT201(Electronic)</option>
                                        <option value="Provisional Tax">Provisional Tax</option>
                                        <option value="Reconciliation Tax">Reconciliation Tax</option>
                                        <option value="Income Tax">Income Tax</option>

                                    </select>
                                </div>


                                {selectedOption && (
                                    <div className="fv-row mb-7">
                                        {/* begin::Label */}
                                        <label className="required fw-semibold fs-6 mb-2">
                                            {{ PAYE: "EMP201", UIF: "EMP201", SDL: "EMP201", VAT201M: "VAT201", VAT201E: "VAT201", "Reconciliation Tax": "EMP501", "Income Tax": "ITR14", "Provisional Tax": "IRP6" }[selectedOption] || ""}
                                        </label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        <input
                                            type="text"
                                            name="user_name"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder={{ PAYE: "EMP201", UIF: "EMP201", SDL: "EMP201", VAT201M: "VAT201", VAT201E: "VAT201", "Reconciliation Tax": "EMP501", "Income Tax": "ITR14", "Provisional Tax": "IRP6" }[selectedOption] || ""}
                                        />
                                    </div>
                                )}
                                <div className="fv-row mb-7 ">
                                    {/* begin::Label */}
                                    <label className="required fw-semibold fs-6 mb-2">
                                        Obligations
                                    </label>
                                    <input type="text" placeholder='Obligations' className='form-control' />
                                </div>
                                <div className="fv-row mb-7 ">
                                    {/* begin::Label */}
                                    <label className="required fw-semibold fs-6 mb-2">
                                        Status
                                    </label>
                                    <select className="form-select" data-control="select2" data-placeholder="Select an option" >
                                        <option>Select</option>
                                        <option value="PAYE">Completed</option>
                                        <option value="UIF">Pending</option>
                                        <option value="Not Applicable">Not Applicable</option>


                                    </select>
                                </div>


                                <div className="text-center mt-10">
                                    <button
                                        type="reset"
                                        // onClick={() => cancel()}
                                        className="btn btn-light me-3"
                                        data-kt-users-modal-action="cancel"
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type="submit"
                                        className="btn"
                                        data-kt-users-modal-action="submit"
                                    >
                                        <span className="indicator-label">Submit</span>
                                        {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="kt_modal_complete_event"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div
                            className="modal-content"
                            style={{ maxHeight: "700px", overflow: "auto" }}
                        >
                            <div
                                className="modal-header d-flex justify-content-between"
                                id="kt_modal_add_user_header"
                            >
                                <h2 className="fw-bold">Complete Event</h2>

                            </div>

                            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                <div className="fv-row mb-7 d-flex flex-row gap-5">
                                    <div className="">
                                        <div className="form-check form-check-custom form-check-solid  form-check-sm">
                                            <input
                                                className="form-check-input"
                                                name="payment"
                                                type="radio"
                                                value="goto"
                                                id="flexRadioGoto"
                                                checked={selectedRadio === 'goto'}  // Ensuring the correct radio is checked
                                                onChange={(e) => { setSelectedRadio(e.target.value) }}
                                            />
                                            <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioPay">
                                                Goto Link
                                            </label>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="form-check form-check-custom form-check-solid  form-check-sm">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="payment"
                                                value="mark"
                                                id="flexRadioMark"
                                                checked={selectedRadio === 'mark'}  // Ensuring the correct radio is checked
                                                onChange={(e) => { setSelectedRadio(e.target.value) }}
                                            />
                                            <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioMark">
                                                Mark as completed
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-10 mb-20'>
                                    {selectedRadio === "mark" && (
                                        <div>
                                            <div className="fv-row mb-7 ">
                                                {/* begin::Label */}
                                                <label className="required fw-semibold fs-6 mb-2">
                                                    Completion Date
                                                </label>
                                                <input type="date" className='form-control' />
                                            </div>

                                            <div className="fv-row mb-7 ">
                                                {/* begin::Label */}
                                                <label className="required fw-semibold fs-6 mb-2">
                                                    Upload Files
                                                </label>
                                                <input type="file" className='form-control' />
                                            </div>

                                            <div className="fv-row mb-7 ">
                                                {/* begin::Label */}
                                                <label className="required fw-semibold fs-6 mb-2">
                                                    Remarks
                                                </label>
                                                <textarea className="form-control" data-kt-autosize="true" style={{ minHeight: "100px" }}></textarea>
                                            </div>
                                        </div>
                                    )}

                                    {selectedRadio === "goto" && (

                                        <div className="fv-row mb-10 mt-10 ">
                                            <div
                                                className="card cursor-pointer shadow-sm p-20 d-flex flex-column align-items-center justify-content-center"
                                                style={{
                                                    transition: 'all 0.3s ease',
                                                    border: "1px solid grey"
                                                }}
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.style.transform = 'translateY(-5px)';
                                                    e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.2)';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.currentTarget.style.transform = 'translateY(0)';
                                                    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                                                }}
                                            >
                                                <div>
                                                    <img
                                                        style={{ height: "35px", width: "35px" }}
                                                        src={toAbsoluteUrl("/media/tax-icons/File Now.svg")}
                                                        alt="icon"
                                                    />
                                                </div>
                                                <span className="fw-bold fs-4 mt-3">Launch external link</span>
                                            </div>

                                        </div>


                                    )}
                                </div>









                                <div className="text-center mt-10">
                                    <button
                                        type="reset"
                                        // onClick={() => cancel()}
                                        className="btn btn-light me-3"
                                        data-kt-users-modal-action="cancel"
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type="submit"
                                        className="btn"
                                        data-kt-users-modal-action="submit"
                                    >
                                        <span className="indicator-label">Submit</span>
                                        {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="kt_modal_payment"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div
                            className="modal-content"
                            style={{ maxHeight: "700px", overflow: "auto" }}
                        >
                            <div
                                className="modal-header d-flex justify-content-between"
                                id="kt_modal_add_user_header"
                            >
                                <h2 className="fw-bold">Pay Now</h2>

                            </div>

                            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">




                                <>
                                    <div className="fv-row mb-7 ">
                                        <div className="fv-row mb-7 ">
                                            {/* begin::Label */}
                                            <label className="required fw-semibold fs-6 mb-2">
                                                Amount
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input

                                                // {...formik.getFieldProps("user_name")}
                                                type="text"
                                                name="user_name"
                                                className="form-control"
                                                placeholder='Amount'
                                                autoComplete="off"
                                                value={1000}
                                            />

                                        </div>
                                        <div className="fv-row mb-7 ">
                                            {/* begin::Label */}
                                            <label className="required fw-semibold fs-6 mb-2">
                                                Select Accont
                                            </label>
                                            {/* end::Label */}

                                            <select className="form-select" data-control="select2" data-placeholder="Select an option">
                                                <option>Select</option>
                                                <option value="Cash">1345363567537 (10000 ZAR)</option>
                                                <option value="Cheque">98247587765 (5005 ZAR)</option>



                                            </select>

                                        </div>
                                    </div>
                                    <label className="fw-semibold fs-6 mb-5" htmlFor="flexRadioPay">
                                        Would you like to pay now or later?
                                    </label>
                                    <div className="fv-row mb-7 d-flex flex-row gap-5">
                                        <div className="">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    name="payOption"
                                                    type="radio"
                                                    value="now"
                                                    id="flexRadioNow"
                                                // checked={selectedPayOption === 'now'}
                                                // onChange={(e) => setSelectedPayOption(e.target.value)} // Ensure state updates
                                                />
                                                <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioNow">
                                                    Now
                                                </label>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    name="payOption"
                                                    type="radio"
                                                    value="later"
                                                    id="flexRadioLater"
                                                // checked={selectedPayOption === 'later'}
                                                // onChange={(e) => setSelectedPayOption(e.target.value)} // Ensure state updates
                                                />
                                                <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioLater">
                                                    Later
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </>






                                <div className="text-center mt-10">
                                    <button
                                        type="reset"
                                        // onClick={() => cancel()}
                                        className="btn btn-light me-3"
                                        data-kt-users-modal-action="cancel"
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type="submit"
                                        className="btn"
                                        data-kt-users-modal-action="submit"
                                    >
                                        <span className="indicator-label">{selectedOption === "pay" ? "Pay" : "Mark as paid"}</span>
                                        {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default TaxCompliance