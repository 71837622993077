import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import Select, { OptionProps, MultiValueProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import { Email, findAdditionalInfo, shareItem } from "./shareHistorySlice";
import { Modal } from "bootstrap";
import { RootState } from "../../store";

interface SignaturePlacerProps {
  itemGuid: string;
}

interface SelectOption {
  label: string;
  value: string;
}

const ShareLinkModal: React.FC<SignaturePlacerProps> = ({ itemGuid }) => {
  const { emailList, count, loading } = useSelector(
    (state: RootState) => state?.emailList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(findAdditionalInfo({} as any) as any);
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: [] as SelectOption[],
    },
    validationSchema: Yup.object().shape({
      email: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string()
              .email("Invalid email format")
              .required("Email is required"),
          })
        )
        .min(1, "At least one email is required")
        .required("Email list is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        console.log(
          values?.email?.map((option: any) => {
            // Check if option is an object with label and value, otherwise it's a string
            return typeof option === "string" ? option : option.value;
          })
        );
        const emails = values?.email?.map((option: any) => {
          return typeof option === "string" ? option : option.value;
        });
        setSubmitting(true);
        dispatch(
          shareItem({ emails: emails, itemGuid: [itemGuid] } as any) as any
        );
        formik.resetForm();
        const modal = Modal.getInstance("#date_range");
        if (modal) {
          modal.hide();
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "100%",
      padding: 3,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      padding: 5,
      background: "#f1f1f4",
    }),
  };

  return (
    <div className="modal fade" tabIndex={-1} id="date_range">
      <div
        className="modal modal-dialog modal-dialog-centered "
        // style={{ margin: "0 auto", marginTop: "10%" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            {" "}
            <h4 className="mb-0">Share link</h4>
          </div>
          <div className="modal-body p-10">
            <div>
              <div className="mt-5">
                <label className="fs-5 fw-bold mb-2">Email</label>
                <CreatableSelect
                  isMulti
                  value={formik.values.email}
                  onChange={(selectedOptions) => {
                    // Ensure selectedOptions is always an array of strings (email addresses)
                    console.log(selectedOptions);

                    const selectedValues = selectedOptions.map(
                      (option: any) => {
                        // Check if option is an object with label and value, otherwise it's a string
                        return typeof option === "string"
                          ? option
                          : option.value;
                      }
                    );

                    console.log(selectedValues); // Log the selected email addresses as an array of strings

                    // Update Formik's email array with the selected email addresses
                    formik.setFieldValue("email", selectedOptions);
                  }}
                  onCreateOption={(inputValue) => {
                    if (
                      Yup.string()
                        .email("Invalid email format")
                        .isValidSync(inputValue)
                    ) {
                      const newOption = {
                        label: inputValue,
                        value: inputValue,
                      };
                      console.log(newOption);

                      // Add the new email to the Formik value array
                      formik.setFieldValue("email", [
                        ...formik.values.email,
                        newOption,
                      ]);
                      // Add it to options as well for selection
                      // setOptions((prevOptions) => [...prevOptions, newOption]);
                    } else {
                      console.log("errror");

                      formik.setFieldError("email", "Email format was wrong");
                      // Optionally, show a message if the email format is invalid
                      // alert("Invalid email format");
                    }
                  }}
                  options={emailList?.map((item: Email) => ({
                    label: item?.itemValue,
                    value: item?.itemValue,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={customStyles}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-danger mt-1">
                    {Array.isArray(formik.errors.email)
                      ? formik.errors.email.map((error, index) =>
                          typeof error === "string" ? (
                            <div key={index}>{error}</div>
                          ) : (
                            <div key={index}>Invalid input</div> // Customize this as needed
                          )
                        )
                      : typeof formik.errors.email === "string"
                        ? formik.errors.email
                        : "Invalid input"}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn mt-10"
                type="button"
                onClick={() => formik.handleSubmit()}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLinkModal;
