import { useState, useEffect } from "react";
import { messaging } from "./FirebaseConf";
import { getToken } from "firebase/messaging";
import { enhancedFetch } from "../app/modules/auth/core/_requests";
import { onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Api } from "../app/pages/ServiceTicket/ReplyPage";
import { fetchNotifications } from "./NotificationSlice";
import { useDispatch } from "react-redux";

const FirebaseNotification = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const dispatch = useDispatch();

  const [fmcToken, setFmcToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  async function requestPermission() {
    try {
      // Register the service worker
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      // console.log("Service Worker registered with scope:", registration.scope);

      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BMM3FwxB-kk2-X0Ac79tDNCyXnJcmLdnZgUrzsSjifTOwOpF1Czn4F2paBuZMI_2-fhu7pO254h5bgiCpCU_QKI",
          serviceWorkerRegistration: registration, // Pass the registered service worker
        });
        // console.log("Token Gen", token);
        setFmcToken(token);
      } else {
        console.log("Notification permission denied");
      }
    } catch (error) {
      console.error("Error during requestPermission", error);
    }
  }

  const registerDevice = async () => {
    try {
      const url = `${API_URL}/registerDevice`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        fcmToken: fmcToken,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      // console.log(data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateNotification = async ({
    notificationGuid,
    status,
    interactionRef,
  }: any) => {
    try {
      const url = `${API_URL}/notification/updateNotificationLog`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        notificationGuid: notificationGuid,
        notificationStatus: status,
        ...(interactionRef !== null &&
          interactionRef !== "" && { interactionReference: interactionRef }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      console.log(data);

      setTimeout(() => {
        dispatch(fetchNotifications({} as any) as any);
      }, 300);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  const CustomToast = ({
    title,
    body,
    buttons,
    mediaUrl,
    notificationGuid,
    ticketId,
    notificationCode,
  }: {
    title: string;
    body: string;
    buttons: any[];
    mediaUrl?: string;
    notificationGuid: string;
    ticketId: string;
    notificationCode: string;
  }) => (
    <div
      className="p-0 m-0 cursor-pointer"
      onClick={() => {
        if (notificationCode === "SERVICE_REQUEST_UPDATE") {
          navigate("/helpdesk/service-ticket/reply", {
            state: { data: ticketId },
          });
        } else {
          navigate("/communication-center", {
            state: { notificationGuid: notificationGuid },
          });
        }
      }}
    >
      <p className="fw-bold m-0 p-0">{title}</p>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      {mediaUrl && (
        <img src={mediaUrl} alt="Notification Media" className="img-fluid" />
      )}
      <div className="d-flex flex-row justify-content-start pt-2 gap-5">
        {buttons?.map(
          ({
            buttonId,
            name,
            action,
            type,
          }: {
            buttonId: string;
            name: string;
            code: string;
            action: string;
            type: number;
          }) => (
            <button
              key={buttonId}
              className={`btn ${
                type === 1 ? "btn-primary" : "btn-secondary"
              } pt-1 pb-1`}
              onClick={() =>
                handleButtonClick(action, notificationGuid, buttonId)
              }
            >
              {name}
            </button>
          )
        )}
      </div>
    </div>
  );

  const handleButtonClick = (
    action: string,
    notificationGuid: string,
    buttonId: string
  ) => {
    if (action === "HOME_PAGE") {
      window.location.href = "/dashboard";
    } else {
      window.open(action, "_blank");
    }

    updateNotification({
      notificationGuid,
      status: 3,
      interactionRef: buttonId,
    });
  };

  onMessage(messaging, (payload) => {
    if (payload.data) {
      const notificationData = JSON.parse(payload.data.notificationDetails);
      console.log(notificationData);

      const {
        title,
        body,
        buttons,
        mediaUrl,
        notificationGuid,
        ticketId,
        notificationCode,
      } = notificationData;
      if (
        location.pathname === "/helpdesk/service-ticket/reply" &&
        notificationCode === "SERVICE_REQUEST_UPDATE"
      ) {
        Api.api(ticketId);
      }

      toast(
        <CustomToast
          title={title}
          body={body}
          buttons={buttons}
          mediaUrl={mediaUrl}
          notificationGuid={notificationGuid}
          ticketId={ticketId}
          notificationCode={notificationCode}
        />
      );
      setTimeout(() => {
        updateNotification({
          notificationGuid,
          status: 2,
          interactionRef: null,
        });
      }, 1000);
    }
  });

  useEffect(() => {
    if (fmcToken) {
      registerDevice();
    }
  }, [fmcToken]);

  return (
    <>
      <ToastContainer autoClose={5000} limit={1} />
    </>
  );
};

export default FirebaseNotification;
