import axios from "axios";
const API_URL = process.env.REACT_APP_WEB_API_URL;
export const VERIFY_OTP = `${API_URL}/verifyOtp`;
export const SET_PASSWORD = `${API_URL}/capturePassword`;
export const BUSINESS = `${API_URL}/kycCreateSmeBusiness`;
export const DOCUMENT_UPLOAD = `${API_URL}/kycUploadBusinessDocuments`;
export const APPLICANT = `${API_URL}/kycUpdateDirectorInfo`;
export const PRIMARY_APPLICANT = `${API_URL}/kycCreatePrimaryUser`;
export const SELFIE_DOC = `${API_URL}/kycUploadSelfiDocument`;
export const SIGNATURE_DOC = `${API_URL}/kycUploadSignatureDocument`;
export const KYC_VERIFY = `${API_URL}/kycVerifyAndSubmitApplication`;
export const SECONDARY_APPLICANT = `${API_URL}/kycManangeSecondaryDirectors`;
export const GET_ALL = `${API_URL}/getAllKycData`;
export const ZAR_ACC = `${API_URL}/kycCreateSmeBankAccount`;
export const USD_ACC = `${API_URL}/openUsdAccount`;
export const CREATE_PIN = `${API_URL}/changePin`;
export const PLANS = `${API_URL}/dashboard/getSubscriptionProducts`;
export const MANAGE_PLANS = `${API_URL}/manageSubscriptionPlan`;
export const ACTIVATE = `${API_URL}/activateUser`;
export const REAPPLY = `${API_URL}/kycReapply`;
export const RESET = `${API_URL}/resetSmileIdLivelinessData`;
export const ERROR_MESSAGE = `${API_URL}/errorMessage/getErrorMessageByCode`;



export function verifyOtp(
  mobile: string,
  isdCode: number,
  otp: string,
  channel: string,
  otpType: string,
  appId: string
) {
  let data = JSON.stringify({
    mobile,
    isdCode,
    otp,
    channel,
    otpType,
  });

  return axios.post(VERIFY_OTP, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      app_id: appId,
    },
  });
}

export function password(
  mobile: string,
  isdCode: number,
  password: string,
  appId: string
) {
  let data = JSON.stringify({
    mobile,
    isdCode,
    password,
  });

  return axios.post(SET_PASSWORD, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      app_id: appId,
    },
  });
}

export function business(
  tncAccepted: boolean,
  mobile: string,
  isdCode: number,
  brandName: string,
  businessName: string,
  yearOfIncorporation: string,
  vatNumber: string,
  city: string,
  deviceId: string,
  platform: string,
  geoLocation: string,
  ipAddress: string,
  token: string
) {
  let data = JSON.stringify({
    tncAccepted,
    mobile,
    isdCode,
    brandName,
    businessName,
    yearOfIncorporation,
    vatNumber,
    city,
    deviceId,
    platform,
    geoLocation,
    ipAddress,
  });

  return axios.post(BUSINESS, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function docUpload(
  license: File | undefined,
  moa: File | undefined,
  tax: File | undefined,
  token: string
) {
  const formData = new FormData();

  const getRenamedFile = (file: any, label: any) => {
    const fileExtension = file.name.split(".").pop();
    const newFileName = `${label}.${fileExtension}`;
    return new File([file], newFileName, { type: file.type });
  };

  if (license) formData.append("files", getRenamedFile(license, "LICENSE_16"));
  if (moa) formData.append("files", getRenamedFile(moa, "MOA_18"));
  if (tax) formData.append("files", getRenamedFile(tax, "TAX_19"));

  console.log(formData);

  return axios.post(DOCUMENT_UPLOAD, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function applicant(primaryApplicantType: string, noOfDirectors: string, token: string) {
  let data = JSON.stringify({
    primaryApplicantType,
    noOfDirectors,
  });

  return axios.post(APPLICANT, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function primaryApplicant(
  file: File | undefined,
  firstName: string,
  lastName: string,
  dob: string,
  email: string,
  nationality: string,
  idType: string,
  idNumber: string,
  idExpiryDate:string,
  token: string,
  reapply:boolean
) {



  const formData = new FormData();
  if(!reapply){
    formData.append("file", file ?? '');
  }
  else if(reapply && file!==undefined){
    formData.append("file", file ?? '');
  }
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("dob", dob);
  formData.append("email", email);
  formData.append("nationality", nationality);
  formData.append("idType", idType);
  formData.append("idNumber", idNumber);
  formData.append("idExpiryDate", idExpiryDate);
  console.log(formData);

  return axios.post(PRIMARY_APPLICANT, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function uploadSelfieDoc(
  file: File | null,
  token: string
) {

  const formData = new FormData();
  formData.append("file", file ?? '');


  return axios.post(SELFIE_DOC, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function uploadSignature(
  file: File | null,
  token: string
) {

  const formData = new FormData();
  formData.append("file", file ?? '');


  return axios.post(SIGNATURE_DOC, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}



export function secondaryApplicant(
  file: File | undefined,
  firstName: string,
  lastName: string,
  dob: string,
  email: string,
  nationality: string,
  idType: string,
  idNumber: string,
  idExpiryDate:string,
  token: string,
  secondaryIsdCode: string,
  secondaryMobileNumber: string,
  orderId: number,
  reapply:boolean,
) {



  const formData = new FormData();
  if(!reapply){
    formData.append("file", file ?? '');
  }
  else if(reapply && file!==undefined){
    formData.append("file", file ?? '');
  }
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("dob", dob);
  formData.append("email", email);
  formData.append("nationality", nationality);
  formData.append("idType", idType);
  formData.append("idNumber", idNumber);
  formData.append("idExpiryDate", idExpiryDate);
  formData.append("secondaryIsdCode", secondaryIsdCode);
  formData.append("secondaryMobileNumber", secondaryMobileNumber);
  formData.append("orderId", orderId.toString());
  console.log(formData);

  return axios.post(SECONDARY_APPLICANT, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAllData(
  token: string,
) {



  let data = JSON.stringify({});

  return axios.post(GET_ALL, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getZAR(
  token: string,
) {

  let data = JSON.stringify({});

  return axios.post(ZAR_ACC, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });

}

export function getUSD(
  token: string,
  skipUsdAccountCreation:boolean
) {

  let data = JSON.stringify({
    ...(skipUsdAccountCreation && { skipUsdAccountCreation }),
  });

  return axios.post(USD_ACC, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}


export function createPin(
  pin: string,
  token: string

) {
  let data = JSON.stringify({
    pin
  });

  return axios.post(CREATE_PIN, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}


export function getPlans(
  token: string,
) {

  let data = JSON.stringify({});

  return axios.post(PLANS, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}


export function managePlans(
  product: string,
  subscriptionAmount: Number,
  token: string,
) {

  let data = JSON.stringify({
    product,
    subscriptionAmount,
  });

  return axios.post(MANAGE_PLANS, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function activate(
  token: string,
) {

  let data = JSON.stringify({});

  return axios.post(ACTIVATE, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function reset(
  token: string,
) {

  let data = JSON.stringify({});

  return axios.post(RESET, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}
export function getErrorMessage(
  token: string,
  errorCode: string,
  tpId: string,
) {

  let data = JSON.stringify({errorCode,tpId});

  return axios.post(ERROR_MESSAGE, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}


export function reapply(
  token: string,
) {

  let data = JSON.stringify({});

  return axios.post(REAPPLY, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
      Authorization: `Bearer ${token}`,
    },
  });
}
