import { useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import copy from "../../../_metronic/assets/all-serviceIcons/copy-alt.svg";
import { toast } from "react-toastify";
import Select, { OptionProps, MultiValueProps } from "react-select";

interface SignaturePlacerProps {}

const TaxShareLinkModal: React.FC<SignaturePlacerProps> = ({}) => {
  const [loading, setLoading] = useState(true);
  const textContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });
  // Update your save handlers
  const handleSave = (signatureDataURL: string) => {};

  const items = [
    "akhil@yopmail.com",
    "ruthra@yopmail.com",
    "sathya@yopmail.com",
    "ajinkya@yopmail.com",
  ];

  const CustomOption: React.FC<OptionProps<any>> = ({
    innerProps,
    label,
    data,
  }) => (
    <div {...innerProps} className="p-3  bg-hover-light">
      <span className="ms-3">{label}</span>
    </div>
  );
  const CustomSingleValue: React.FC<MultiValueProps<any>> = ({
    innerProps,
    data,
    removeProps,
  }) => (
    <div
      {...innerProps}
      className="card pt-2 pb-2 ps-3 pe-3 bg-light-secondary d-flex flex-row ms-3 "
      style={{
        transition: "width 1s ease",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1)")}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(0.9)")}
    >
      {data.label}
      <span
        className="text-hover-danger fw-bold"
        {...removeProps}
        style={{ marginLeft: "8px", cursor: "pointer", color: "blue" }}
      >
        &#x2715;
      </span>
    </div>
  );

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "100%",
      padding: 3,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      padding: 5, // Adjust the padding as needed
      background: "#f1f1f4",
    }),
    selected: (provided: any) => ({
      ...provided,
      transition: "width 2s ease",
    }),
  };

  return (
    <div className="modal fade" tabIndex={-1} id="date_range">
      <div
        className="modal modal-dialog modal-dialog-centered "
        // style={{ margin: "0 auto", marginTop: "10%" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            {" "}
            <h4 className="mb-0">Share link</h4>
          </div>
          <div className="modal-body p-10">
            {/* <div className="mb-5">
              <label className="fs-5 fw-bold mb-2">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter name"
              />
            </div> */}
            <div>
              <div className="mt-5">
                <label className="fs-5 fw-bold mb-2">Email</label>
                <Select
                  isMulti
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option: any) => option.value
                    );
                    // formik.setFieldValue("audience", selectedValues);
                  }}
                  options={items.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  components={{
                    Option: CustomOption,
                    MultiValue: CustomSingleValue,
                  }}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button className="btn mt-10" type="button">
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxShareLinkModal;
