import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  DrawerComponent,
  MenuComponent,
} from "../../../_metronic/assets/ts/components";
import { useFormik } from "formik";
import { Modal } from "bootstrap";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  changeExpiry,
  listSharedHistory,
  manageAccess,
  resend,
  ShareAccessData,
  ShareItem,
} from "./shareHistorySlice";
import { RootState } from "../../store";
import * as Yup from "yup";
import { getCurrentDateTime } from "../bulkPayment/bulkPaymentStepper/steps/Step2";

const FilterSchema = Yup.object().shape({
  itemName: Yup.string()
    .max(50, "Item name must be 50 characters or less")
    .optional(),
  itemType: Yup.array().min(1, "Please select at least one item type"),
  // lastModified: Yup.date()
  //   .nullable()
  //   .max(new Date(), "Date cannot be in the future"),
});

const SharingHistory: FC = () => {
  const dispatch = useDispatch();
  const [add, setAdd] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [shareGuid, setShareGuid] = useState("");
  const [expiry, setExpiry] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const { shareHistory, count, loading } = useSelector(
    (state: RootState) => state?.shareHistory
  );

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  useEffect(() => {
    dispatch(listSharedHistory(fetchShareListPayload as any) as any);
  }, [dispatch, currentPage]);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(listSharedHistory(fetchShareListPayload as any) as any);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  const generateSearchCriteria = (
    propertyName: string,
    condition: boolean,
    propertyValue: any
  ) => {
    return condition
      ? [
          {
            searchType: propertyName,
            search: propertyValue,
          },
        ]
      : [];
  };

  const filterFormik = useFormik({
    initialValues: {
      itemName: "",
      sharedWith: "",
      itemType: "",
      status: "",
      lastModified: "",
    },
    // validationSchema: FilterSchema,
    onSubmit: (values) => {
      // Perform filtering logic here

      console.log("Filter Values:", values);
      dispatch(listSharedHistory(fetchShareListPayload as any) as any);
      // Close dropdown after submission
      // if (dropdownRef.current) {
      //   const dropdownButton = dropdownRef.current.querySelector(
      //     '[data-bs-toggle="dropdown"]'
      //   ) as HTMLButtonElement; // Cast to HTMLButtonElement
      //   if (dropdownButton) {
      //     dropdownButton.click();
      //   }
      // }
    },
  });

  const fetchShareListPayload = {
    ...(searchValue && {
      search: searchValue,
    }),
    pageNumber: currentPage,
    resultPerPage: itemsPerPage,
    skipPagination: false,
    searchFilter: [
      ...generateSearchCriteria(
        "LAST_MODIFIED",
        !!filterFormik?.values?.lastModified,
        filterFormik?.values?.lastModified
      ),
      ...generateSearchCriteria(
        "ITEM_NAME",
        !!filterFormik?.values?.itemName,
        filterFormik?.values?.itemName
      ),
      ...generateSearchCriteria(
        "STATUS",
        !!filterFormik?.values?.status,
        filterFormik?.values?.status
      ),
      ...generateSearchCriteria(
        "SHARED_WITH",
        !!filterFormik?.values?.sharedWith,
        filterFormik?.values?.sharedWith
      ),
    ],
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(count / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const handleOpenModal = () => {
    const modalElement = document.getElementById("change_exp_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const handleOpenAccessModal = () => {
    const modalElement = document.getElementById("access_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  // const items = [
  //   "akhil@yopmail.com",
  //   "ruthra@yopmail.com",
  //   "sathya@yopmail.com",
  //   "ajinkya@yopmail.com",
  // ];

  const [checked, setChecked] = useState<string[]>([]);
  const [itemList, setItemList] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [viewData, setViewData] = useState<ShareItem>();

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    folderGuid: string
  ) => {
    const target = e.target as HTMLInputElement;

    setChecked((prev) => {
      if (target.checked) {
        // Add the folderGuid to the state
        return prev.filter((guid) => guid !== folderGuid);
      } else {
        // Remove the folderGuid from the state
        return [...prev, folderGuid];
      }
    });
  };

  const handleAccessCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    folderGuid: string
  ) => {
    const target = e.target as HTMLInputElement;

    setItemList((prev) => {
      if (target.checked) {
        // Add the folderGuid to the state
        return [...prev, folderGuid];
      } else {
        // Remove the folderGuid from the state
        return prev.filter((guid) => guid !== folderGuid);
      }
    });
  };

  const [items, setItems] = useState<ShareAccessData[]>([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  // Handle adding email
  const handleAddEmail = () => {
    // Validate email
    if (!emailInput) {
      setEmailError("Email is required");
      return;
    }

    if (!validateEmail(emailInput)) {
      setEmailError("Invalid email format");
      return;
    }

    // Check if email already exists
    if (emails.includes(emailInput)) {
      setEmailError("Email already added");
      return;
    }

    // Add email and reset
    setEmails((prev) => [...prev, emailInput]);
    setEmailInput("");
    setAdd(false);
    setEmailError("");
  };

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  const keyLabels: Record<keyof ShareItem, string> = {
    itemType: "Item type",
    validity: "Expiry date",
    createdDateString: "Shared date",
    updatedDateString: "Updated date",
    itemName: "Item name",
    shareGuid: "shareGuid",
    shareAccessData: "shareAccessData",
    status: "Status",
  };

  interface TableComponentProps {
    data: ShareAccessData[];
  }

  const TableComponent: React.FC<TableComponentProps> = ({ data }) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Email</th>
            <th>Accessed Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr key={`entry-${index}`}>
              <td>{entry.email || "-"}</td>
              <td>{entry.accessDate || "-"}</td>
              <td>
                <p
                  className={`mb-0 text-${
                    entry.accessStatus === "RESOURCE_ACCESSED"
                      ? "success"
                      : entry.accessStatus === "EXPIRED"
                        ? "danger"
                        : "primary"
                  }`}
                >
                  {entry.accessStatus === "RESOURCE_ACCESSED"
                    ? "Accessed"
                    : entry.accessStatus === "EXPIRED"
                      ? "Expired"
                      : "Yet to Access"}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Shared History</h4>{" "}
      <div className="card p-2">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex align-items-center  position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control p-3 border-secondary w-250px ps-15"
              placeholder="Search Files & Folders"
              onChange={(event) => {
                handleSearchChange(event);
              }}
            />
          </div>
          <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
            {/* <Authorize hasAnyPermission={["MENU_DOMESTIC_VENDOR_PAY|ADD"]}> */}
            <div className="dropdown">
              <button
                className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#ebe9eb",
                }}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-funnel text-dark fs-2 p-0 m-0"></i>
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end p-10 w-350px"
                aria-labelledby="dropdownMenuButton"
              >
                <form
                  onSubmit={filterFormik.handleSubmit}
                  onReset={filterFormik.handleReset}
                >
                  <li>
                    <p className="fs-4">Filter options</p>
                    <hr />
                  </li>
                  <li className="mb-3">
                    <label className="fs-5 mb-2">Item name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter folder name"
                      name="itemName"
                      value={filterFormik.values.itemName}
                      onChange={filterFormik.handleChange}
                    />
                  </li>
                  <li className="mb-3">
                    <label className="fs-5 mb-2">Shared with</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      name="sharedWith"
                      value={filterFormik.values.sharedWith}
                      onChange={filterFormik.handleChange}
                    />
                  </li>
                  {/* <li className="mb-3">
                    <label className="fs-5 mb-2">Item type</label>
                    <select
                      name="itemType"
                      className="form-select"
                      value={filterFormik.values.itemType}
                      onChange={filterFormik.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="1">System Generated</option>
                      <option value="2">User Generated</option>
                      <option value="3">Workspace</option>
                    </select>
                  </li> */}
                  <li className="mb-3">
                    <label className="fs-5 mb-2">Status</label>
                    <select
                      name="status"
                      className="form-select"
                      value={filterFormik.values.status}
                      onChange={filterFormik.handleChange}
                    >
                      <option value="">Select</option>
                      <option value="1">Yet to access</option>
                      <option value="2">Accessed</option>
                      <option value="3">Link Expired</option>
                    </select>
                  </li>
                  <li className="mb-5">
                    <label className="fs-5 mb-2">Last modified</label>
                    <input
                      type="date"
                      className="form-control"
                      name="lastModified"
                      max={new Date().toISOString().split("T")[0]}
                      value={filterFormik.values.lastModified}
                      onChange={filterFormik.handleChange}
                    />
                  </li>
                  <li>
                    <div className="d-flex justify-content-end">
                      <button className="btn btn-md" type="submit">
                        Apply
                      </button>
                      {/* <button className="btn btn-md ms-2" type="reset">
                        Reset
                      </button> */}
                    </div>
                  </li>
                </form>
              </ul>
            </div>
            {/* </Authorize> */}
          </div>
        </div>

        <table
          className="table align-middle border rounded table-row-dashed fs-6 g-5"
          style={{
            maxHeight: "440px",
            overflowY: "scroll",
          }}
        >
          <thead className="bg-gray-200">
            <tr className="text-start text-dark-600 fw-bold fs-6 ">
              {/* <th className="px-3">
                  {" "}
                  <label className="form-check form-check-custom form-check-sm fs-7">
                    <input
                      className="form-check-input cursor-pointer border border-dark"
                      type="checkbox"
                      // checked={selectAll}
                      // onClick={handleSelectAll}
                    />{" "}
                  </label>
                </th> */}

              <th className="min-w-200px p-5">Item Name</th>
              <th className="min-w-200px p-5">Item Type</th>
              <th className="min-w-100px p-5">Shared with</th>
              <th className="min-w-100px p-5">Validity</th>
              {/* <th className="min-w-100px p-5">Status</th> */}
              <th className="min-w-100px p-5">Action</th>
            </tr>
          </thead>

          <tbody>
            {shareHistory?.length > 0 ? (
              shareHistory?.map((item: ShareItem, index: number) => (
                <tr
                  key={index}
                  onMouseOver={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "#f1f1f2";
                  }}
                  onMouseOut={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "white";
                  }}
                >
                  {/* <td className="px-3">
                      <label className="form-check form-check-custom form-check-sm fs-7">
                        <input
                          className="form-check-input cursor-pointer border border-dark"
                          type="checkbox"
                          // checked={invoiceGuid.includes(item.invoiceGuid)}
                          onClick={(e) => handleCheckClick(e)}
                        />{" "}
                      </label>
                    </td> */}

                  <td className="p-5 text-gray-600 cursor-pointer fw-hover-bold fs-6">
                    <p className="text-gray-600 text-hover-primary fw-hover-bold fs-6 mb-0">
                      {item.itemName}
                    </p>
                  </td>
                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                    <p className="mb-0"> {item.itemType}</p>
                  </td>
                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                    <p className="mb-0">
                      {item?.shareAccessData &&
                      item?.shareAccessData.length > 1 ? (
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={
                            <Popover
                              className="px-7 py-3 bg-light-secondary"
                              id="popover-trigger-focus"
                            >
                              {item.shareAccessData.map((accessData, idx) => (
                                <div key={idx}>{accessData.email.trim()}</div>
                              ))}
                            </Popover>
                          }
                        >
                          <div>
                            {item.shareAccessData
                              .map((accessData) => accessData.email)
                              .join(", ")
                              .slice(0, 20)}
                            ...
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <p className="text-gray-600 text-hover-primary fw-hover-bold fs-6 mb-0">
                          {item?.shareAccessData?.[0]?.email ||
                            "No email available"}
                        </p>
                      )}
                    </p>
                  </td>

                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                    <p className="mb-0"> {item.validity}</p>
                  </td>

                  <td className="p-5 text-gray-600 p-2">
                    <div className="d-flex gap-5 align-items-center">
                      {/* <button
                          className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "25%",
                            backgroundColor: "#ebe9eb",
                          }}
                          type="button"
                          onClick={() => handleOpenModal()}
                        >
                          
                        </button> */}

                      <div className="dropdown" style={{ width: "20px" }}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-add-money">Actions</Tooltip>
                          }
                        >
                          <button
                            className="btn m-0 bg-hover-light p-0 d-flex align-items-center justify-content-center"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "25%",
                              backgroundColor: "#ebe9eb",
                            }}
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-three-dots text-dark fs-6 p-0 m-0"></i>
                          </button>
                        </OverlayTrigger>

                        <ul
                          className="dropdown-menu dropdown-menu-end p-1"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {/* <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              // onClick={() => setToggle(true)}
                            >
                              View
                            </button>
                          </li> */}
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() =>
                                dispatch(
                                  resend({
                                    shareGuid: item?.shareGuid,
                                  } as any) as any
                                )
                              }
                            >
                              Resend
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                setShareGuid(item?.shareGuid);
                                handleOpenModal();
                              }}
                            >
                              Change Expiry
                            </button>
                          </li>

                          {item?.status !== 2 && (
                            <li>
                              <button
                                className="dropdown-item text-danger"
                                type="button"
                                onClick={() => {
                                  setShareGuid(item?.shareGuid);
                                  setItems(item?.shareAccessData);
                                  handleOpenAccessModal();
                                }}
                              >
                                Manage access
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                      {/* <div> */}
                      <i
                        className="bi bi-eye text-dark fs-3 p-0 m-0"
                        onClick={() => setViewData(item)}
                        id="kt_share_modal_toggle"
                      ></i>
                      {/* </div> */}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(count / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(count / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(Math.ceil(count / itemsPerPage))
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {count}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              //   onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div
        id="kt_share_modal"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_share_modal_toggle"
        data-kt-drawer-close="#kt_modal_share_close"
      >
        <div className="card shadow-none rounded-0 w-100 p-5">
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_share_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-dark p-0">Details</h5>
          </div>
          <div className="card-body p-0 mx-5 d-flex flex-column justify-content-between">
            <div>
              <div className="mt-5">
                {viewData && (
                  <>
                    {/* Render all keys except shareAccessData */}
                    {Object.entries(viewData)
                      .filter(([key]) =>
                        [
                          "itemType",
                          "validity",
                          "itemName",
                          "createdDateString",
                        ].includes(key)
                      )
                      .map(([key, value]) => (
                        <React.Fragment key={key}>
                          <div className="d-flex card mb-5 justify-content-between">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <p className="m-0">
                                  {keyLabels[key as keyof ShareItem]}
                                </p>
                              </div>
                              <div className="d-flex">
                                <p className="m-0 fw-bold">{String(value)}</p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}

                    {/* Render the TableComponent for shareAccessData */}
                    {"shareAccessData" in viewData && (
                      <TableComponent
                        key="table"
                        data={viewData.shareAccessData} // Pass the entire shareAccessData array to the TableComponent
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="change_exp_modal">
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              <h4 className="mb-0">Change Expiry Period</h4>
            </div>
            <div className="modal-body p-10">
              <div className="d-flex flex-column">
                <label htmlFor="language" className="fs-6  mb-3">
                  Expiry Period
                </label>
                <select
                  id="language"
                  name="language"
                  onChange={(e) => {
                    setExpiry(e.target.value);
                    const value = e.target.value;
                    const expiry = value?.split(" ")?.[0];
                    const type = value?.split(" ")?.[1];
                    console.log(expiry, type);
                  }}
                  className="form-select mb-5"
                >
                  <option value="">Select a Period</option>
                  <option value="1 HOURS">4hrs</option>
                  <option value="4 HOURS">8hrs</option>
                  <option value="8 HOURS">8hrs</option>
                  <option value="24 HOURS">24hrs</option>
                  <option value="3 DAYS">3 days</option>
                  <option value="7 DAYS">7 days</option>
                  <option value="15 DAYS">15 days</option>
                  <option value="30 DAYS">30 days</option>
                  <option value="60 DAYS">60 days</option>
                  <option value="90 DAYS">90 days</option>
                </select>
                <button
                  type="button"
                  className={`w-100 rounded btn`}
                  style={{
                    // width: "150px",
                    color: "#ffff",
                  }}
                  onClick={() => {
                    dispatch(
                      changeExpiry({
                        shareGuid: shareGuid,
                        expiryPeriod: expiry?.split(" ")?.[0],
                        expiryType: expiry?.split(" ")?.[1],
                      } as any) as any
                    ).then(() => {
                      setExpiry("");
                      setShareGuid("");
                      const modal = Modal.getInstance("#change_exp_modal");
                      if (modal) {
                        modal.hide();
                      }
                    });
                    // setCurrentPage(1);
                  }}
                  disabled={!expiry}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="access_modal">
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              <h4 className="mb-0">Update Link access</h4>
            </div>
            <div className="modal-body p-10">
              <div className="d-flex flex-column">
                <div className="card card-body max-h-300px overflow-scroll pt-0">
                  <div className="d-flex justify-content-between mb-3">
                    <p className="m-0 fw-bold fs-5">Shared with</p>
                    {/* <label className="form-check form-check-custom form-check-sm">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        // checked
                        // onClick={(e) => handleCheckClick(e)}
                      />{" "}
                    </label> */}
                  </div>
                  <hr
                    className="mt-0"
                    style={{
                      borderStyle: "dashed",
                    }}
                  />
                  {items?.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between mb-3 align-items-center"
                    >
                      <p className="m-0">{item?.email}</p>
                      <div className="form-check form-check-custom form-check-sm">
                        <input
                          className="form-check-input cursor-pointer border border-dark"
                          type="checkbox"
                          checked={
                            (item?.status === "Active" &&
                              !checked.includes(item?.linkGuid)) ||
                            (item?.status === "Deleted" &&
                              itemList.includes(item?.linkGuid))
                          }
                          onClick={(e) => {
                            if (item?.status === "Active")
                              handleCheckClick(e, item.linkGuid);
                            else handleAccessCheckClick(e, item.linkGuid);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  {emails?.map((email, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between mb-3 align-items-center"
                    >
                      <p className="m-0">{email}</p>
                      {/* <div className="form-check form-check-custom form-check-sm">
                        <input
                          className="form-check-input cursor-pointer border border-dark"
                          type="checkbox"
                          // checked={checked.includes(item.folderGuid)}
                          // onClick={(e) => handleCheckClick(e, item.folderGuid)}
                        />
                      </div> */}
                    </div>
                  ))}
                  {add && (
                    <div className="d-flex flex-column gap-3 mb-3">
                      <div className="d-flex gap-3 align-items-center">
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          placeholder="Enter email"
                          value={emailInput}
                          onChange={(e) => {
                            setEmailInput(e.target.value);
                            setEmailError("");
                          }}
                        />
                        <i
                          className="bi bi-check-circle fs-1 cursor-pointer"
                          onClick={handleAddEmail}
                        ></i>
                      </div>
                      {emailError && (
                        <p className="text-danger m-0 small">{emailError}</p>
                      )}
                    </div>
                  )}
                  <div>
                    <p
                      className="text-decoration-underline text-primary cursor-pointer"
                      onClick={() => setAdd(!add)}
                    >
                      Add View user
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  className={`w-100 rounded btn`}
                  style={{
                    // width: "150px",
                    color: "#ffff",
                  }}
                  onClick={() => {
                    dispatch(
                      manageAccess({
                        shareGuid: shareGuid,
                        itemGuid: itemList,
                        deleteItemGuid: checked,
                        emails: emails,
                      } as any) as any
                    ).then(() => {
                      const modal = Modal.getInstance("#access_modal");
                      if (modal) {
                        modal.hide();
                      }
                    });
                    // setCurrentPage(1);
                  }}
                  //   disabled={!toDate}
                >
                  Update access
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SharingHistory };
