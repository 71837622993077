import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";

import notificationReducer from "../firebase/NotificationSlice";
import roleReducer from "./pages/settings/RuleSettingsSlice";
import vendorReducer from "./pages/IncomingInvoice/vendorSlice";
import ruleDashboardSlice from "./pages/settings/RuleDashboardSlice";
import documentRoomSlice from "./pages/documentRoom/documentRoomSlice";
import settingsSlice from "./pages/documentRoom/settingSlice";
import shareHistorySlice from "./pages/documentRoom/shareHistorySlice";
import collectionsSlice from "./pages/documentRoom/collectionSlice";

const rootReducer = combineReducers({
  notificationLogs: notificationReducer,
  role: roleReducer,
  vendors: vendorReducer,
  folders: documentRoomSlice,
  shareHistory: shareHistorySlice,
  docAppList: shareHistorySlice,
  emailList: shareHistorySlice,
  collections: collectionsSlice,
  init: collectionsSlice,
  settings: settingsSlice,
  uploadResponse: settingsSlice,
  banks: vendorReducer,
  ruleDashboard: ruleDashboardSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
