import React, { useState } from 'react'

const PaymentCenter = () => {
    // State to track the selected radio button
    const [selectedOption, setSelectedOption] = useState<string>('pay');
    const [selectedPayOption, setSelectedPayOption] = useState<string>('now');
    const [selOption, setSelOption] = useState<string>('');

    // Handle the change event
    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.target.value);
    };
    const handleRadioPaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.target.value);
    };
    return (
        <>
            <h4>Payment Center</h4>
            <div className='card p-5'>
                <div className='d-flex flex-row justify-content-between gap-5'>
                    <div className='card p-5 shadow w-100 bg-secondary text-dark' style={{ height: "150px" }}>
                        <div className='fs-2x fw-bold mt-10 '>ZAR 1,000</div>
                        <div className=''>Due this Month</div>
                    </div>
                    <div className='card p-5 shadow w-100 bg-dark text-light' style={{ height: "150px" }}>
                        <div className='fs-2x fw-bold mt-10'>ZAR 500</div>
                        <div className=''>Paid this month</div>
                    </div>
                    <div className='card p-5 shadow w-100 bg-warning text-light' style={{ height: "150px" }}>
                        <div className='fs-2x fw-bold mt-10'>ZAR 500</div>
                        <div className=''>Remaining this month</div>
                    </div>
                    <div className='card p-5 shadow w-100  bg-info text-light' style={{ height: "150px" }}>
                        <div className='fs-2x fw-bold mt-10'>ZAR 12,000</div>
                        <div className=''>Total paid this financial year</div>
                    </div>
                </div>
                <div className='card shadow  mt-10'>
                    <div className="card-header border-0 mt-5">
                        <div className="card-title">
                            <div className="d-flex align-items-center position-relative my-1">
                                <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                <input
                                    type="text"
                                    data-kt-user-table-filter="search"
                                    className="form-control form-control-solid w-250px ps-13"
                                    placeholder="Search "
                                //   value={searchValue}
                                //   onChange={handleSearchChange}
                                />
                            </div>
                        </div>

                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-customer-table-toolbar="base"
                            >
                                {/* {filter && (
                      <div className="d-flex align-items-center me-5 mt-3 justify-content-center">
                        <a
                          className="text-danger text-hover-danger fw-bold"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            filterFormik.resetForm();
                            setSelectedTypes([]);
                          }}
                        >
                          Clear Filters
                        </a>
                      </div>
                    )} */}
                                <div>
                                    <button
                                        type="button"
                                        className="btn me-3"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        id="kt-toolbar-filter"
                                    //   onClick={handleTransType}
                                    >
                                        <i className="ki-duotone ki-filter fs-2 text-light">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                        Filter
                                    </button>
                                    <div
                                        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                                        data-kt-menu="true"
                                        id="kt-toolbar-filter"
                                    >
                                        <div className="px-7 py-5">
                                            <div className="fs-4 text-dark fw-bold">
                                                Filter Options
                                            </div>
                                        </div>
                                        <form>
                                            <div className="separator border-gray-200"></div>
                                            <div className="px-7 py-5">
                                                {/* By Status */}
                                                <div
                                                    className="mb-5"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <label className="form-label fs-5 fw-semibold mb-3">By Tax Type</label>
                                                    <div className="d-flex flex-column">
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusActive"
                                                                name="status"
                                                                value="Active"
                                                            />
                                                            <label className="form-check-label" htmlFor="statusActive">
                                                                Corporate Income Tax
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusInactive"
                                                                name="status"
                                                                value="Inactive"
                                                            />
                                                            <label className="form-check-label" htmlFor="statusInactive">
                                                                VAT
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusPending"
                                                                name="status"
                                                                value="Pending Approval"
                                                            />
                                                            <label className="form-check-label" htmlFor="statusPending">
                                                                PAYE
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusPending"
                                                                name="status"
                                                                value="Pending Approval"
                                                            />
                                                            <label className="form-check-label" htmlFor="statusPending">
                                                                UIF
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusPending"
                                                                name="status"
                                                                value="Pending Approval"
                                                            />
                                                            <label className="form-check-label" htmlFor="statusPending">
                                                                SDL
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusPending"
                                                                name="status"
                                                                value="Pending Approval"
                                                            />
                                                            <label className="form-check-label" htmlFor="statusPending">
                                                                Turnover Tax
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusPending"
                                                                name="status"
                                                                value="Pending Approval"
                                                            />
                                                            <label className="form-check-label" htmlFor="statusPending">
                                                                Provisional Tax
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* By Type */}
                                                <div className="mb-10">
                                                    <label className="form-label fs-5 fw-semibold mb-3">By Status</label>
                                                    <div className="d-flex flex-column">
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="typeRemittance"
                                                                name="type"
                                                                value="Remittance Partner"
                                                            />
                                                            <label className="form-check-label" htmlFor="typeRemittance">
                                                                Paid
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="typeIDAuth"
                                                                name="type"
                                                                value="ID Authentication"
                                                            />
                                                            <label className="form-check-label" htmlFor="typeIDAuth">
                                                                Overdue
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="typeSMS"
                                                                name="type"
                                                                value="SMS"
                                                            />
                                                            <label className="form-check-label" htmlFor="typeSMS">
                                                                Due
                                                            </label>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button
                                                                type="reset"
                                                                className="btn btn-light btn-active-light-primary me-2"
                                                                data-kt-menu-dismiss="true"
                                                                data-kt-customer-table-filter="reset"
                                                            //   onClick={filterFormik.handleReset}
                                                            >
                                                                Reset
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                data-kt-customer-table-filter="filter"
                                                                data-kt-menu-dismiss="true"
                                                            //   disabled={filterFormik.isSubmitting}
                                                            >
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>

                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn  me-3"

                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        id="kt-toolbar-partner"
                                    >

                                        Add Payment
                                    </button>
                                    <div
                                        className="menu menu-sub menu-sub-dropdown w-220px w-md-220px"
                                        data-kt-menu="true"
                                        id="kt-toolbar-partner"
                                    >
                                        {/* <div className="px-7 py-5">
                                                    <div className="fs-4 text-dark fw-bold">
                                                        New Partner
                                                    </div>
                                                </div> */}
                                        <form>
                                            <div className="separator border-gray-200"></div>
                                            <div className="px-7 py-5">

                                                <div className=' d-flex flex-column gap-2'>
                                                    <div className='cursor-pointer text-primary rounded fw-semibold  bg-hover-secondary p-3' data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_add_payment">
                                                        Add Manually
                                                    </div>
                                                    <div className='cursor-pointer text-gray-600 rounded fw-semibold bg-hover-secondary p-3' data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_signup_partner">
                                                        Calculate & Add
                                                    </div>
                                                    <div className='cursor-pointer text-gray-600 rounded fw-semibold bg-hover-secondary p-3' data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_signup_partner">
                                                        Upload CSV/Excel
                                                    </div>
                                                </div>

                                            </div>
                                        </form>

                                    </div>

                                </div>






                            </div>

                            <div
                                className="d-flex justify-content-end align-items-center d-none"
                                data-kt-customer-table-toolbar="selected"
                            >
                                <div className="fw-bold me-5">
                                    <span
                                        className="me-2"
                                        data-kt-customer-table-select="selected_count"
                                    ></span>
                                    Selected
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-kt-customer-table-select="delete_selected"
                                >
                                    Delete Selected
                                </button>
                            </div>
                            {/* <!--end::Group actions--> */}
                        </div>
                        {/* <!--end::Card toolbar--> */}
                    </div>
                    <div className="card-body p-5 ms-5 me-5">
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                            <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th>Tax Type</th>
                                    <th>Amount</th>
                                    <th>Due Date</th>
                                    <th>Payment Date</th>
                                    <th>Status</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Corporate Income Tax</td>
                                    <td>ZAR 1,000</td>
                                    <td>1/01/2025</td>
                                    <td>-</td>
                                    <td className='text-primary'>Due in 30 days</td>
                                    <td><div className='badge badge-primary ps-10 pe-10 pt-2 pb-2' data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_payment">Pay</div></td>

                                </tr>
                                <tr>
                                    <td>VAT</td>
                                    <td>ZAR 1,500</td>
                                    <td>1/11/2024</td>
                                    <td>-</td>
                                    <td className='text-danger'>Overdue by 21 days</td>
                                    <td><div className='badge badge-primary ps-10 pe-10 pt-2 pb-2'>Pay</div></td>

                                </tr>
                                <tr>
                                    <td>UIF</td>
                                    <td>ZAR 2,500</td>
                                    <td>10/12/2024</td>
                                    <td>-</td>
                                    <td className='text-warning'>Due Today</td>
                                    <td><div className='badge badge-primary ps-10 pe-10 pt-2 pb-2'>Pay</div></td>

                                </tr>
                                <tr>
                                    <td>Provisional Tax</td>
                                    <td>ZAR 2,500</td>
                                    <td>8/12/2024</td>
                                    <td>9/12/2024</td>
                                    <td className='text-Sucess'><div className='badge badge-light-success'>Paid</div></td>
                                    <td><div className=' pt-2 pb-2'>Receipt <i className="bi bi-download ms-2 text-dark"></i></div></td>

                                </tr>
                                <tr>
                                    <td>Turnover Tax</td>
                                    <td>ZAR 2,500</td>
                                    <td>8/08/2024</td>
                                    <td>9/12/2024</td>
                                    <td className='text-Sucess'><div className='badge badge-light-success'>Paid</div></td>
                                    <td><div className=' pt-2 pb-2'>Receipt <i className="bi bi-download ms-2 text-dark"></i></div></td>

                                </tr>
                                <tr>
                                    <td>VAT</td>
                                    <td>ZAR 2,500</td>
                                    <td>8/12/2024</td>
                                    <td>9/09/2024</td>
                                    <td className='text-Sucess'><div className='badge badge-light-success'>Paid</div></td>
                                    <td><div className=' pt-2 pb-2'>Receipt <i className="bi bi-download ms-2 text-dark"></i></div></td>

                                </tr>
                                <tr>
                                    <td>UIF</td>
                                    <td>ZAR 2,500</td>
                                    <td>8/07/2024</td>
                                    <td>9/12/2024</td>
                                    <td className='text-Sucess'><div className='badge badge-light-success'>Paid</div></td>
                                    <td><div className=' pt-2 pb-2'>Receipt <i className="bi bi-download ms-2 text-dark"></i></div></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div
                className="modal fade"
                id="kt_modal_add_payment"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div
                        className="modal-content"
                        style={{ maxHeight: "700px", overflow: "auto" }}
                    >
                        <div
                            className="modal-header d-flex justify-content-between"
                            id="kt_modal_add_user_header"
                        >
                            <h2 className="fw-bold">Add Payment</h2>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">

                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Tax Type
                                </label>
                                <select className="form-select" data-control="select2" data-placeholder="Select an option">
                                    <option>Select Type</option>
                                    <option value="1">Corporate Income Tax</option>
                                    <option value="2">VAT</option>
                                    <option value="3">PAYE</option>
                                    <option value="4">UIF</option>
                                    <option value="5">SDL</option>
                                    <option value="6">Turnover Tax</option>
                                    <option value="7">Provisional Tax</option>

                                </select>
                            </div>


                            {/* begin::Input group */}
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Amount
                                </label>
                                {/* end::Label */}

                                <div className="position-relative w-30">
                                    <input
                                        type="text"
                                        className="form-control pr-5"
                                        placeholder="Amount"

                                    />
                                    {(
                                        <span className="position-absolute top-50 end-0 translate-middle-y pe-3 fw-bold">ZAR </span>
                                    )}
                                </div>

                            </div>
                            <div className="fv-row mb-7 ">
                                {/* begin::Label */}
                                <label className="required fw-semibold fs-6 mb-2">
                                    Due Date
                                </label>
                                {/* end::Label */}

                                <div className="">
                                    <input
                                        type="date"
                                        className="form-control pr-5"
                                        placeholder="Amount"

                                    />

                                </div>

                            </div>
                            <div className="text-center mt-10">
                                <button
                                    type="reset"
                                    // onClick={() => cancel()}
                                    className="btn btn-light me-3"
                                    data-kt-users-modal-action="cancel"
                                >
                                    Discard
                                </button>

                                <button
                                    type="submit"
                                    className="btn"
                                    data-kt-users-modal-action="submit"
                                >
                                    <span className="indicator-label">Submit</span>
                                    {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="kt_modal_payment"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div
                        className="modal-content"
                        style={{ maxHeight: "700px", overflow: "auto" }}
                    >
                        <div
                            className="modal-header d-flex justify-content-between"
                            id="kt_modal_add_user_header"
                        >
                            <h2 className="fw-bold">Payment</h2>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">

                            <div className="fv-row mb-7 d-flex flex-row gap-5">
                                <div className="">
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input
                                            className="form-check-input"
                                            name="payment"
                                            type="radio"
                                            value="pay"
                                            id="flexRadioPay"
                                            checked={selectedOption === 'pay'}  // Ensuring the correct radio is checked
                                            onChange={handleRadioChange}
                                        />
                                        <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioPay">
                                            Pay
                                        </label>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="mark"
                                            id="flexRadioMark"
                                            checked={selectedOption === 'mark'}  // Ensuring the correct radio is checked
                                            onChange={handleRadioChange}
                                        />
                                        <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioMark">
                                            Mark as paid
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {selectedOption === "pay" && (
                                <>
                                    <div className="fv-row mb-7 ">
                                        <div className="fv-row mb-7 ">
                                            {/* begin::Label */}
                                            <label className="required fw-semibold fs-6 mb-2">
                                                Amount
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input

                                                // {...formik.getFieldProps("user_name")}
                                                type="text"
                                                name="user_name"
                                                className="form-control"
                                                placeholder='Amount'
                                                autoComplete="off"
                                                value={1000}
                                            />

                                        </div>
                                        <div className="fv-row mb-7 ">
                                            {/* begin::Label */}
                                            <label className="required fw-semibold fs-6 mb-2">
                                                Select Accont
                                            </label>
                                            {/* end::Label */}

                                            <select className="form-select" data-control="select2" data-placeholder="Select an option" value={selOption} onChange={(e) => { setSelOption(e.target.value) }}>
                                                <option>Select</option>
                                                <option value="Cash">1345363567537 (10000 ZAR)</option>
                                                <option value="Cheque">98247587765 (5005 ZAR)</option>



                                            </select>

                                        </div>
                                    </div>
                                    <label className="fw-semibold fs-6 mb-5" htmlFor="flexRadioPay">
                                        Would you like to pay now or later?
                                    </label>
                                    <div className="fv-row mb-7 d-flex flex-row gap-5">
                                        <div className="">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    name="payOption" 
                                                    type="radio"
                                                    value="now"
                                                    id="flexRadioNow"
                                                    checked={selectedPayOption === 'now'}
                                                    onChange={(e) => setSelectedPayOption(e.target.value)} // Ensure state updates
                                                />
                                                <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioNow">
                                                    Now
                                                </label>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    name="payOption" 
                                                    type="radio"
                                                    value="later"
                                                    id="flexRadioLater"
                                                    checked={selectedPayOption === 'later'}
                                                    onChange={(e) => setSelectedPayOption(e.target.value)} // Ensure state updates
                                                />
                                                <label className="fw-semibold fs-6 ms-2" htmlFor="flexRadioLater">
                                                    Later
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )}

                            {selectedOption === "mark" && (
                                <div>
                                    <div className="fv-row mb-7 ">
                                        {/* begin::Label */}
                                        <label className="required fw-semibold fs-6 mb-2">
                                            Payment Date
                                        </label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        <input

                                            // {...formik.getFieldProps("user_name")}
                                            type="Date"
                                            name="user_name"
                                            className="form-control"

                                            autoComplete="off"
                                        />

                                    </div>
                                    <div className="fv-row mb-7 ">
                                        {/* begin::Label */}
                                        <label className="required fw-semibold fs-6 mb-2">
                                            Mode of payment
                                        </label>
                                        {/* end::Label */}

                                        <select className="form-select" data-control="select2" data-placeholder="Select an option" value={selOption} onChange={(e) => { setSelOption(e.target.value) }}>
                                            <option>Select</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Cheque">Cheque</option>
                                            <option value="Bank Transfer">Bank Transfer</option>
                                            <option value="Card">Card</option>


                                        </select>

                                    </div>
                                    {selOption && selOption !== "Cash" && (
                                        <div className="fv-row mb-7">
                                            {/* begin::Label */}
                                            <label className="required fw-semibold fs-6 mb-2">
                                                {selOption === "Cheque"
                                                    ? "Cheque No"
                                                    : selOption === "Bank Transfer"
                                                        ? "Transaction reference number"
                                                        : "Last 4 digits"}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input
                                                type="text"
                                                name="user_name"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder={selOption === "Cheque"
                                                    ? "Cheque No"
                                                    : selOption === "Bank Transfer"
                                                        ? "Transaction reference number"
                                                        : "Last 4 digits"}
                                            />
                                        </div>
                                    )}

                                    <div className="fv-row mb-7 ">
                                        {/* begin::Label */}
                                        <label className="required fw-semibold fs-6 mb-2">
                                            Amount
                                        </label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        <input

                                            // {...formik.getFieldProps("user_name")}
                                            type="text"
                                            name="user_name"
                                            className="form-control"
                                            placeholder='Amount'
                                            autoComplete="off"
                                            value={1000}
                                        />

                                    </div>


                                </div>
                            )}



                            <div className="text-center mt-10">
                                <button
                                    type="reset"
                                    // onClick={() => cancel()}
                                    className="btn btn-light me-3"
                                    data-kt-users-modal-action="cancel"
                                >
                                    Discard
                                </button>

                                <button
                                    type="submit"
                                    className="btn"
                                    data-kt-users-modal-action="submit"
                                >
                                    <span className="indicator-label">{selectedOption === "pay" ? "Pay" : "Mark as paid"}</span>
                                    {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PaymentCenter