import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Bank } from "../../Payee/PayrollStepper/PayrollStepperModal2";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
const token = tokenData ? tokenData.token : null;

interface Folder {
  source: string;
  folderGuid: string;
  folderName: string;
  parentFolderGuid: string;
  type: string; // Consider creating an enum if "FOLDER" has multiple options.
  size: string;
  lastModified: string;
  path: string;
  createdDate: string;
  updatedDate: string;
  root: boolean;
  fileName: string;
  readUrl: string;
}

interface UserFolderData {
  userId: string;
  tenantId: string;
  businessGuid: string;
  rootDirectory: string;
  folders: Folder[];
  parentFolderGuid: string;
  folderToParentMapping: Record<string, string>;
}

// Define initial state
interface VendorsState {
  folders: UserFolderData;
  banks: Bank[];
  count: number;
  loading: boolean;
  error: string | null;
}

interface uploadFilePayload {
  parentFolderGuid: string;
  uploadFiles: File[] | null;
}

interface ConfigModel {
  documentGuid: string;
  pageNumber: number;
  x: string;
  y: string;
  h: string;
  w: string;
}

interface SignDocumentPayload {
  itemId: string;
  type: "SPEC_PAGES";
  configModel: ConfigModel[];
}

const initialState: VendorsState = {
  folders: {
    userId: "",
    tenantId: "",
    rootDirectory: "",
    businessGuid: "",
    folders: [],
    parentFolderGuid: "",
    folderToParentMapping: {},
  },
  banks: [],
  count: 0,
  loading: false,
  error: null,
};

export interface SearchCriteria {
  propertyName: string;
  propertyValue: string | number;
}

interface CreateDirectoryPayload {
  parentFolderGuid: string;
  folderName: string;
}

interface DeleteDirectoryPayload {
  folderGuidList: string[];
}

interface FetchDirectoryPayload {
  search: string;
  parentFolderGuid: string;
  folderNameFilter: string;
  sourceTypeFilter: string;
  lastModifiedDateFilter: string;
}
interface FetchVendorPayload {
  tenantId: string;
  searchCriteriaList: SearchCriteria[];
  pageNumber: number;
  pageSize: number;
  skipPagination: boolean;
  // Define your payload type here if needed
}

// Define async thunk for fetching notifications
export const listDirectory = createAsyncThunk<
  UserFolderData,
  FetchDirectoryPayload,
  { rejectValue: string }
>(
  "/dataRoom/listDirectory",
  async (payload: FetchDirectoryPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/listDirectory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            // searchCriteriaList: payload.searchCriteriaList,
            // pageNumber: payload.pageNumber,
            // pageSize: payload.pageSize,
            // skipPagination: payload.skipPagination,
            ...(payload.search && {
              search: payload.search,
            }),
            ...(payload.parentFolderGuid && {
              parentFolderGuid: payload.parentFolderGuid,
            }),
            ...(payload.lastModifiedDateFilter && {
              lastModifiedDateFilter: payload.lastModifiedDateFilter,
            }),
            ...(payload.folderNameFilter && {
              folderNameFilter: payload.folderNameFilter,
            }),
            ...(payload.sourceTypeFilter && {
              sourceTypeFilter: payload.sourceTypeFilter,
            }),
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const listPrevDirectory = createAsyncThunk<
  UserFolderData,
  FetchVendorPayload,
  { rejectValue: string }
>(
  "/dataRoom/loadPreviousDirectory",
  async (payload: FetchVendorPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/loadPreviousDirectory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            searchCriteriaList: payload.searchCriteriaList,
            pageNumber: payload.pageNumber,
            pageSize: payload.pageSize,
            skipPagination: payload.skipPagination,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const deleteCustomDirectory = createAsyncThunk<
  UserFolderData,
  DeleteDirectoryPayload,
  { rejectValue: string }
>(
  "/dataRoom/deleteCustomDirectory",
  async (payload: DeleteDirectoryPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/deleteCustomDirectory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            folderGuidList: payload.folderGuidList,
            // folderName: payload.folderName,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const createDirectory = createAsyncThunk<
  UserFolderData,
  CreateDirectoryPayload,
  { rejectValue: string }
>(
  "/dataRoom/createCustomDirectory",
  async (payload: CreateDirectoryPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/createCustomDirectory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            parentFolderGuid: payload.parentFolderGuid,
            folderName: payload.folderName,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.status.statusCode;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const renameDirectory = createAsyncThunk<
  UserFolderData,
  CreateDirectoryPayload,
  { rejectValue: string }
>(
  "/dataRoom/renameCustomDirectory",
  async (payload: CreateDirectoryPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/renameCustomDirectory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            folderGuid: payload.parentFolderGuid,
            folderName: payload.folderName,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.status.statusCode;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const moveDirectory = createAsyncThunk<
  UserFolderData,
  {
    sourceFolderGuid: string;
    destinationFolderGuid: string;
    operationType: string;
  },
  { rejectValue: string }
>(
  "/dataRoom/moveDirectory",
  async (
    payload: {
      sourceFolderGuid: string;
      destinationFolderGuid: string;
      operationType: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/moveDirectory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            // folderGuid: payload.parentFolderGuid,
            // folderName: payload.folderName,
            sourceFolderGuid: payload.sourceFolderGuid,
            destinationFolderGuid: payload.destinationFolderGuid,
            operationType: payload.operationType,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.status.statusCode;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const uplaodFiles = createAsyncThunk<
  { statusCode: number; messageDescription: string },
  uploadFilePayload,
  { rejectValue: string }
>(
  "/dataRoom/uploadFiles",

  async (payload: uploadFilePayload, { rejectWithValue }) => {
    const { parentFolderGuid, uploadFiles } = payload;
    // console.log(bulkFile);

    if (!uploadFiles) {
      return rejectWithValue("No file selected.");
    }

    try {
      const formData = new FormData();
      uploadFiles.forEach((file) => {
        formData.append("uploadFiles", file);
      });
      formData.append("parentFolderGuid", parentFolderGuid);
      //   formData.append("tenantId", tenantId);
      //   formData.append("userId", userId);

      const response = await axios.post<{
        status: { statusCode: number; messageDescription: string };
      }>(`${API_URL}/dataRoom/uploadFiles`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status.statusCode === 0) {
        Swal.fire({
          title: "Confirmation!",
          text: "File added to directory",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "Alert!",
          text: response.data.status.messageDescription,
          icon: "warning",
        });
      }

      return response.data.status;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const downloadFolder = createAsyncThunk<
  UserFolderData,
  { url: string; folderGuid: string },
  { rejectValue: string }
>(
  "/dataRoom/downloadFolder",
  async (payload: { url: string; folderGuid: string }, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/${payload.url}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...(payload.url === "downloadFolder" && {
              folderGuid: payload.folderGuid,
            }),
            ...(payload.url === "downloadFile" && {
              fileGuid: payload.folderGuid,
            }),
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const contentType = response.headers.get("Content-Type");
      const contentDisposition = response.headers.get("Content-Disposition");

      // JSON Response Handling
      if (contentType && contentType.includes("application/json")) {
        const responseData = await response.json();
        return responseData.message;
      }

      // Blob-based file download for non-JSON content
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      // Determine filename
      let fileName = "downloaded-file";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(
          /filename=["']?([^"'\s]+)["']?/i
        );
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      } else if (contentType) {
        // Expanded content type mapping
        const extensionMap = {
          "application/pdf": "pdf",
          "image/jpeg": "jpg",
          "image/png": "png",
          "application/zip": "zip",
          "application/octet-stream": "bin", // Generic binary file
          "application/msword": "doc",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            "docx",
          "application/vnd.ms-excel": "xls",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            "xlsx",
          "text/csv": "csv",
        } as const;

        const extension =
          contentType in extensionMap
            ? extensionMap[contentType as keyof typeof extensionMap]
            : "file";

        fileName = contentDisposition
          ? fileName
          : `downloaded-file.${extension}`;
      }

      // Create temporary anchor for download
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);

      // Trigger download
      anchor.click();

      // Clean up resources
      anchor.remove();
      window.URL.revokeObjectURL(blobUrl);

      // Return a success message
      return "File downloaded successfully.";
    } catch (error) {
      // Proper error handling
      console.error("Download error:", error);
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred"
      );
    }
  }
);

export const signDocument = createAsyncThunk<
  UserFolderData,
  SignDocumentPayload,
  { rejectValue: string }
>(
  "/dataRoom/signDocument",
  async (payload: SignDocumentPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(`${API_URL}/dataRoom/signDocument`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          itemId: payload.itemId,
          type: "SPEC_PAGES",
          configModel: payload.configModel,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.status.statusCode;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

// Create slice
export const documentRoomSlice = createSlice({
  name: "dataRoom",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listDirectory?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listDirectory?.fulfilled, (state, action) => {
        state.loading = false;
        state.folders = action.payload;
        state.count = action?.payload?.folders?.length;
      })
      .addCase(listDirectory?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      });
  },
});

// Selectors
export const selectVendors = (state: { notifications: VendorsState }) =>
  state.notifications;

// Reducer
export default documentRoomSlice.reducer;
