import React, { useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import Select from "react-select";

interface Level {
    id: number;
    from: string;
    to: string;
    tax: string;
}
const TaxSettings = () => {
    const [levels, setLevels] = useState<Level[]>([
        { id: 1, from: '', to: '', tax: '' },  // Include tax field in the initial state
    ]);

    const [active, setActive] = useState(false);

    // Define the types for the parameters
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>, // Event type
        index: number, // Index type
        field: 'from' | 'to' | 'tax' // Field type (can be 'from', 'to', or 'tax')
    ) => {
        const newLevels = [...levels];
        newLevels[index][field] = e.target.value;
        setLevels(newLevels);
    };

    const addLevel = () => {
        const newLevel: Level = { id: levels.length + 1, from: '', to: '', tax: '' }; // Include tax in the new level
        setLevels([...levels, newLevel]);
    };
    return (
        <>
            <h4>Settings</h4>
            <div className='card p-5'>
                <div className='d-flex flex-row justify-content-between'>
                    <div>
                        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1"> Tax Settings</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">Integrations</a>
                            </li>
                            {/* <li className="nav-item">
        <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_3">Link 3</a>
    </li> */}
                        </ul>
                    </div>
                    <div>
                        <button className='btn' data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"> Add Integration</button>
                    </div>
                </div>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                        <div className='card shadow-sm'>
                            <div className='p-5' style={{ maxWidth: "400px" }}>

                                <div className="mb-7 d-flex flex-row align-items-center justify-content-around gap-5">
                                    <label className="form-label text-nowrap">VAT</label>
                                    <div className="position-relative w-100">
                                        <input
                                            type="text"
                                            className="form-control pr-5"
                                            placeholder=""

                                        />
                                        <span className="position-absolute top-50 end-0 translate-middle-y pe-3">%</span>
                                    </div>
                                </div>

                                <div className="mb-7 d-flex flex-row align-items-center justify-content-around gap-5">
                                    <label className="form-label text-nowrap">UID</label>
                                    <div className="position-relative w-100">
                                        <input
                                            type="text"
                                            className="form-control pr-5"
                                            placeholder=""

                                        />
                                        <span className="position-absolute top-50 end-0 translate-middle-y pe-3">1% employer</span>

                                    </div>
                                    <div className="position-relative w-100">
                                        <input
                                            type="text"
                                            className="form-control pr-5"
                                            placeholder=""

                                        />
                                        <span className="position-absolute top-50 end-0 translate-middle-y pe-3">1% employee</span>

                                    </div>
                                </div>
                                <div className=" d-flex flex-row align-items-center justify-content-around gap-5">
                                    <label className="form-label text-nowrap">SDL</label>
                                    <div className="position-relative w-100">
                                        <input
                                            type="text"
                                            className="form-control pr-5"
                                            placeholder=""

                                        />
                                        <span className="position-absolute top-50 end-0 translate-middle-y pe-3">1% payroll</span>
                                    </div>
                                </div>



                            </div>
                            <label className="form-label text-nowrap ms-5">PAYE</label>
                            <div className="mb-7 d-flex flex-column gap-4 card shadow-sm p-5 ms-5 me-5">


                                {levels.map((level, index) => (
                                    <div key={level.id} className="d-flex gap-3">
                                        <div className="position-relative w-30">
                                            <input
                                                type="text"
                                                className="form-control pr-5"
                                                placeholder="From"
                                                value={level.from}
                                                onChange={(e) => handleInputChange(e, index, 'from')}
                                            />

                                        </div>

                                        <div className="position-relative w-30">
                                            <input
                                                type="text"
                                                className="form-control pr-5"
                                                placeholder="To"
                                                value={level.to}
                                                onChange={(e) => handleInputChange(e, index, 'to')}
                                            />

                                        </div>

                                        <div className="position-relative w-30">
                                            <input
                                                type="text"
                                                className="form-control pr-5"
                                                placeholder="Tax"
                                                value={level.tax}
                                                onChange={(e) => handleInputChange(e, index, 'tax')}
                                            />
                                            {(
                                                <span className="position-absolute top-50 end-0 translate-middle-y pe-3">%</span>
                                            )}
                                        </div>
                                    </div>

                                ))}


                                <div className="d-flex gap-3 align-items-center">
                                    <div className="position-relative w-30">
                                        <p className='fw-bold'>{Number(levels[0].to) + 1} & Above</p>

                                    </div>

                                    <div className="position-relative w-30">
                                        <input
                                            type="text"
                                            className="form-control pr-5"
                                            placeholder="To"
                                        // value={level.to}
                                        // onChange={(e) => handleInputChange(e, index, 'to')}
                                        />

                                    </div>

                                    <div className="position-relative w-30">
                                        <input
                                            type="text"
                                            className="form-control pr-5"
                                            placeholder="Tax"
                                        // value={level.tax}
                                        // onChange={(e) => handleInputChange(e, index, 'tax')}
                                        />
                                        {(
                                            <span className="position-absolute top-50 end-0 translate-middle-y pe-3">%</span>
                                        )}
                                    </div>
                                </div>


                                <div className='d-flex flex-row gap-5'>
                                    <p onClick={addLevel} className="text-primary text-underline cursor-pointer">
                                        Add Level
                                    </p>

                                    <p onClick={addLevel} className="text-primary text-underline cursor-pointer">
                                        Stop
                                    </p>
                                </div>

                                <label className="form-label text-nowrap fw-bold">Add Rule</label>
                                <div className="mb-7 d-flex flex-column gap-4 card shadow-sm p-5 ms-5 me-5">

                                    <div className="form-group mb-4 d-flex flex-column">
                                        {!active ? (
                                            <>
                                                <div className="d-flex flex-row justify-content-between w-100">
                                                    <div>
                                                        {" "}
                                                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>{" "}
                                                        Add another payment approval rule
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary p-2 fs-7 m-0 p-0"
                                                            onClick={() => {
                                                                setActive(true);
                                                                // formik.resetForm();
                                                                // setAmountRange({ min: 0, max: Infinity });
                                                                // setApprovalLevels([[]]);
                                                                // setEditingRuleIndex(null);
                                                            }}
                                                        >
                                                            {" "}
                                                            <i
                                                                className="bi bi-plus-lg"
                                                                style={{ WebkitTextStroke: "0.5px" }}
                                                            ></i>
                                                            Add Rule
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex flex-row align-items-center mb-5">
                                                    <label className="form-label text-nowrap">
                                                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>
                                                        If amount is greater or equal to&nbsp;
                                                    </label>
                                                    <div className="mb-2">
                                                        <div
                                                            className="position-relative"
                                                            style={{ maxWidth: "200px" }}
                                                        >
                                                            <input
                                                                type="text"
                                                                // value={amountRange.min}
                                                                // onChange={(e) => handleAmountChange(e, "min")}
                                                                placeholder="0.00"
                                                                className="form-control"
                                                                style={{ paddingLeft: "60px", height: "33px" }}
                                                                maxLength={12}
                                                            />
                                                            <select
                                                                name="currency"
                                                                // value={formik.values.currency}
                                                                // onChange={formik.handleChange}
                                                                // onBlur={formik.handleBlur}
                                                                className="position-absolute"
                                                                style={{
                                                                    top: "50%",
                                                                    left: "5px",
                                                                    transform: "translateY(-50%)",
                                                                    border: "none",
                                                                    background: "transparent",
                                                                    color: "#6c757d",
                                                                    height: "33px",
                                                                    outline: "none",
                                                                }}
                                                            >
                                                                <option selected value="ZAR">
                                                                    ZAR
                                                                </option>
                                                                <option value="USD">USD</option>
                                                            </select>
                                                        </div>
                                                        {/* {formik.touched.fromAmount &&
                          formik.errors.fromAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.fromAmount}
                          </div>
                        ) : null} */}
                                                    </div>

                                                    <label className="form-label">
                                                        &nbsp;and less than &nbsp;
                                                    </label>
                                                    <div className="mb-2">
                                                        <div
                                                            className="position-relative"
                                                            style={{ maxWidth: "200px" }}
                                                        >
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "50%",
                                                                    left: "10px",
                                                                    transform: "translateY(-50%)",
                                                                    color: "#6c757d",
                                                                }}
                                                            >
                                                                {/* {formik.values.currency} */}
                                                            </span>
                                                            <input
                                                                type="text"
                                                                // value={
                                                                //   amountRange.max === Infinity
                                                                //     ? ""
                                                                //     : amountRange.max
                                                                // }
                                                                // onChange={(e) => handleAmountChange(e, "max")}
                                                                placeholder="No limit"
                                                                className="form-control"
                                                                style={{ paddingLeft: "50px", height: "33px" }}
                                                                maxLength={12}
                                                            />
                                                        </div>
                                                        {/* {formik.touched.toAmount && formik.errors.toAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.toAmount}
                          </div>
                        ) : null} */}
                                                    </div>
                                                </div>

                                                {/* {approvalLevels?.map((level, index) => ( */}
                                                <>
                                                    <div
                                                        className="form-group d-flex flex-row align-items-center gap-5 ms-10"
                                                    //   key={index}
                                                    >
                                                        <label className="form-label">
                                                            <i
                                                                className="bi bi-arrow-return-right me-3 "
                                                                style={{ WebkitTextStroke: "1px" }}
                                                            ></i>{" "}
                                                            {/* {index === 0 ? "Require" : "Then require"} */}
                                                        </label>
                                                        <div className="d-flex flex-grow-1 align-items-center mt-3">
                                                            <Select
                                                                isMulti
                                                                //   options={getAvailableApprovers(index)}
                                                                //   value={approvalLevels[index]}
                                                                //   onChange={(selectedOptions) => {
                                                                //     if (selectedOptions.length <= 5) {
                                                                //       handleApproverChange(
                                                                //         index,
                                                                //         selectedOptions as (RoleData | User)[]
                                                                //       );
                                                                //     } else {

                                                                //       toast.error('You can only select up to 5 approvers on each level.', { theme: "colored" });
                                                                //     }
                                                                //   }}
                                                                className="basic-multi-select w-100"
                                                                classNamePrefix="select"
                                                                placeholder="Select approvers for this level"
                                                            // getOptionLabel={(option) => option.name}
                                                            //   getOptionValue={(option) =>
                                                            //     "roleGuid" in option ? option.roleGuid : option.userGuid
                                                            //   }
                                                            //   components={{
                                                            //     MultiValue: ({ data, index, innerProps, removeProps }) => (
                                                            //       <>
                                                            //         {index > 0 && <span>&nbsp; or &nbsp;</span>}
                                                            //         <div {...innerProps} className="bg-gray-600 p-1 ps-2 pe-2 mt-2 mb-2 rounded bg-opacity-10">
                                                            //           {data.name}
                                                            //           <span className="fs-4 text-hover-danger ms-2" {...removeProps}>&times;</span>
                                                            //         </div>
                                                            //       </>
                                                            //     ),
                                                            //   }}
                                                            //   isDisabled={formik.values.toAmount === ""}

                                                            />



                                                            {/* {approvalLevels.length > 1 && ( */}
                                                            <span
                                                                className="fs-2x ms-2 mb-2 text-gray-600 cursor-pointer"
                                                            // onClick={() => removeApprovalLevel(index)}
                                                            >
                                                                &times;
                                                            </span>
                                                            {/* )} */}
                                                        </div>

                                                    </div>
                                                    {/* {
                          formik.touched.levels &&
                            formik.errors.levels ? (
                            typeof formik.errors.levels === "string" ? (
                              // Handle case where errors.levels is a string
                              <div className="text-danger">{formik.errors.levels}</div>
                            ) : Array.isArray(formik.errors.levels) &&
                              (formik.errors.levels as LevelError[])[index]?.actors ? (
                              // Handle case where errors.levels is an array and has specific actor errors
                              <div className="text-danger ms-10">
                                Level {index + 1}: {(formik.errors.levels as LevelError[])[index].actors}
                              </div>
                            ) : null
                          ) : null
                        } */}

                                                </>
                                                {/* ))} */}

                                                <span
                                                    className=" mb-4 text-start text-decoration-underline cursor-pointer ms-10"
                                                //   onClick={addApprovalLevel}
                                                >
                                                    Add another approver
                                                </span>

                                                <div className="form-group ms-10 d-flex flex-end">
                                                    <button
                                                        type="button"
                                                        className="btn  btn-light me-2 p-2 fs-7 "
                                                    onClick={()=>{setActive(false)}}

                                                    >
                                                        Cancel
                                                    </button>
                                                    <button type="submit" className="btn btn-primary p-2 fs-7">
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                            <div className='card p-2'>
                                <table className="table rounded table-row-gray-300 gy-7">
                                    <thead>
                                        <tr className="fw-bolder fs-6 text-gray-800">
                                            <th>Date Added</th>
                                            <th> Integration Name</th>
                                            <th>Type</th>
                                            <th>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>22/11/2024</td>
                                            <td>Abc Corporations</td>
                                            <td>Corporate Income Tax (CIT)</td>
                                            <td > <div className='badge badge-success'>Active</div></td>

                                        </tr>
                                        <tr>
                                            <td>22/10/2024</td>
                                            <td>Xfin</td>
                                            <td>Value Added Tax (VAT)</td>
                                            <td > <div className='badge badge-success'>Active</div></td>

                                        </tr>
                                        <tr>
                                            <td>22/10/2024</td>
                                            <td>Xfin</td>
                                            <td>Pay-AS-You-Earn (PAYE)</td>
                                            <td > <div className='badge badge-success'>Active</div></td>

                                        </tr>
                                        <tr>
                                            <td>22/10/2024</td>
                                            <td>Xfin</td>
                                            <td>Unemployed Insurance Fund (UIF)</td>
                                            <td > <div className='badge badge-success'>Active</div></td>

                                        </tr>
                                        <tr>
                                            <td>22/10/2024</td>
                                            <td>Xfin</td>
                                            <td>Skill Developemnt Levy (SDL)</td>
                                            <td > <div className='badge badge-success'>Active</div></td>

                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">

                        </div>
                    </div>

                </div>
                <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Integration</h5>

                            </div>
                            <div className="modal-body">
                                <div className='mb-7'>
                                    <label htmlFor="exampleFormControlInput1" className="required form-label">Type</label>
                                    <select className="form-select" data-control="select2" data-placeholder="Select an option">
                                        <option></option>
                                        <option value="1">Corporate Income Tax (CIT)</option>
                                        <option value="2">Value Added Tax (VAT) </option>
                                        <option value="3">Pay-AS-You-Earn (PAYE) </option>
                                        <option value="4">Unemployed Insurance Fund (UIF) </option>
                                        <option value="5">Skill Developemnt Levy (SDL) </option>
                                        <option value="6">Turnover Tax (Small Business Alternative) </option>
                                        <option value="7">Provisional Tax </option>
                                    </select>
                                </div>
                                <div className="">
                                    <label htmlFor="" className="form-label">API</label>
                                    <textarea className="form-control" data-kt-autosize="true" style={{ minHeight: "200px" }}></textarea>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary">
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </>
            )
}

            export default TaxSettings