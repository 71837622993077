import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  activateInstance,
  Category,
  createRule,
  deleteInstance,
  findMasterData,
  findRule,
  inactivateInstance,
  Rule,
} from "./RuleDashboardSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { MutatingDots } from "react-loader-spinner";
import Loader from "../components/Loader";

const RuleDashboard = () => {
  const { rules, categoryList, loading } = useSelector(
    (state: RootState) => state.ruleDashboard
  );
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  console.log(loading);

  useEffect(() => {
    dispatch(
      findRule({
        pageNumber: 1,
        pageSize: 100,
        skipPagination: true,
        // searchCriteriaList: [
        //   {
        //     propertyName: "name1",
        //     propertyValue: "tran",
        //   },
        // ],
      }) as any
    );
    dispatch(findMasterData() as any);
  }, [dispatch]);

  const categoryOptions = categoryList.map((category: Category) => ({
    value: category.code,
    label: category.name,
  }));

  const formik = useFormik({
    initialValues: {
      categoryName: "",
      categoryCode: "",
      ruleName: "",
      ruleCode: "",
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required("Category is required"),
      ruleName: Yup.string().required("Rule name is required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await dispatch(createRule(values) as any);

        if (res?.meta?.requestStatus === "fulfilled") {
          // Show success alert
          Swal.fire({
            title: "Rule Created",
            text: "Redirecting to the rule settings page...",
            icon: "success",
            confirmButtonText: "Ok",
          });

          // Fetch the rules
          await dispatch(
            findRule({
              pageNumber: 1,
              pageSize: 100,
              skipPagination: true,
            }) as any
          );

          setTimeout(() => {
            navigate("/settings", {
              state: { data: res.payload?.message, values: formik.values },
            });
            Swal.close();
          }, 1000);
        }
      } catch (error) {
        console.error("Failed to create rule:", error);
      } finally {
        if (dropdownRef.current) {
          dropdownRef.current.classList.remove("show");
        }
        formik.resetForm();
      }
    },
  });

  const handleToggleStatus = (rule: Rule) => {
    const action = rule.status === "ACTIVE" ? "deactivate" : "activate";

    Swal.fire({
      title: `Are you sure you want to ${action} this rule?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const dispatchAction =
          rule.status === "ACTIVE"
            ? inactivateInstance({ instanceGuid: rule.instanceGuid })
            : activateInstance({ instanceGuid: rule.instanceGuid });

        dispatch(dispatchAction as any).then((res: any) => {
          if (res?.meta?.requestStatus === "fulfilled") {
            Swal.fire({
              title: `Rule ${rule.status === "ACTIVE" ? "Deactivated" : "Activated"}`,
              icon: "success",
              confirmButtonText: "Ok",
            });
            dispatch(
              findRule({
                pageNumber: 1,
                pageSize: 100,
                skipPagination: true,
              }) as any
            );
          }
        });
      }
    });
  };

  const handleDelete = async (rule: Rule) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete this rule?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (result.isConfirmed) {
      await dispatch(
        deleteInstance({ instanceGuid: rule.instanceGuid }) as any
      ).then(async (res: any) => {
        if (res?.meta?.requestStatus === "fulfilled") {
          await Swal.fire({
            title: `Rule deleted`,
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
          await dispatch(
            findRule({
              pageNumber: 1,
              pageSize: 100,
              skipPagination: true,
              // searchCriteriaList: [
              //   {
              //     propertyName: "name1",
              //     propertyValue: "tran",
              //   },
              // ],
            }) as any
          );
        }
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4>Rule Data</h4>
          <div className="card shadow-sm p-4 px-md-8 m-5">
            <div className="d-flex justify-content-end mb-5">
              <div className="dropdown" ref={dropdownRef}>
                <button
                  type="button"
                  className="btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  Add Rule
                </button>

                <div
                  className="dropdown-menu mt-2"
                  style={{ width: "300px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="dropdown-header text-gray-900 fs-4 fw-bold px-3 py-2">
                    Add Rule
                  </div>
                  <div className="dropdown-divider"></div>
                  <form onSubmit={formik.handleSubmit} className="p-5">
                    <div className="p-2">
                      <label className="form-label">Select Category</label>
                      <select
                        name="categoryName"
                        className="form-select"
                        onChange={(e) => {
                          const selectedCategory = categoryList.find(
                            (cat: Category) => cat.code === e.target.value
                          );
                          formik.setFieldValue(
                            "categoryName",
                            selectedCategory ? selectedCategory.name : ""
                          );
                          formik.setFieldValue(
                            "categoryCode",
                            selectedCategory ? selectedCategory.code : ""
                          );
                          formik.setFieldValue("ruleName", "");
                          formik.setFieldValue("ruleCode", "");

                          formik.setFieldTouched("categoryName", false);
                          formik.setFieldTouched("categoryCode", false);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.categoryCode}
                      >
                        <option value="">Select a category</option>
                        {categoryOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {formik.touched.categoryName &&
                      formik.errors.categoryName ? (
                        <div className="text-danger">
                          {formik.errors.categoryName}
                        </div>
                      ) : null}
                    </div>
                    <div className="p-2">
                      <label className="form-label">Select Rule</label>
                      <select
                        name="ruleName"
                        className="form-select"
                        onChange={(e) => {
                          const selectedRule = categoryList
                            .find(
                              (cat: Category) =>
                                cat.code === formik.values.categoryCode
                            )
                            ?.names.find(
                              (rule) => rule.name === e.target.value
                            );

                          formik.setFieldValue(
                            "ruleName",
                            selectedRule ? selectedRule.name : ""
                          );
                          formik.setFieldValue(
                            "ruleCode",
                            selectedRule ? selectedRule.code : ""
                          );

                          formik.setFieldTouched("ruleName", false);
                          formik.setFieldTouched("ruleCode", false);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.ruleName}
                      >
                        <option value="">Select a rule</option>
                        {formik.values.categoryCode &&
                          categoryList
                            .find(
                              (cat: Category) =>
                                cat.code === formik.values.categoryCode
                            )
                            ?.names.map((rule) => (
                              <option key={rule.code} value={rule.name}>
                                {rule.name}
                              </option>
                            ))}
                      </select>
                      {formik.touched.ruleName && formik.errors.ruleName ? (
                        <div className="text-danger">
                          {formik.errors.ruleName}
                        </div>
                      ) : null}
                    </div>

                    <div className="dropdown-divider"></div>
                    <div className="px-3 py-2 d-flex flex-center">
                      <button className="btn w-100 px-4" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                <thead className="bg-gray-200">
                  <tr className="text-start text-dark fw-bold fs-7 gs-0">
                    <th className=" p-3 p-md-5">Name</th>
                    <th className=" p-3 p-md-5">Category</th>
                    <th className=" p-3 p-md-5">Status</th>
                    <th className=" p-3 p-md-5 text-start">Action</th>
                  </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {rules?.length === 0 ? (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No data found.
                      </td>
                    </tr>
                  ) : (
                    rules?.map((rule: Rule, index: number) => (
                      <tr key={index}>
                        <td
                          className="p-3 p-md-5 cursor-pointer"
                          onClick={() => {
                            navigate("/settings", { state: { data: rule } });
                          }}
                        >
                          {rule?.ruleName}
                        </td>
                        <td className="p-3 p-md-5">{rule?.categoryName}</td>
                        <td className="p-3 p-md-5">
                          <div className="form-check form-switch form-check-custom form-check-success form-check-solid">
                            <input
                              className="form-check-input h-20px w-30px"
                              type="checkbox"
                              id={`kt_flexSwitchCustomDefault_${index}`}
                              checked={rule?.status === "ACTIVE"}
                              onChange={() => handleToggleStatus(rule)} // Call the toggle function
                            />
                          </div>
                        </td>
                        <td className="p-3 p-md-5">
                          <i
                            className="bi bi-eye  cursor-pointer"
                            style={{ color: "#0000ff" }}
                            onClick={() => {
                              navigate("/settings", { state: { data: rule } });
                            }}
                          ></i>{" "}
                          <i
                            className="bi bi-trash3 ms-5 text-danger cursor-pointer"
                            onClick={() => {
                              handleDelete(rule);
                            }}
                          ></i>{" "}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RuleDashboard;
