import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import folder from "../../../../_metronic/assets/all-serviceIcons/folder.svg";
import { deleteDocuments, findSettings } from "./settingSlice";
import { RootState } from "../../../store";

const TaxSign: FC = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<string[]>([]);

  const { settings, loading } = useSelector(
    (state: RootState) => state?.settings
  );

  useEffect(() => {
    dispatch(findSettings({} as any) as any);
  }, [dispatch]);

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    folderGuid: string
  ) => {
    const target = e.target as HTMLInputElement;

    setChecked((prev) => {
      if (target.checked) {
        // Add the folderGuid to the state
        return [...prev, folderGuid];
      } else {
        // Remove the folderGuid from the state
        return prev.filter((guid) => guid !== folderGuid);
      }
    });
  };

  return (
    <>
      {" "}
      <h4 className="mb-4">Document Room - Library</h4>{" "}
      <div className="card p-2">
        <div className="d-flex justify-content-between mb-3">
          {/* <div className="d-flex align-items-center  position-relative my-1 ms-10">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-docs-table-filter="search"
              className="form-control p-3 border-secondary w-250px ps-15"
              placeholder="Search Files & Folders"
              // defaultValue={searchTerm}
              // onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div> */}
          <div className="d-flex flex-1 align-items-center p-3 gap-10 flex-end">
            {checked?.length > 0 && (
              <button
                className={`btn btn-danger fs-7 p-3 w-100px`}
                onClick={() =>
                  Swal.fire({
                    icon: "warning",
                    text: "Are you sure you want to delete the selected files?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                  }).then((res) => {
                    if (res.isConfirmed)
                      dispatch(
                        deleteDocuments({
                          documentGuids: checked,
                        } as any) as any
                      ).then(() => {
                        setChecked([]);
                        dispatch(findSettings({} as any) as any);
                      });
                  })
                }
                //   id="kt_modal_toggle_domestic"
                //   disabled={payAmount < 1}
              >
                Delete ({checked?.length})
              </button>
            )}
          </div>
        </div>

        {settings?.documents?.length > 0 ? (
          // Grid View
          <div className="ms-10 row g-2 gap-10 mb-5">
            {settings?.documents?.map((item: any, index: number) => (
              <div className="col-lg-2 col-md-4" key={index}>
                <div
                  className="border rounded p-4 h-100 bg-light bg-hover-secondary cursor-pointer"
                  onMouseOver={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "#f1f1f2";
                  }}
                  onMouseOut={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "white";
                  }}
                >
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    <div className="form-check form-check-custom form-check-sm">
                      <input
                        className="form-check-input cursor-pointer border border-dark"
                        type="checkbox"
                        onClick={(e) => handleCheckClick(e, item.documentGuid)}
                        checked={checked.includes(item.documentGuid)}
                      />
                    </div>
                  </div>

                  <div className="text-center mb-3">
                    {/* <i className="bi bi-folder2-open fs-1 text-primary"></i> */}
                    <img
                      src={item?.documentPath}
                      alt={folder}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>

                  <h5 className="text-center text-gray-800 mb-2">
                    {item.documentName}
                  </h5>

                  {/* <div className="text-center text-gray-600 small">
                    <div>{item.lastModified}</div>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-5">
            <p className="text-gray-600">No Data Found</p>
          </div>
        )}
      </div>
    </>
  );
};

export { TaxSign };
