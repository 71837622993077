import { FC, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import XLogo from "../../../_metronic/assets/Icons/xfin.png";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { validateOtp } from "./shareHistorySlice";

const ViewLinks: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { "*": remainingPath } = useParams();
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const url = `${API_URL}/docApp/validateLink/${remainingPath}`;

  useEffect(() => {
    console.log("api called");

    // Example API call using the full path
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          // body,
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(url, options);
        const data = await response.json();
        const saveData = data?.message;
        // Handle the API response data
        sessionStorage.setItem("verifyData", JSON.stringify({ saveData }));
        console.log(data.message);
        if (data.status.statusCode === 0) {
        }
      } catch (error) {
        // Handle any errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [url]);

  const validate = useFormik({
    initialValues: {
      token: "",
    },
    validationSchema: Yup.object().shape({
      token: Yup.string()
        .matches(
          /^[A-Za-z0-9]+$/,
          "Only letters (A-Z) (a-z), digits (0-9) are allowed"
        )
        .required("Verification code is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        const data = await dispatch(
          validateOtp({ otp: values?.token } as any) as any
        );
        if (validateOtp.fulfilled.match(data)) {
          // Navigate only if the thunk is successful
          navigate("/docApp/list");
        } else {
          console.error(data.payload || "Validation failed");
        }
        console.log(data);

        // await withdrawMoney(values?.token);
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    },
  });

  return (
    <>
      <style>
        {`
        .responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.responsive-card {
  width: 100%;
  max-width: 600px;
  height: auto;
  min-height: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .responsive-card {
    padding: 1rem;
    min-height: 200px;
  }
}
        `}
      </style>
      <div className="responsive-container">
        <div className="responsive-card">
          <img alt="Logo" src={XLogo} className="h-50px mb-4" />
          <div className="w-75">
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <p>Verification Code has been sent to tes***@yopmail.com</p>
            </div>
            <form onSubmit={validate.handleSubmit}>
              <div className="mt-3 p-10">
                <div className="mb-3">
                  <label className="form-label required">
                    Enter Verification Code
                  </label>
                  <input
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          validate.touched.token && validate.errors.token,
                      },
                      {
                        "is-valid":
                          validate.touched.token && !validate.errors.token,
                      }
                    )}
                    placeholder="Enter code"
                    name="token"
                    value={validate.values.token}
                    onChange={validate.handleChange}
                    onBlur={validate.handleBlur}
                    maxLength={10}
                  />
                  {validate.touched.token && validate.errors.token ? (
                    <div className="text-danger mt-1">
                      {validate.errors.token}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-center gap-5 mt-10">
                  <button
                    className="btn btn-primary"
                    type="button"
                    // disabled={validate.isSubmitting}
                  >
                    Resend
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    disabled={validate.isSubmitting}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </>
  );
};

export { ViewLinks };
