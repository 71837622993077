/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { Modal } from "bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { AddVendorModal } from "./AddVendorModal";
import { currencyData } from "../components/Modal/currencyData";
import { Vendor } from "./vendorSlice";

const fileTypes = ["pdf"];

const AddInvoice: FC = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const navigate = useNavigate();
  const { vendors } = useSelector((state: RootState) => state?.vendors);
  const type = (useLocation().state as { invoiceType: number })?.invoiceType;
  console.log(type);

  const [tab, setTab] = useState("session");

  const [add, setAdd] = useState(false);
  const [file, setFile] = useState<File | null>();
  const [fileurl, setUrl] = useState("");
  const [previousValue, setPreviousValue] = useState("");

  useEffect(() => {
    if (type === 1) formik.setFieldValue("currency", "ZAR");
  }, [type]);

  const addInvoice = async (invdata: any, upload: boolean, fileurl: string) => {
    try {
      const url = `${API_URL}/vendorInvoice/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        invoiceDate: invdata?.invoiceDate,
        invoiceNumber: invdata?.invoiceNumber,
        vendorName: invdata?.vendorName,
        amount: invdata?.amount,
        currency: invdata?.currency,
        dueDate: invdata?.dueDate,
        invoiceType: type,
        upload: upload,
        url: fileurl,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        const modal = Modal.getInstance("#kt_modal_add_manual");
        if (modal) {
          modal.hide();
        }
        Swal.fire({
          icon: "success",
          text: "Invoice added successfully",
        });
        if (type === 1) navigate("/invoice/domesticVendor");
        else navigate("/invoice/internationalVendor");
        // getInvoice();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invoice_date: "",
      inv_num: "",
      vendor_name: "",
      currency: type === 1 ? "ZAR" : "",
      amount: "",
      due_date: "",
    },
    validationSchema: Yup.object().shape({
      invoice_date: Yup.string()
        .required("Invoice date is required")
        .matches(
          /^\d{4}-\d{2}-\d{2}$/,
          "Invoice date must be in the format DD-MM-YYYY"
        )
        .test("year-limit", "Year cannot be more than 9999", (value) => {
          if (!value) return true; // Skip empty check, as it's handled by required
          const year = parseInt(value.split("-")[0], 10);
          return year <= 9999;
        }),
      inv_num: Yup.string().required("Invoice number is required"),
      vendor_name: Yup.string()
        .max(50, "Maximum 50 characters allowed")
        .required("Vendor name is required"),
      currency: Yup.string().required("Currency is required"),
      amount: Yup.string()
        .required("Invoice amount is required")
        .test(
          "zar-limit-validation",
          "amount should be greater than 0",
          function (value) {
            if (value) {
              return +value > 0;
            }

            return true;
          }
        ),
      due_date: Yup.string()
        .required("Due date is required")
        .matches(
          /^\d{4}-\d{2}-\d{2}$/,
          "Due date must be in the format DD_MM_YYYY"
        )
        .test("year-limit", "Year cannot be more than 9999", (value) => {
          if (!value) return true; // Skip empty check, as it's handled by required
          const year = parseInt(value.split("-")[0], 10);
          return year <= 9999;
        })
        .test(
          "due-after-invoice",
          "Due date must be greater than invoice date",
          function (value) {
            const { invoice_date } = this.parent;
            if (!value || !invoice_date) return true; // Skip if either date is missing
            return new Date(value) > new Date(invoice_date);
          }
        ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        invoiceDate: values.invoice_date,
        invoiceNumber: values.inv_num,
        vendorName: values.vendor_name,
        currency: values.currency,
        amount: values.amount,
        dueDate: values.due_date,
      };

      try {
        setSubmitting(true);
        addInvoice(data, file ? true : false, fileurl);
        // formik.resetForm();
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        // cancel(true);
      }
    },
    onReset: () => {
      if (type === 1) formik.setFieldValue("currency", "ZAR");
    },
  });

  const handleOpenModal = () => {
    const modalElement = document.getElementById("item-modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const handleVendorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    // setSelectedCompany(selectedValue);

    if (selectedValue === "add-new-vendor") {
      // if (formikRef.current) {
      //   formikRef.current.resetForm();
      // }
      handleOpenModal();
    }

    if (selectedValue !== "add-new-vendor") {
      formik.handleChange(event);
      // Fetch company details based on selectedValue (companyName or id) from API or data source
      // const selectedCompanyDetails = customers.find(
      //   (company) => company.customerName === selectedValue
      // );
      // if (selectedCompanyDetails) {
      //   setCompanyDetails(selectedCompanyDetails);
      // }
    } else {
      // setCompanyDetails(null);
    }
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // If the value is empty, set the previous value to an empty string
    if (value === "") {
      setPreviousValue("");
      return ""; // Return an empty string
    }

    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      // Assuming `updateData` is your function to update the state
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleAddFile = (uploadedFile: File | null) => {
    if (uploadedFile) {
      setFile(uploadedFile);
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("invoiceType", type?.toString());

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // Create the POST request options
      const requestOptions = {
        method: "POST",
        headers,
        body: formData,
      };
      console.log(requestOptions);

      const url = `${API_URL}/vendorInvoice/uploadVendorInvoiceFile`;
      // Send the POST request
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status?.statusCode === 0) {
            setAdd(true);
            setUrl(result?.message?.url);
            formik.setValues({
              invoice_date: result?.message?.invoiceDate?.split("T")?.[0],
              inv_num: result?.message?.invoiceNumber,
              vendor_name:
                vendors?.find(
                  (vendor: Vendor) =>
                    vendor.companyName === result?.message?.vendorName
                )?.companyName || "",
              currency: result?.message?.currency,
              amount: result?.message?.amount,
              due_date: result?.message?.dueDate?.split("T")?.[0],
            });
          } else {
            setAdd(false);
            setFile(null);
            Swal.fire({
              icon: "error",
              title: result?.status?.errorTitle || "Error",
              html:
                `<strong style="display: block; margin-bottom: 10px;">${
                  result?.status?.messageDescription || ""
                }</strong>` +
                (result?.status?.suggestion
                  ? `<span>${result.status.suggestion}</span><br>
                      <a href="${result.status?.refUrl}" target="_blank" rel="noopener noreferrer" style="display: block; margin-top: 10px;">
                        ${result?.status?.refUrl ? `Reference: ${result.status?.refUrl}` : ""}
                      </a>`
                  : result?.status?.refUrl
                    ? `<a href="${result.status?.refUrl}" target="_blank" rel="noopener noreferrer">${result.status?.refUrl}</a>`
                    : ""),
              allowEscapeKey: true,
              allowEnterKey: true,
            });
          }
        })
        .catch((error) => console.error("error", error));
    } else {
      console.error("No file selected.");
    }
  };

  return (
    <>
      <h4 className="mb-4">
        Add Invoice {type === 1 ? "Domestic" : "International"}
      </h4>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div className="card min-h-550px">
            <div className="card-body">
              <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                <li
                  className="nav-item"
                  onClick={() => {
                    formik.resetForm({
                      values: {
                        ...formik.values, // Keep the current values
                        currency:
                          formik.values.currency || (type === 1 ? "ZAR" : ""), // Preserve or update the currency value
                      },
                    });
                    setFile(null);
                    setUrl("");
                  }}
                >
                  <a
                    className={`nav-link ${tab === "session" ? "active" : ""}`}
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_1"
                  >
                    Add Manually
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    formik.resetForm({
                      values: {
                        ...formik.values,
                        currency:
                          formik.values.currency || (type === 1 ? "ZAR" : ""),
                      },
                    });
                    setTab("");
                    setFile(null);
                    setUrl("");
                  }}
                >
                  <a
                    className={`nav-link ${tab === "activity" ? "active" : ""}`}
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_2"
                  >
                    Upload File
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade active show`}
                  id="kt_tab_pane_1"
                  role="tabpanel"
                >
                  <form
                    id="kt_modal_add_manual_form"
                    className="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <div className="d-flex flex-column mx-15 px-15">
                      <div className="fv-row mb-7 mt-5">
                        <label className="required fw-bold fs-6 mb-2">
                          Invoice Date
                        </label>

                        <input
                          placeholder="Select invoice date"
                          {...formik.getFieldProps("invoice_date")}
                          type="date"
                          name="invoice_date"
                          maxLength={50}
                          onKeyDown={() => {}}
                          className={clsx("form-control mb-3 mb-lg-0")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.invoice_date}
                          autoComplete="off"
                          max={new Date().toISOString().split("T")[0]}
                        />
                        {formik.touched.invoice_date &&
                          formik.errors.invoice_date && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.invoice_date}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Invoice Number
                        </label>

                        <input
                          placeholder="Enter invoice number"
                          maxLength={25}
                          {...formik.getFieldProps("inv_num")}
                          className={clsx("form-control mb-3 mb-lg-0")}
                          type="text"
                          name="inv_num"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.inv_num}
                          autoComplete="off"
                        />

                        {formik.touched.inv_num && formik.errors.inv_num && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{formik.errors.inv_num}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Vendor Name
                        </label>

                        <select
                          {...formik.getFieldProps("vendor_name")}
                          className={clsx("form-select    mb-3 mb-lg-0")}
                          name="vendor_name"
                          onChange={(e) => {
                            handleVendorChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.vendor_name}
                          autoComplete="off"
                        >
                          <option selected value="">
                            To
                          </option>
                          <option value="add-new-vendor">Add new vendor</option>
                          <hr />
                          {vendors
                            ?.filter((ven: Vendor) => ven.vendorType === type)
                            ?.map((item: Vendor, index: number) => (
                              <option key={index} value={item.companyName}>
                                {item.companyName}
                              </option>
                            ))}
                        </select>
                        {formik.touched.vendor_name &&
                          formik.errors.vendor_name && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.vendor_name}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className="mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Amount
                        </label>
                        <div className="input-group mb-3">
                          <button
                            className={`${type === 1 ? "" : "dropdown-toggle"} w-25 d-flex justify-content-between align-items-center`}
                            type="button"
                            {...(type === 2 && {
                              "data-bs-toggle": "dropdown",
                            })}
                            aria-expanded="false"
                            style={{
                              borderTopLeftRadius: "5px",
                              borderBottomLeftRadius: "5px",
                              borderRight: "none",
                              borderWidth: "1px", // Reduced border thickness
                              borderStyle: "solid",
                              borderColor: "rgb(207, 204, 204)", // Using rgb for GrayText
                              backgroundColor: "transparent",
                              padding: "0.375rem 0.75rem", // Standard Bootstrap button padding
                              fontSize: "1rem", // Standard Bootstrap font size
                              lineHeight: "1.5", // Standard Bootstrap line height
                              transition:
                                "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                            }}
                          >
                            <span className="me-2">
                              {formik.values.currency}
                            </span>
                            <span className="dropdown-toggle-split"></span>
                          </button>
                          <ul
                            className="dropdown-menu scroll-y"
                            style={{ maxHeight: "200px", width: "200px" }}
                          >
                            {type === 1
                              ? null
                              : currencyData
                                  .filter(
                                    (country) => country.currency != "ZAR"
                                  )
                                  .map((country, index) => (
                                    <li key={index}>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          formik.setFieldValue(
                                            "currency",
                                            country?.currency
                                          );
                                        }}
                                      >
                                        {country?.flag} {country?.currency}
                                      </a>
                                    </li>
                                  ))}
                          </ul>
                          <input
                            {...formik.getFieldProps("amount")}
                            type="text"
                            className={clsx("form-control")}
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                            placeholder="Enter invoice amount"
                            aria-label="Amount input with currency dropdown"
                            max="100000"
                            pattern="[0-9]*"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "amount",
                                handleAmountChange(e)
                              );
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          {formik.errors.currency && (
                            <div className="fv-plugins-message-container w-25">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.currency}
                                </span>
                              </div>
                            </div>
                          )}
                          {formik.touched.amount && formik.errors.amount && (
                            <div className="fv-plugins-message-container w-75">
                              <div className="fv-help-block">
                                <span role="alert">
                                  | {formik.errors.amount}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Due date
                        </label>

                        <input
                          placeholder="Select due date"
                          maxLength={70}
                          {...formik.getFieldProps("due_date")}
                          className={clsx("form-control mb-3 mb-lg-0")}
                          type="date"
                          name="due_date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.due_date}
                          autoComplete="off"
                          min={formik?.values.invoice_date}
                        />
                        {formik.touched.due_date && formik.errors.due_date && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{formik.errors.due_date}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-center mt-10 mb-5">
                      <button
                        type="reset"
                        onClick={() => formik.resetForm()}
                        className="btn btn-light me-3"
                        data-kt-users-modal-action="cancel"
                      >
                        Discard
                      </button>

                      <button type="submit" className="btn ">
                        <span className="indicator-label">Submit</span>
                        {formik.isSubmitting && (
                          <span className="indicator-progress">
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="kt_tab_pane_2"
                  role="tabpanel"
                >
                  <div className="d-flex mb-3 justify-content-center mx-15 px-15">
                    {file ? (
                      <div className="d-flex flex-column w-100 justify-content-center ">
                        <div className="d-flex justify-content-center">
                          <div
                            style={{
                              border: "2px dashed #888",
                              borderRadius: "8px",
                              padding: "20px",
                              width: "340px",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                              style={{ width: "300px" }}
                            >
                              <div className="d-flex flex-row">
                                <p className="fw-bold">{file.size}&nbsp;</p>
                                <span>KB</span>
                              </div>
                              <div className="text-container my-2">
                                <p
                                  className="text-truncate"
                                  style={{ maxWidth: "15rem" }}
                                >
                                  {file.name}
                                </p>
                              </div>
                              <p
                                className="text-danger text-decoration-underline fw-bold cursor-pointer"
                                onClick={handleFileRemove}
                              >
                                Remove
                              </p>
                            </div>
                          </div>
                        </div>
                        {add && (
                          <form
                            id="kt_modal_add_manual_form"
                            className="form"
                            onSubmit={formik.handleSubmit}
                            noValidate
                          >
                            <div
                              className="d-flex flex-column scroll-y me-n7 pe-7"
                              id="kt_modal_add_manualr_scroll"
                              data-kt-scroll="true"
                              data-kt-scroll-activate="{default: false, lg: true}"
                              data-kt-scroll-max-height="auto"
                              data-kt-scroll-dependencies="#kt_modal_add_manual_header"
                              data-kt-scroll-wrappers="#kt_modal_add_manual_scroll"
                              data-kt-scroll-offset="300px"
                            >
                              <div className="fv-row mb-7 mt-5">
                                <label className="required fw-bold fs-6 mb-2">
                                  Invoice Date
                                </label>

                                <input
                                  placeholder="Select invoice date"
                                  {...formik.getFieldProps("invoice_date")}
                                  type="date"
                                  name="invoice_date"
                                  maxLength={50}
                                  className={clsx("form-control mb-3 mb-lg-0")}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.invoice_date}
                                  autoComplete="off"
                                  max={new Date().toISOString().split("T")[0]}
                                />
                                {formik.touched.invoice_date &&
                                  formik.errors.invoice_date && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {formik.errors.invoice_date}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>

                              <div className="fv-row mb-7">
                                <label className="required fw-bold fs-6 mb-2">
                                  Invoice Number
                                </label>

                                <input
                                  placeholder="Enter invoice number"
                                  maxLength={25}
                                  {...formik.getFieldProps("inv_num")}
                                  className={clsx("form-control mb-3 mb-lg-0")}
                                  type="text"
                                  name="inv_num"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.inv_num}
                                  autoComplete="off"
                                />

                                {formik.touched.inv_num &&
                                  formik.errors.inv_num && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {formik.errors.inv_num}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>

                              <div className="mb-7">
                                <label className="required fw-bold fs-6 mb-2">
                                  Vendor Name
                                </label>

                                <select
                                  {...formik.getFieldProps("vendor_name")}
                                  className={clsx("form-select mb-3 mb-lg-0")}
                                  name="vendor_name"
                                  onChange={(e) => {
                                    handleVendorChange(e);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.vendor_name}
                                  autoComplete="off"
                                >
                                  <option selected value="">
                                    To
                                  </option>
                                  <option value="add-new-vendor">
                                    Add new vendor
                                  </option>
                                  <hr />
                                  {vendors
                                    ?.filter(
                                      (ven: Vendor) => ven.vendorType === type
                                    )
                                    ?.map((item: Vendor, index: number) => (
                                      <option
                                        key={index}
                                        value={item.companyName}
                                      >
                                        {item.companyName}
                                      </option>
                                    ))}
                                </select>
                                {formik.touched.vendor_name &&
                                  formik.errors.vendor_name && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {formik.errors.vendor_name}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>

                              <div className="mb-7">
                                <label className="required fw-bold fs-6 mb-2">
                                  Amount
                                </label>
                                <div className="input-group mb-3">
                                  <button
                                    className="dropdown-toggle w-25 d-flex justify-content-between align-items-center"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{
                                      borderTopLeftRadius: "5px",
                                      borderBottomLeftRadius: "5px",
                                      borderRight: "none",
                                      borderWidth: "1px", // Reduced border thickness
                                      borderStyle: "solid",
                                      borderColor: "rgb(207, 204, 204)", // Using rgb for GrayText
                                      backgroundColor: "transparent",
                                      padding: "0.375rem 0.75rem", // Standard Bootstrap button padding
                                      fontSize: "1rem", // Standard Bootstrap font size
                                      lineHeight: "1.5", // Standard Bootstrap line height
                                      transition:
                                        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                                    }}
                                  >
                                    <span className="me-2">
                                      {formik.values.currency}
                                    </span>
                                    <span className="dropdown-toggle-split"></span>
                                  </button>
                                  <ul
                                    className="dropdown-menu scroll-y"
                                    style={{ maxHeight: "200px" }}
                                  >
                                    {type === 1
                                      ? null
                                      : currencyData.map((country, index) => (
                                          <li key={index}>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                formik.setFieldValue(
                                                  "currency",
                                                  country?.currency
                                                );
                                              }}
                                            >
                                              {country?.flag}{" "}
                                              {country?.currency}
                                            </a>
                                          </li>
                                        ))}
                                  </ul>
                                  <input
                                    {...formik.getFieldProps("amount")}
                                    type="text"
                                    className={clsx("form-control")}
                                    style={{
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                    }}
                                    placeholder="Enter invoice amount"
                                    aria-label="Amount input with currency dropdown"
                                    max="100000"
                                    pattern="[0-9]*"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "amount",
                                        handleAmountChange(e)
                                      );
                                    }}
                                  />
                                </div>
                                <div className="d-flex">
                                  {formik.errors.currency && (
                                    <div className="fv-plugins-message-container w-25">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {formik.errors.currency}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {formik.touched.amount &&
                                    formik.errors.amount && (
                                      <div className="fv-plugins-message-container w-75">
                                        <div className="fv-help-block">
                                          <span role="alert">
                                            | {formik.errors.amount}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>

                              <div className="mb-7">
                                <label className="required fw-bold fs-6 mb-2">
                                  Due date
                                </label>

                                <input
                                  placeholder="Select due date"
                                  maxLength={70}
                                  {...formik.getFieldProps("due_date")}
                                  className={clsx("form-control mb-3 mb-lg-0")}
                                  type="date"
                                  name="due_date"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.due_date}
                                  autoComplete="off"
                                  min={formik?.values?.invoice_date}
                                />
                                {formik.touched.due_date &&
                                  formik.errors.due_date && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {formik.errors.due_date}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="text-center mt-10 mb-5">
                              <button
                                type="reset"
                                onClick={() => {
                                  formik.resetForm();
                                  setFile(null);
                                }}
                                className="btn btn-light me-3"
                                data-kt-users-modal-action="cancel"
                              >
                                Discard
                              </button>

                              <button type="submit" className="btn ">
                                <span className="indicator-label">Submit</span>
                                {formik.isSubmitting && (
                                  <span className="indicator-progress">
                                    Please wait...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignSelf: "center",
                          }}
                        >
                          <FileUploader
                            handleChange={handleAddFile}
                            name="file"
                            types={fileTypes}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "2px dashed #888",
                                borderRadius: "8px",
                                padding: "20px",
                                marginBottom: "10px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                size="4x"
                                style={{ color: "#888" }}
                              />
                              <p style={{ marginTop: "10px", color: "#888" }}>
                                Drag & drop invoice here, or click to select
                                invoice
                              </p>
                            </div>
                          </FileUploader>
                          {/* {!data.fileData.fileData && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      File is required
                    </div>
                  </div>
                )} */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddVendorModal />
      </div>
    </>
  );
};

export default AddInvoice;
