import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Bank } from "../Payee/PayrollStepper/PayrollStepperModal2";
import axios from "axios";
import Swal from "sweetalert2";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
const token = tokenData ? tokenData.token : null;

export interface Documents {
  tenantId: string;
  documentId: number;
  documentGuid: string;
  documentExtention: string;
  documentName: string;
  documentPath: string;
  fileUploadType: number;
  documentStatus: number;
  referenceType: string;
  referenceId: string;
  documentTypes: number[];
  cloneDocument: boolean;
}

interface Settings {
  tenantId: string;
  businessGuid: string;
  settingGuid: string;
  expiryPeriod: string;
  expiryType: string;
  documents: Documents[];
}

// Define initial state
interface SettingsState {
  settings: Settings;
  uploadResponse: Documents;
  loading: boolean;
  error: string | null;
}

interface uploadFilePayload {
  parentFolderGuid: string;
  uploadFiles: File[] | null;
}

const initialState: SettingsState = {
  settings: {
    tenantId: "",
    businessGuid: "",
    settingGuid: "",
    expiryPeriod: "",
    expiryType: "",
    documents: [],
  },
  uploadResponse: {
    tenantId: "",
    documentId: 0,
    documentGuid: "",
    documentExtention: "",
    documentName: "",
    documentPath: "",
    fileUploadType: 0,
    documentStatus: 0,
    referenceType: "",
    referenceId: "",
    documentTypes: [],
    cloneDocument: false,
  },
  loading: false,
  error: null,
};

export interface SearchCriteria {
  propertyName: string;
  propertyValue: string | number;
}

interface CreateDirectoryPayload {
  parentFolderGuid: string;
  folderName: string;
}

interface FetchDirectoryPayload {
  search: string;
  parentFolderGuid: string;
  folderNameFilter: string;
  sourceTypeFilter: string;
  lastModifiedDateFilter: string;
}

// Define async thunk for fetching notifications
export const findSettings = createAsyncThunk<
  Settings,
  FetchDirectoryPayload,
  { rejectValue: string }
>(
  "/dataRoom/findSettings",
  async (payload: FetchDirectoryPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(`${API_URL}/dataRoom/findSettings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const manageSettings = createAsyncThunk<
  Settings,
  { expiryPeriod: string; expiryType: string },
  { rejectValue: string }
>(
  "/dataRoom/manageSettings",
  async (
    payload: { expiryPeriod: string; expiryType: string },
    { rejectWithValue }
  ) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/manageSettings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            expiryPeriod: payload.expiryPeriod,
            expiryType: payload.expiryType,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.status.statusCode;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const uploadSignatureFiles = createAsyncThunk<
  Documents,
  uploadFilePayload,
  { rejectValue: string }
>(
  "/dataRoom/uploadSignatureFiles",

  async (payload: uploadFilePayload, { rejectWithValue }) => {
    const { parentFolderGuid, uploadFiles } = payload;
    // console.log(bulkFile);

    if (!uploadFiles) {
      return rejectWithValue("No file selected.");
    }

    try {
      const formData = new FormData();
      uploadFiles.forEach((file) => {
        formData.append("files", file);
      });
      // formData.append("parentFolderGuid", parentFolderGuid);
      //   formData.append("tenantId", tenantId);
      //   formData.append("userId", userId);

      const response = await axios.post<{
        message: Documents;
      }>(`${API_URL}/dataRoom/uploadSignatureFiles`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      // if (response.data.status.statusCode === 0 && response.data.message) {
      //   Swal.fire({
      //     title: "Confirmation!",
      //     text: "Signature added to library",
      //     icon: "success",
      //   });
      //   return response.data.message;
      // } else {
      //   Swal.fire({
      //     title: "Alert!",
      //     text: response.data.status.messageDescription,
      //     icon: "warning",
      //   });
      // }

      return response.data.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const deleteDocuments = createAsyncThunk<
  { statusCode: number; messageDescription: string },
  { documentGuids: string[] },
  { rejectValue: string }
>(
  "/dataRoom/deleteDocuments",
  async (payload: { documentGuids: string[] }, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(
        `${API_URL}/dataRoom/deleteDocuments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            documentGuids: payload.documentGuids,
            // folderName: payload.folderName,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.message;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

// Create slice
export const settingsSlice = createSlice({
  name: "dataSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findSettings?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(findSettings?.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
        // state.count = action?.payload?.folders?.length;
      })
      .addCase(findSettings?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      })
      .addCase(uploadSignatureFiles?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadSignatureFiles?.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadResponse = action.payload;
        // state.count = action?.payload?.folders?.length;
      })
      .addCase(uploadSignatureFiles?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload as string;
      });
  },
});

// Selectors
// export const selectVendors = (state: { notifications: VendorsState }) =>
//   state.notifications;

// Reducer
export default settingsSlice.reducer;
