import React, { useState } from 'react'


interface Document {
    id: number;
    name: string;
    file: null | string; // or specify the correct file type
}

const OtherDocuments = () => {
        // State to store the list of documents
        const [documents, setDocuments] = useState<Document[]>([]);

        // Function to handle adding a new document
        const handleAddNewDocument = () => {
            setDocuments((prevDocs) => [
                ...prevDocs,
                { id: prevDocs.length, name: '', file: null }, // Add a new document object
            ]);
        };
    
    
    
        // Function to handle file input changes
        const handleFileChange = (e: any, index: any) => {
            const updatedDocuments = [...documents];
            updatedDocuments[index].file = e.target.files[0];
            setDocuments(updatedDocuments);
        };
    
        // Function to remove a document
        const handleRemoveDocument = (index: any) => {
            const updatedDocuments = documents.filter((_, i) => i !== index);
            setDocuments(updatedDocuments);
        };
  return (
  <div>
     <div className="fv-row mb-7">
                                {/* begin::Label */}

                                {/* end::Label */}
                                <p className="fs-2 fw-bold text-center mt-5">
          Please upload the following documents
        </p>

                                {documents.map((document, index) => (
                                    <div className='mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Document Name</label>
                                        <div key={document.id} className="d-flex flex-row gap-5 mb-3">

                                            {/* Document Name input field */}
                                            <input
                                                type="text"
                                                placeholder="Document Name"
                                                className="form-control"
                                                value={document.name}
                                            // onChange={(e) => handleDocumentNameChange(e, index)}
                                            />

                                            {/* File upload input field */}
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => handleFileChange(e, index)}
                                            />

                                            {/* Remove button */}
                                            <div

                                                className='mt-2 text-danger'
                                                onClick={() => handleRemoveDocument(index)}
                                            >
                                                <i className="bi bi-dash text-danger fs-2 cursor-pointer"></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Add New Document link */}
                            <div className="cursor-pointer" onClick={handleAddNewDocument}>
                                <p className="text-primary d-flex flex-row align-items-center"> <i className="bi bi-plus text-primary fs-2"></i>Add New Document</p>
                            </div>
                            <div className="text-center pt-15">
                                <button
                                    type="reset"
                                    // onClick={() => cancel()}
                                    className="btn btn-light me-3"
                                    data-kt-users-modal-action="cancel"
                                >
                                    Skip
                                </button>

                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    data-kt-users-modal-action="submit"
                                >
                                    <span className="indicator-label">Submit</span>
                                    {/* {formik.isSubmitting && (
                                                            <span className="indicator-progress">
                                                                Please wait...{" "}
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )} */}
                                </button>
                            </div>
  </div>
    
  )
}

export default OtherDocuments