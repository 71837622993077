import { useState, ChangeEvent, FC, useEffect } from "react";
import { Modal } from "bootstrap";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import dayjs, { Dayjs } from "dayjs";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import download from "../../../../_metronic/assets/all-serviceIcons/file-download.svg";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import Loader from "../../components/Loader";

interface Accounts {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

const TaxOnDemandDoc: FC = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [toDate, setToDate] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const [accounts, setAccounts] = useState<Accounts[]>([]);

  const tooltipdownload = <Tooltip id="tooltip-add-money">Download</Tooltip>;
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    checkScroll();
    // Add resize observer to handle container size changes
    const resizeObserver = new ResizeObserver(checkScroll);
    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  const scroll = (direction: string) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = 100;
      container?.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const handleToDateChange = (date: string | Date | Dayjs | null) => {
    let formattedDate = "";

    if (date) {
      if (dayjs.isDayjs(date)) {
        formattedDate = date.format("YYYY-MM-DD");
      } else {
        formattedDate = dayjs(date).format("YYYY-MM-DD");
      }
      setToDate(formattedDate);
    } else {
      setToDate("");
    }
  };

  //   useEffect(() => {
  //     setLoading(true);
  //   }, []);

  const handleOpenModal = () => {
    const modalElement = document.getElementById("date_range");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const handleMonthlyModal = () => {
    const modalElement = document.getElementById("monthly_statements");
    const modal = new Modal(modalElement as Element);
    modal.show();
    // handleReset();
  };

  const location = useLocation();
  const accountDetails: Accounts = (
    location.state as { accountDetails: Accounts }
  )?.accountDetails;

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // Calculate pagination
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = filteredTransactions?.slice(
  //     indexOfFirstItem,
  //     indexOfLastItem
  //   );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(accounts?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAccounts();
  }, []);

  const docList = [
    "Balance Statement",
    "Trial Balance",
    "Signature Confirmation",
    "No Liability",
    "Monthly Statements",
  ];

  const genList = [
    {
      gendate: "24 Oct 2024",
      fileName: "balance statement",
      tillDate: "31 Mar 2024",
    },
    {
      gendate: "31 Oct 2024",
      fileName: "trial balance",
      tillDate: "31 Oct 2024",
    },
    {
      gendate: "5 Nov 2024",
      fileName: "balance statement",
      tillDate: "15 May 2024",
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = ["2022", "2023", "2024"];

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <style>{`
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .translate-middle-x-reverse {
    transform: translateX(50%);
  }
`}</style>
          <h4 className="mb-5">On Demand Documents</h4>
          <div className="py-2 card p-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="">
                <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                  <li className="nav-item" key={1}>
                    <a
                      className={`nav-link text-active-gray-800 ${
                        selectedTab === "All" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_1"
                      //   onClick={() => handleTabClick("All")}
                    >
                      Document List
                    </a>
                  </li>
                  <li className="nav-item" key={2}>
                    <a
                      className={`nav-link text-active-gray-800 ${
                        selectedTab === "Credit" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_2"
                      //   onClick={() => handleTabClick("Credit")}
                    >
                      Generated Documents
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className="d-flex align-items-center gap-10 me-5 p-3">
                <div>
                  {" "}
                  <select
                    className="form-select text-gray cursor-pointer"
                    value={selectedAccount ? selectedAccount.accountNumber : ""}
                    onChange={(e) => {
                      const selectedAccountId = e.target.value;
                      const selectedAccount = accounts.find(
                        (account) => account.accountNumber === selectedAccountId
                      );
                      setSelectedAccount(
                        selectedAccount ? selectedAccount : accounts?.[0]
                      );
                      if (selectedTab === "Schedule") {
                        const accountNumber =
                          selectedAccount?.accountNumber?.toString();
                        if (accountNumber) {
                          getScheduleData(accountNumber);
                        }
                      }
                    }}
                  >
                    {accounts?.map((account) => (
                      <option
                        key={account.accountNumber}
                        value={account.accountNumber}
                      >
                        {account.accountNumber}
                        {"  "}({account.currency})
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className={`d-flex align-items-center position-relative my-1 ${
                    search ? "" : "me-6"
                  }`}
                >
                  <OverlayTrigger placement="top" overlay={tooltipSearch}>
                    <i
                      className={`ki-outline ki-magnifier fs-1 position-absolute ${
                        search ? "ms-5" : ""
                      }  cursor-pointer`}
                      onClick={() => setSearch(!search)}
                    ></i>
                  </OverlayTrigger>
                  {search && (
                    <input
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="text"
                      data-kt-docs-table-filter="search"
                      className={`form-control p-3 border-secondary form-control-solid w-${
                        search ? "250px" : "40px"
                      } ps-15`}
                      placeholder="Search transaction"
                    />
                  )}
                </div>
                <div
                  className="mt-1 cursor-pointer "
                  onClick={() => handleDownloadRange()}
                >
                  <OverlayTrigger placement="top" overlay={tooltipFilter}>
                    <i className="bi bi-funnel fs-2 text-hover-dark"></i>
                  </OverlayTrigger>
                </div>
                <div>
                  <OverlayTrigger placement="top" overlay={tooltipDownload}>
                    <i
                      className=" bi bi-download fs-2 text-hover-dark cursor-pointer"
                      onClick={() => {
                        handleDownload();
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </div> */}
            </div>

            <div
              className="tab-content"
              id="myTabContent"
              style={{
                overflow: "scroll",
              }}
            >
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <>
                  <div className="card p-2">
                    <table
                      className="table align-middle border rounded table-row-dashed fs-6 g-5"
                      style={{
                        // height: "440px",
                        overflowY: "scroll",
                      }}
                    >
                      <tbody>
                        {docList && docList.length > 0 ? (
                          docList.map((doc, index) => (
                            <tr
                              //   key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                              }}
                              onClick={() => {
                                if (
                                  doc === "Balance Statement" ||
                                  doc === "Trial Balance"
                                ) {
                                  setToDate("");
                                  handleOpenModal();
                                } else if (doc === "Monthly Statements")
                                  handleMonthlyModal();
                              }}
                            >
                              <td className="px-3 text-hover-primary">
                                {`${index + 1}.`} {doc}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* {docList?.map((doc, index) => (
                      <div
                        className="px-3 py-2 bg-hover-light text-hover-primary fs-5 mt-5"
                        key={index}
                        onClick={() => {
                          if (
                            doc === "Balance Statement" ||
                            doc === "Trial Balance"
                          ) {
                            setToDate("");
                            handleOpenModal();
                          }
                        }}
                      >
                        {`${index + 1}.`} {doc}
                      </div>
                    ))} */}
                  </div>
                </>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Date</th>
                          <th className="min-w-100px p-5">Document Name</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {genList && genList.length > 0 ? (
                          genList.map((doc, index) => (
                            <tr
                              //   key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                              }}
                            >
                              <td className="px-3">{doc.gendate}</td>
                              <td className="px-3">{doc?.fileName}</td>
                              <td>
                                <div className="d-flex gap-10">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipdownload}
                                  >
                                    <img
                                      style={{
                                        height: "30px",
                                        width: "22px",
                                        mixBlendMode: "multiply",
                                      }}
                                      src={download}
                                      alt=""
                                      // onClick={() =>
                                      //   handleDownloadClick(
                                      //     item.txnId,
                                      //     item.fromAccount
                                      //   )
                                      // }
                                    />
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* <div className="d-flex justify-content-between">
                      <div className="p-5">
                        <ul className="pagination">
                          <li
                            className={`page-item double-arrow ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            key={0}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(1)}
                            >
                              <i className="previous"></i>
                              <i className="previous"></i>
                            </a>
                          </li>
                          <li
                            className={`page-item previous ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            key={1}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              <i className="previous"></i>
                            </a>
                          </li>
                          {renderPageNumbers()}
                          <li
                            className={`page-item next ${
                              currentPage ===
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                                ? "disabled"
                                : ""
                            }`}
                            key={2}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              <i className="next"></i>
                            </a>
                          </li>
                          <li
                            className={`page-item double-arrow ${
                              currentPage ===
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                                ? "disabled"
                                : ""
                            }`}
                            key={3}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() =>
                                handlePageChange(
                                  Math.ceil(
                                    filteredTransactions?.length / itemsPerPage
                                  )
                                )
                              }
                            >
                              <i className="next"></i>
                              <i className="next"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="p-5 d-flex align-items-center">
                        <label
                          htmlFor="itemsPerPage"
                          style={{ fontWeight: "bold" }}
                        >
                          Total: {filteredTransactions?.length}&nbsp;&nbsp;
                        </label>
                        <select
                          id="itemsPerPage"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                          style={{ padding: "1px 2px 1px 2px" }}
                        >
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex={-1} id="date_range">
            <div
              className="modal-dialog modal-dialog-centered w-400px"
              // style={{ margin: "0 auto", marginTop: "10%" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  {" "}
                  <h4 className="mb-0">Download statement</h4>
                </div>
                <div className="modal-body p-10">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="w-100 flex-grow-1 mb-10">
                      <label className="fs-6 fw-bold">Select Date</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Till Date"
                            className="w-100 form-control"
                            disableFuture
                            slotProps={{
                              textField: {
                                size: "small",
                                //   style: {
                                //     width: "35vw",
                                //   },
                              },
                            }}
                            value={toDate ? dayjs(toDate) : null}
                            onChange={(date) => {
                              handleToDateChange(date);
                              // validateDates();
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <button
                      type="button"
                      className={`w-100 rounded btn`}
                      style={{
                        // width: "150px",
                        color: "#ffff",
                      }}
                      onClick={() => {
                        setCurrentPage(1);
                      }}
                      disabled={!toDate}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex={-1} id="monthly_statements">
            <div
              className="modal-dialog modal-dialog-centered w-400px"
              // style={{ margin: "0 auto", marginTop: "10%" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  {" "}
                  <h4 className="mb-0">Download statement</h4>
                </div>
                <div className="modal-body">
                  <div className="d-flex align-items-center mb-2 ">
                    {" "}
                    {showLeftArrow && (
                      <i
                        className="bi bi-chevron-left fs-3 fw-bold text-dark"
                        onClick={() => scroll("left")}
                      ></i>
                    )}
                    <div
                      ref={scrollContainerRef}
                      className="d-flex gap-3 overflow-x-auto scrollbar-hide"
                      onScroll={checkScroll}
                    >
                      {years.map((year, index) => (
                        <div
                          className={`d-flex px-3 py-2 ${selectedYear === year ? "bg-light text-primary" : ""} bg-hover-light text-hover-primary rounded`}
                          key={index}
                          onClick={() => setSelectedYear(year)}
                        >
                          {year}
                        </div>
                      ))}
                    </div>
                    {showRightArrow && (
                      <i
                        className="bi bi-chevron-right fs-3 fw-bold text-dark"
                        onClick={() => scroll("right")}
                      ></i>
                    )}
                  </div>

                  <div
                    style={{
                      maxHeight: "400px",
                      overflow: "scroll",
                    }}
                  >
                    {months.map((mon, index) => (
                      <div
                        className="p-3 bg-light bg-hover-secondary rounded text-hover-primary mb-2"
                        key={index}
                      >
                        {mon}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TaxOnDemandDoc;
