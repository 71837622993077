import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const TaxDashboard = () => {
  return (
    <>
      <h4>Dashboard</h4>
      <div className="card  p-5">
        <div className="d-flex flex-row justify-content-between gap-5">
          <div
            className="card p-5 shadow w-100 bg-secondary text-dark"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-10 ">ZAR 1,000</div>
            <div className="">Total tax due this month</div>
          </div>
          <div
            className="card p-5 shadow w-100 bg-dark text-light"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-10">ZAR 500</div>
            <div className="">Total paid this month</div>
          </div>
          <div
            className="card p-5 shadow w-100 bg-warning text-light"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-10">ZAR 500</div>
            <div className="">Total pending this month</div>
          </div>
          <div
            className="card p-5 shadow w-100  bg-info text-light"
            style={{ height: "150px" }}
          >
            <div className="fs-2x fw-bold mt-10">3</div>
            <div className="">Event's spending</div>
          </div>
        </div>
        <div className="w-100 ">
          <div className="card shadow-sm w-50 p-5 mt-5">
            <h5>Deadline's this month</h5>
            <div className="card shadow p-5 mt-3 mb-3">
              <div className="d-flex flex-row gap-5 align-items-center">
                <div className="badge badge-dark text-white pt-5 pb-5 ps-3 pe-3 fw-bold rounded">
                  7th Jan
                </div>
                <div>
                  <div className="fw-bold ">PAYE/UIF/SDL - EMP201</div>
                  <div className="mt-2 fs-7">For January</div>
                </div>
              </div>
              <div className="border-gray-300 mt-5 border-bottom-dashed"></div>
              <div className="d-flex flex-row flex-end gap-5 mt-3">
                <div>
                  {" "}
                  <div className="badge badge-light-primary">Payment pending </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add-money">Pay now</Tooltip>}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_payment"
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={toAbsoluteUrl("/media/tax-icons/Pay Now.svg")}
                        alt=""
                      />
                    </span>
                  </OverlayTrigger>
                </div>

                <div>
                  {" "}
                  <div className="badge badge-light-primary">Filing pending </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add-money">File now</Tooltip>}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_payment"
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={toAbsoluteUrl(
                          "/media/tax-icons/File Now (1).svg"
                        )}
                        alt=""
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="card shadow p-5 mt-3 mb-3">
              <div className="d-flex flex-row gap-5 align-items-center">
                <div className="badge badge-dark text-white pt-5 pb-5 ps-3 pe-3 fw-bold rounded">
                  13th Jan
                </div>
                <div>
                  <div className="fw-bold ">PAYE/UIF/SDL - EMP201</div>
                  <div className="mt-2 fs-7">For January</div>
                </div>
              </div>
              <div className="border-gray-300 mt-5 border-bottom-dashed"></div>
              <div className="d-flex flex-row flex-end gap-5 mt-3">
                <div>
                  {" "}
                  <div className="badge badge-light-success">Completed</div>
                  <i className="bi bi-download fs-4 mt-2 ms-3 text-dark"></i> 
                </div>

                <div>
                  {" "}
                  <div className="badge badge-light-primary">Filing pending </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add-money">File now</Tooltip>}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_payment"
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={toAbsoluteUrl(
                          "/media/tax-icons/File Now (1).svg"
                        )}
                        alt=""
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="card shadow p-5 mt-3 mb-3">
              <div className="d-flex flex-row gap-5 align-items-center">
                <div className="badge badge-dark text-white pt-5 pb-5 ps-3 pe-3 fw-bold rounded">
                  17th Jan
                </div>
                <div>
                  <div className="fw-bold ">PAYE/UIF/SDL - EMP201</div>
                  <div className="mt-2 fs-7">For January</div>
                </div>
              </div>
              <div className="border-gray-300 mt-5 border-bottom-dashed"></div>
              <div className="d-flex flex-row flex-end gap-5 mt-3">
                <div>
                  {" "}
                  <div className="badge badge-light-primary">Payment pending </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add-money">Pay now</Tooltip>}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_payment"
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={toAbsoluteUrl("/media/tax-icons/Pay Now.svg")}
                        alt=""
                      />
                    </span>
                  </OverlayTrigger>
                </div>

                <div>
                  {" "}
                  <div className="badge badge-light-primary">Filing pending </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-add-money">File now</Tooltip>}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_payment"
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={toAbsoluteUrl(
                          "/media/tax-icons/File Now (1).svg"
                        )}
                        alt=""
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default TaxDashboard;
